.CalendarDay__selected_span {
  background: #82e0aa;
  color: white;
}

.CalendarDay__selected {
  background: orange;
  color: white;
}

.CalendarDay__selected:hover {
  background: orange;
  color: white;
}

.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  background: brown;
}

.DateInput_input {
  /* font-weight: 200; */
  color: #6c757d;
  background-color: #262a2d;
  width: 100%;

  position: static;
}
.form-group.form-group-icon {
  border-bottom: 1px solid #6c757d;
}

.DateInput_input__focused {
  outline: 0;
  color: #6c757d;
  background-color: #262a2d;
  border: 1px solid #ffcc9e;
  box-shadow: 0 0 0 0.2rem rgba(255, 137, 30, 0.25);
}
.SingleDatePickerInput {
  background-color: #262a2d;
}

input.DateInput_input.DateInput_input_1.DateInput_input__small.DateInput_input__small_2 {
  padding-bottom: 12px;
  padding-top: 13px;
}

input.DateInput_input.DateInput_input_1 {
  background-color: #0000;
}

.DateInput.DateInput_1 {
  background-color: #0000;
}

.daterange-picker-vip {
  background-color: #fff;
  color: #000000;
}

.SingleDatePickerInput.SingleDatePickerInput_1.SingleDatePickerInput__withBorder.SingleDatePickerInput__withBorder_2 {
  background-color: #fff;
  color: #000000;
}

#activityDate.DateInput_input.DateInput_input_1 {
  background-color: #fff;
}

#activityDate > .SingleDatePickerInput {
  background-color: #fff;
}
