/*!
 * Bootstrap v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #ff891e;
  --secondary: #6c757d;
  --success: #60a662;
  --info: #1ab3e8;
  --warning: #fec701;
  --danger: #e25a5a;
  --light: #f8f9fa;
  --dark: #222;
  --success-light: #1cd066;
  --black: #000;
  --dark-light: #262a2e;
  --stone: #232629;
  --gray-color: #969696;
  --gray-dark: #565b61;
  --smoke: #f5f5f5;
  --off-white: #dedede;
  --light-gray: #eee;
  --facebook: #6684c4;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #ff891e;
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  color: #d16300;
  text-decoration: underline;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

select {
  word-wrap: normal;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 400;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 1.88125rem;
}

h2, .h2 {
  font-size: 1.6275rem;
}

h3, .h3 {
  font-size: 1.25125rem;
}

h4, .h4 {
  font-size: 1.12875rem;
}

h5, .h5 {
  font-size: 1.00625rem;
}

h6, .h6 {
  font-size: 0.875rem;
}

.lead {
  font-size: 1.09375rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 2.85rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 2rem;
  margin-bottom: 2rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.07);
}

small,
.small {
  font-size: 83%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.09375rem;
}

.blockquote-footer {
  display: block;
  font-size: 83%;
  color: #6c757d;
}

.blockquote-footer::before {
  content: "\2014\00A0";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word;
}

a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}

kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 750px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 970px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1170px;
  }
}

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  -ms-flex-preferred-size: 0;
      flex-basis: 0;
  -ms-flex-positive: 1;
      flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  -ms-flex: 0 0 auto;
      flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  -ms-flex: 0 0 8.33333%;
      flex: 0 0 8.33333%;
  max-width: 8.33333%;
}

.col-2 {
  -ms-flex: 0 0 16.66667%;
      flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.col-3 {
  -ms-flex: 0 0 25%;
      flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  -ms-flex: 0 0 33.33333%;
      flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.col-5 {
  -ms-flex: 0 0 41.66667%;
      flex: 0 0 41.66667%;
  max-width: 41.66667%;
}

.col-6 {
  -ms-flex: 0 0 50%;
      flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  -ms-flex: 0 0 58.33333%;
      flex: 0 0 58.33333%;
  max-width: 58.33333%;
}

.col-8 {
  -ms-flex: 0 0 66.66667%;
      flex: 0 0 66.66667%;
  max-width: 66.66667%;
}

.col-9 {
  -ms-flex: 0 0 75%;
      flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  -ms-flex: 0 0 83.33333%;
      flex: 0 0 83.33333%;
  max-width: 83.33333%;
}

.col-11 {
  -ms-flex: 0 0 91.66667%;
      flex: 0 0 91.66667%;
  max-width: 91.66667%;
}

.col-12 {
  -ms-flex: 0 0 100%;
      flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  -ms-flex-order: -1;
      order: -1;
}

.order-last {
  -ms-flex-order: 13;
      order: 13;
}

.order-0 {
  -ms-flex-order: 0;
      order: 0;
}

.order-1 {
  -ms-flex-order: 1;
      order: 1;
}

.order-2 {
  -ms-flex-order: 2;
      order: 2;
}

.order-3 {
  -ms-flex-order: 3;
      order: 3;
}

.order-4 {
  -ms-flex-order: 4;
      order: 4;
}

.order-5 {
  -ms-flex-order: 5;
      order: 5;
}

.order-6 {
  -ms-flex-order: 6;
      order: 6;
}

.order-7 {
  -ms-flex-order: 7;
      order: 7;
}

.order-8 {
  -ms-flex-order: 8;
      order: 8;
}

.order-9 {
  -ms-flex-order: 9;
      order: 9;
}

.order-10 {
  -ms-flex-order: 10;
      order: 10;
}

.order-11 {
  -ms-flex-order: 11;
      order: 11;
}

.order-12 {
  -ms-flex-order: 12;
      order: 12;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.66667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333%;
}

.offset-5 {
  margin-left: 41.66667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333%;
}

.offset-8 {
  margin-left: 66.66667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333%;
}

.offset-11 {
  margin-left: 91.66667%;
}

@media (min-width: 576px) {
  .col-sm {
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    -ms-flex-positive: 1;
        flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    -ms-flex: 0 0 8.33333%;
        flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-sm-2 {
    -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-sm-3 {
    -ms-flex: 0 0 25%;
        flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-sm-5 {
    -ms-flex: 0 0 41.66667%;
        flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-sm-6 {
    -ms-flex: 0 0 50%;
        flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    -ms-flex: 0 0 58.33333%;
        flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-sm-8 {
    -ms-flex: 0 0 66.66667%;
        flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-sm-9 {
    -ms-flex: 0 0 75%;
        flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    -ms-flex: 0 0 83.33333%;
        flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-sm-11 {
    -ms-flex: 0 0 91.66667%;
        flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-sm-12 {
    -ms-flex: 0 0 100%;
        flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    -ms-flex-order: -1;
        order: -1;
  }
  .order-sm-last {
    -ms-flex-order: 13;
        order: 13;
  }
  .order-sm-0 {
    -ms-flex-order: 0;
        order: 0;
  }
  .order-sm-1 {
    -ms-flex-order: 1;
        order: 1;
  }
  .order-sm-2 {
    -ms-flex-order: 2;
        order: 2;
  }
  .order-sm-3 {
    -ms-flex-order: 3;
        order: 3;
  }
  .order-sm-4 {
    -ms-flex-order: 4;
        order: 4;
  }
  .order-sm-5 {
    -ms-flex-order: 5;
        order: 5;
  }
  .order-sm-6 {
    -ms-flex-order: 6;
        order: 6;
  }
  .order-sm-7 {
    -ms-flex-order: 7;
        order: 7;
  }
  .order-sm-8 {
    -ms-flex-order: 8;
        order: 8;
  }
  .order-sm-9 {
    -ms-flex-order: 9;
        order: 9;
  }
  .order-sm-10 {
    -ms-flex-order: 10;
        order: 10;
  }
  .order-sm-11 {
    -ms-flex-order: 11;
        order: 11;
  }
  .order-sm-12 {
    -ms-flex-order: 12;
        order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333%;
  }
  .offset-sm-2 {
    margin-left: 16.66667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333%;
  }
  .offset-sm-5 {
    margin-left: 41.66667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333%;
  }
  .offset-sm-8 {
    margin-left: 66.66667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333%;
  }
  .offset-sm-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    -ms-flex-positive: 1;
        flex-grow: 1;
    max-width: 100%;
  }
  .col-md-auto {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    -ms-flex: 0 0 8.33333%;
        flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-md-2 {
    -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-md-3 {
    -ms-flex: 0 0 25%;
        flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-md-5 {
    -ms-flex: 0 0 41.66667%;
        flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-md-6 {
    -ms-flex: 0 0 50%;
        flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    -ms-flex: 0 0 58.33333%;
        flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-md-8 {
    -ms-flex: 0 0 66.66667%;
        flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-md-9 {
    -ms-flex: 0 0 75%;
        flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    -ms-flex: 0 0 83.33333%;
        flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-md-11 {
    -ms-flex: 0 0 91.66667%;
        flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-md-12 {
    -ms-flex: 0 0 100%;
        flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    -ms-flex-order: -1;
        order: -1;
  }
  .order-md-last {
    -ms-flex-order: 13;
        order: 13;
  }
  .order-md-0 {
    -ms-flex-order: 0;
        order: 0;
  }
  .order-md-1 {
    -ms-flex-order: 1;
        order: 1;
  }
  .order-md-2 {
    -ms-flex-order: 2;
        order: 2;
  }
  .order-md-3 {
    -ms-flex-order: 3;
        order: 3;
  }
  .order-md-4 {
    -ms-flex-order: 4;
        order: 4;
  }
  .order-md-5 {
    -ms-flex-order: 5;
        order: 5;
  }
  .order-md-6 {
    -ms-flex-order: 6;
        order: 6;
  }
  .order-md-7 {
    -ms-flex-order: 7;
        order: 7;
  }
  .order-md-8 {
    -ms-flex-order: 8;
        order: 8;
  }
  .order-md-9 {
    -ms-flex-order: 9;
        order: 9;
  }
  .order-md-10 {
    -ms-flex-order: 10;
        order: 10;
  }
  .order-md-11 {
    -ms-flex-order: 11;
        order: 11;
  }
  .order-md-12 {
    -ms-flex-order: 12;
        order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333%;
  }
  .offset-md-2 {
    margin-left: 16.66667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333%;
  }
  .offset-md-5 {
    margin-left: 41.66667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333%;
  }
  .offset-md-8 {
    margin-left: 66.66667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333%;
  }
  .offset-md-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    -ms-flex-positive: 1;
        flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-auto {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    -ms-flex: 0 0 8.33333%;
        flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-lg-2 {
    -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-lg-3 {
    -ms-flex: 0 0 25%;
        flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-lg-5 {
    -ms-flex: 0 0 41.66667%;
        flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-lg-6 {
    -ms-flex: 0 0 50%;
        flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    -ms-flex: 0 0 58.33333%;
        flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-lg-8 {
    -ms-flex: 0 0 66.66667%;
        flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-lg-9 {
    -ms-flex: 0 0 75%;
        flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    -ms-flex: 0 0 83.33333%;
        flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-lg-11 {
    -ms-flex: 0 0 91.66667%;
        flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-lg-12 {
    -ms-flex: 0 0 100%;
        flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    -ms-flex-order: -1;
        order: -1;
  }
  .order-lg-last {
    -ms-flex-order: 13;
        order: 13;
  }
  .order-lg-0 {
    -ms-flex-order: 0;
        order: 0;
  }
  .order-lg-1 {
    -ms-flex-order: 1;
        order: 1;
  }
  .order-lg-2 {
    -ms-flex-order: 2;
        order: 2;
  }
  .order-lg-3 {
    -ms-flex-order: 3;
        order: 3;
  }
  .order-lg-4 {
    -ms-flex-order: 4;
        order: 4;
  }
  .order-lg-5 {
    -ms-flex-order: 5;
        order: 5;
  }
  .order-lg-6 {
    -ms-flex-order: 6;
        order: 6;
  }
  .order-lg-7 {
    -ms-flex-order: 7;
        order: 7;
  }
  .order-lg-8 {
    -ms-flex-order: 8;
        order: 8;
  }
  .order-lg-9 {
    -ms-flex-order: 9;
        order: 9;
  }
  .order-lg-10 {
    -ms-flex-order: 10;
        order: 10;
  }
  .order-lg-11 {
    -ms-flex-order: 11;
        order: 11;
  }
  .order-lg-12 {
    -ms-flex-order: 12;
        order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333%;
  }
  .offset-lg-2 {
    margin-left: 16.66667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333%;
  }
  .offset-lg-5 {
    margin-left: 41.66667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333%;
  }
  .offset-lg-8 {
    margin-left: 66.66667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333%;
  }
  .offset-lg-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    -ms-flex-positive: 1;
        flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-auto {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    -ms-flex: 0 0 8.33333%;
        flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-xl-2 {
    -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xl-3 {
    -ms-flex: 0 0 25%;
        flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-xl-5 {
    -ms-flex: 0 0 41.66667%;
        flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-xl-6 {
    -ms-flex: 0 0 50%;
        flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    -ms-flex: 0 0 58.33333%;
        flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-xl-8 {
    -ms-flex: 0 0 66.66667%;
        flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-xl-9 {
    -ms-flex: 0 0 75%;
        flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    -ms-flex: 0 0 83.33333%;
        flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-xl-11 {
    -ms-flex: 0 0 91.66667%;
        flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-xl-12 {
    -ms-flex: 0 0 100%;
        flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    -ms-flex-order: -1;
        order: -1;
  }
  .order-xl-last {
    -ms-flex-order: 13;
        order: 13;
  }
  .order-xl-0 {
    -ms-flex-order: 0;
        order: 0;
  }
  .order-xl-1 {
    -ms-flex-order: 1;
        order: 1;
  }
  .order-xl-2 {
    -ms-flex-order: 2;
        order: 2;
  }
  .order-xl-3 {
    -ms-flex-order: 3;
        order: 3;
  }
  .order-xl-4 {
    -ms-flex-order: 4;
        order: 4;
  }
  .order-xl-5 {
    -ms-flex-order: 5;
        order: 5;
  }
  .order-xl-6 {
    -ms-flex-order: 6;
        order: 6;
  }
  .order-xl-7 {
    -ms-flex-order: 7;
        order: 7;
  }
  .order-xl-8 {
    -ms-flex-order: 8;
        order: 8;
  }
  .order-xl-9 {
    -ms-flex-order: 9;
        order: 9;
  }
  .order-xl-10 {
    -ms-flex-order: 10;
        order: 10;
  }
  .order-xl-11 {
    -ms-flex-order: 11;
        order: 11;
  }
  .order-xl-12 {
    -ms-flex-order: 12;
        order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333%;
  }
  .offset-xl-2 {
    margin-left: 16.66667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333%;
  }
  .offset-xl-5 {
    margin-left: 41.66667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333%;
  }
  .offset-xl-8 {
    margin-left: 66.66667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333%;
  }
  .offset-xl-11 {
    margin-left: 91.66667%;
  }
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: #212529;
  background-color: #fff9f4;
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #ffdec0;
}

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #ffc28a;
}

.table-hover .table-primary:hover {
  background-color: #ffd1a7;
}

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #ffd1a7;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db;
}

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb;
}

.table-hover .table-secondary:hover {
  background-color: #c8cbcf;
}

.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #d2e6d3;
}

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #acd1ad;
}

.table-hover .table-success:hover {
  background-color: #c2ddc3;
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #c2ddc3;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bfeaf9;
}

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #88d7f3;
}

.table-hover .table-info:hover {
  background-color: #a8e2f7;
}

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #a8e2f7;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffefb8;
}

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #fee27b;
}

.table-hover .table-warning:hover {
  background-color: #ffe99f;
}

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe99f;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f7d1d1;
}

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #f0a9a9;
}

.table-hover .table-danger:hover {
  background-color: #f3bbbb;
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f3bbbb;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}

.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c1c1c1;
}

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #8c8c8c;
}

.table-hover .table-dark:hover {
  background-color: #b4b4b4;
}

.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b4b4b4;
}

.table-success-light,
.table-success-light > th,
.table-success-light > td {
  background-color: #bff2d4;
}

.table-success-light th,
.table-success-light td,
.table-success-light thead th,
.table-success-light tbody + tbody {
  border-color: #89e7af;
}

.table-hover .table-success-light:hover {
  background-color: #aaeec6;
}

.table-hover .table-success-light:hover > td,
.table-hover .table-success-light:hover > th {
  background-color: #aaeec6;
}

.table-black,
.table-black > th,
.table-black > td {
  background-color: #b8b8b8;
}

.table-black th,
.table-black td,
.table-black thead th,
.table-black tbody + tbody {
  border-color: #7a7a7a;
}

.table-hover .table-black:hover {
  background-color: #ababab;
}

.table-hover .table-black:hover > td,
.table-hover .table-black:hover > th {
  background-color: #ababab;
}

.table-dark-light,
.table-dark-light > th,
.table-dark-light > td {
  background-color: #c2c3c4;
}

.table-dark-light th,
.table-dark-light td,
.table-dark-light thead th,
.table-dark-light tbody + tbody {
  border-color: #8e9092;
}

.table-hover .table-dark-light:hover {
  background-color: #b5b6b7;
}

.table-hover .table-dark-light:hover > td,
.table-hover .table-dark-light:hover > th {
  background-color: #b5b6b7;
}

.table-stone,
.table-stone > th,
.table-stone > td {
  background-color: #c1c2c3;
}

.table-stone th,
.table-stone td,
.table-stone thead th,
.table-stone tbody + tbody {
  border-color: #8d8e90;
}

.table-hover .table-stone:hover {
  background-color: #b4b5b6;
}

.table-hover .table-stone:hover > td,
.table-hover .table-stone:hover > th {
  background-color: #b4b5b6;
}

.table-gray-color,
.table-gray-color > th,
.table-gray-color > td {
  background-color: #e2e2e2;
}

.table-gray-color th,
.table-gray-color td,
.table-gray-color thead th,
.table-gray-color tbody + tbody {
  border-color: #c8c8c8;
}

.table-hover .table-gray-color:hover {
  background-color: #d5d5d5;
}

.table-hover .table-gray-color:hover > td,
.table-hover .table-gray-color:hover > th {
  background-color: #d5d5d5;
}

.table-gray-dark,
.table-gray-dark > th,
.table-gray-dark > td {
  background-color: #d0d1d3;
}

.table-gray-dark th,
.table-gray-dark td,
.table-gray-dark thead th,
.table-gray-dark tbody + tbody {
  border-color: #a7aaad;
}

.table-hover .table-gray-dark:hover {
  background-color: #c3c4c7;
}

.table-hover .table-gray-dark:hover > td,
.table-hover .table-gray-dark:hover > th {
  background-color: #c3c4c7;
}

.table-smoke,
.table-smoke > th,
.table-smoke > td {
  background-color: #fcfcfc;
}

.table-smoke th,
.table-smoke td,
.table-smoke thead th,
.table-smoke tbody + tbody {
  border-color: #fafafa;
}

.table-hover .table-smoke:hover {
  background-color: #efefef;
}

.table-hover .table-smoke:hover > td,
.table-hover .table-smoke:hover > th {
  background-color: #efefef;
}

.table-off-white,
.table-off-white > th,
.table-off-white > td {
  background-color: #f6f6f6;
}

.table-off-white th,
.table-off-white td,
.table-off-white thead th,
.table-off-white tbody + tbody {
  border-color: #eeeeee;
}

.table-hover .table-off-white:hover {
  background-color: #e9e9e9;
}

.table-hover .table-off-white:hover > td,
.table-hover .table-off-white:hover > th {
  background-color: #e9e9e9;
}

.table-light-gray,
.table-light-gray > th,
.table-light-gray > td {
  background-color: #fafafa;
}

.table-light-gray th,
.table-light-gray td,
.table-light-gray thead th,
.table-light-gray tbody + tbody {
  border-color: #f6f6f6;
}

.table-hover .table-light-gray:hover {
  background-color: #ededed;
}

.table-hover .table-light-gray:hover > td,
.table-hover .table-light-gray:hover > th {
  background-color: #ededed;
}

.table-facebook,
.table-facebook > th,
.table-facebook > td {
  background-color: #d4ddee;
}

.table-facebook th,
.table-facebook td,
.table-facebook thead th,
.table-facebook tbody + tbody {
  border-color: #afbfe0;
}

.table-hover .table-facebook:hover {
  background-color: #c2cfe7;
}

.table-hover .table-facebook:hover > td,
.table-hover .table-facebook:hover > th {
  background-color: #c2cfe7;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: #fff9f4;
}

.table-hover .table-active:hover {
  background-color: #ffebdb;
}

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: #ffebdb;
}

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #343a40;
}

.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}

.table-dark.table-bordered {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 1.4rem + 2px);
  padding: 0.7rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #ffcc9e;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 137, 30, 0.25);
}

.form-control:-ms-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.7rem + 1px);
  padding-bottom: calc(0.7rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.8rem + 1px);
  padding-bottom: calc(0.8rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.4rem + 1px);
  padding-bottom: calc(0.4rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.8rem + 2px);
  padding: 0.4rem 0.7rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(1.5em + 1.6rem + 2px);
  padding: 0.8rem 1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.5rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}

.form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
      align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}

.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 83%;
  color: #60a662;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.76562rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(96, 166, 98, 0.9);
  border-radius: 0.25rem;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #60a662;
  padding-right: calc(1.5em + 1.4rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2360a662' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.35rem);
  background-size: calc(0.75em + 0.7rem) calc(0.75em + 0.7rem);
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #60a662;
  box-shadow: 0 0 0 0.2rem rgba(96, 166, 98, 0.25);
}

.was-validated .form-control:valid ~ .valid-feedback,
.was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
.form-control.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 1.4rem);
  background-position: top calc(0.375em + 0.35rem) right calc(0.375em + 0.35rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #60a662;
  padding-right: calc((1em + 1.4rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2360a662' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.7rem) calc(0.75em + 0.7rem);
}

.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #60a662;
  box-shadow: 0 0 0 0.2rem rgba(96, 166, 98, 0.25);
}

.was-validated .custom-select:valid ~ .valid-feedback,
.was-validated .custom-select:valid ~ .valid-tooltip, .custom-select.is-valid ~ .valid-feedback,
.custom-select.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #60a662;
}

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #60a662;
}

.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #60a662;
}

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #81b882;
  background-color: #81b882;
}

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(96, 166, 98, 0.25);
}

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #60a662;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #60a662;
}

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #60a662;
  box-shadow: 0 0 0 0.2rem rgba(96, 166, 98, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 83%;
  color: #e25a5a;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.76562rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(226, 90, 90, 0.9);
  border-radius: 0.25rem;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #e25a5a;
  padding-right: calc(1.5em + 1.4rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23e25a5a' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23e25a5a' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.35rem);
  background-size: calc(0.75em + 0.7rem) calc(0.75em + 0.7rem);
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #e25a5a;
  box-shadow: 0 0 0 0.2rem rgba(226, 90, 90, 0.25);
}

.was-validated .form-control:invalid ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 1.4rem);
  background-position: top calc(0.375em + 0.35rem) right calc(0.375em + 0.35rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #e25a5a;
  padding-right: calc((1em + 1.4rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23e25a5a' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23e25a5a' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") #fff no-repeat center right 1.75rem/calc(0.75em + 0.7rem) calc(0.75em + 0.7rem);
}

.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #e25a5a;
  box-shadow: 0 0 0 0.2rem rgba(226, 90, 90, 0.25);
}

.was-validated .custom-select:invalid ~ .invalid-feedback,
.was-validated .custom-select:invalid ~ .invalid-tooltip, .custom-select.is-invalid ~ .invalid-feedback,
.custom-select.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #e25a5a;
}

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #e25a5a;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #e25a5a;
}

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #ea8585;
  background-color: #ea8585;
}

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(226, 90, 90, 0.25);
}

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #e25a5a;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #e25a5a;
}

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #e25a5a;
  box-shadow: 0 0 0 0.2rem rgba(226, 90, 90, 0.25);
}

.form-inline {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row wrap;
      flex-flow: row wrap;
  -ms-flex-align: center;
      align-items: center;
}

.form-inline .form-check {
  width: 100%;
}

@media (min-width: 576px) {
  .form-inline label {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: center;
        justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    -ms-flex-flow: row wrap;
        flex-flow: row wrap;
    -ms-flex-align: center;
        align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: center;
        justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    -ms-flex-negative: 0;
        flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: center;
        justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.6rem 1.8125rem;
  font-size: 0.875rem;
  line-height: 1.5rem;
  border-radius: 0.25rem;
  transition: all 0.3s;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: #212529;
  text-decoration: none;
}

.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: none !important;
}

.btn.disabled, .btn:disabled {
  opacity: 0.65;
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #212529;
  background-color: #ff891e;
  border-color: #ff891e;
}

.btn-primary:hover {
  color: #fff;
  background-color: #f77500;
  border-color: #ea6f00;
}

.btn-primary:focus, .btn-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 122, 32, 0.5);
}

.btn-primary.disabled, .btn-primary:disabled {
  color: #212529;
  background-color: #ff891e;
  border-color: #ff891e;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #ea6f00;
  border-color: #dd6900;
}

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 122, 32, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}

.btn-secondary:focus, .btn-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}

.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #60a662;
  border-color: #60a662;
}

.btn-success:hover {
  color: #fff;
  background-color: #508f52;
  border-color: #4c874d;
}

.btn-success:focus, .btn-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(120, 179, 122, 0.5);
}

.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #60a662;
  border-color: #60a662;
}

.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #4c874d;
  border-color: #477f49;
}

.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(120, 179, 122, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #1ab3e8;
  border-color: #1ab3e8;
}

.btn-info:hover {
  color: #fff;
  background-color: #1499c8;
  border-color: #1391bc;
}

.btn-info:focus, .btn-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(60, 190, 235, 0.5);
}

.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #1ab3e8;
  border-color: #1ab3e8;
}

.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #1391bc;
  border-color: #1288b1;
}

.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(60, 190, 235, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #fec701;
  border-color: #fec701;
}

.btn-warning:hover {
  color: #212529;
  background-color: #d8a901;
  border-color: #cb9f01;
}

.btn-warning:focus, .btn-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(221, 175, 7, 0.5);
}

.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #fec701;
  border-color: #fec701;
}

.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #cb9f01;
  border-color: #bf9501;
}

.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(221, 175, 7, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #e25a5a;
  border-color: #e25a5a;
}

.btn-danger:hover {
  color: #fff;
  background-color: #dc3939;
  border-color: #da2f2f;
}

.btn-danger:focus, .btn-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(230, 115, 115, 0.5);
}

.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #e25a5a;
  border-color: #e25a5a;
}

.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #da2f2f;
  border-color: #d72626;
}

.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(230, 115, 115, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}

.btn-light:focus, .btn-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}

.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #222;
  border-color: #222;
}

.btn-dark:hover {
  color: #fff;
  background-color: #0f0f0f;
  border-color: #090909;
}

.btn-dark:focus, .btn-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(67, 67, 67, 0.5);
}

.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #222;
  border-color: #222;
}

.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #090909;
  border-color: #020202;
}

.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(67, 67, 67, 0.5);
}

.btn-success-light {
  color: #fff;
  background-color: #1cd066;
  border-color: #1cd066;
}

.btn-success-light:hover {
  color: #fff;
  background-color: #17ae55;
  border-color: #16a350;
}

.btn-success-light:focus, .btn-success-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(62, 215, 125, 0.5);
}

.btn-success-light.disabled, .btn-success-light:disabled {
  color: #fff;
  background-color: #1cd066;
  border-color: #1cd066;
}

.btn-success-light:not(:disabled):not(.disabled):active, .btn-success-light:not(:disabled):not(.disabled).active,
.show > .btn-success-light.dropdown-toggle {
  color: #fff;
  background-color: #16a350;
  border-color: #14984a;
}

.btn-success-light:not(:disabled):not(.disabled):active:focus, .btn-success-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-success-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(62, 215, 125, 0.5);
}

.btn-black {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

.btn-black:hover {
  color: #fff;
  background-color: black;
  border-color: black;
}

.btn-black:focus, .btn-black.focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 38, 38, 0.5);
}

.btn-black.disabled, .btn-black:disabled {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

.btn-black:not(:disabled):not(.disabled):active, .btn-black:not(:disabled):not(.disabled).active,
.show > .btn-black.dropdown-toggle {
  color: #fff;
  background-color: black;
  border-color: black;
}

.btn-black:not(:disabled):not(.disabled):active:focus, .btn-black:not(:disabled):not(.disabled).active:focus,
.show > .btn-black.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 38, 38, 0.5);
}

.btn-dark-light {
  color: #fff;
  background-color: #262a2e;
  border-color: #262a2e;
}

.btn-dark-light:hover {
  color: #fff;
  background-color: #151719;
  border-color: #0f1112;
}

.btn-dark-light:focus, .btn-dark-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(71, 74, 77, 0.5);
}

.btn-dark-light.disabled, .btn-dark-light:disabled {
  color: #fff;
  background-color: #262a2e;
  border-color: #262a2e;
}

.btn-dark-light:not(:disabled):not(.disabled):active, .btn-dark-light:not(:disabled):not(.disabled).active,
.show > .btn-dark-light.dropdown-toggle {
  color: #fff;
  background-color: #0f1112;
  border-color: #090a0b;
}

.btn-dark-light:not(:disabled):not(.disabled):active:focus, .btn-dark-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(71, 74, 77, 0.5);
}

.btn-stone {
  color: #fff;
  background-color: #232629;
  border-color: #232629;
}

.btn-stone:hover {
  color: #fff;
  background-color: #111314;
  border-color: #0c0d0d;
}

.btn-stone:focus, .btn-stone.focus {
  box-shadow: 0 0 0 0.2rem rgba(68, 71, 73, 0.5);
}

.btn-stone.disabled, .btn-stone:disabled {
  color: #fff;
  background-color: #232629;
  border-color: #232629;
}

.btn-stone:not(:disabled):not(.disabled):active, .btn-stone:not(:disabled):not(.disabled).active,
.show > .btn-stone.dropdown-toggle {
  color: #fff;
  background-color: #0c0d0d;
  border-color: #060607;
}

.btn-stone:not(:disabled):not(.disabled):active:focus, .btn-stone:not(:disabled):not(.disabled).active:focus,
.show > .btn-stone.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(68, 71, 73, 0.5);
}

.btn-gray-color {
  color: #212529;
  background-color: #969696;
  border-color: #969696;
}

.btn-gray-color:hover {
  color: #fff;
  background-color: #838383;
  border-color: #7d7d7d;
}

.btn-gray-color:focus, .btn-gray-color.focus {
  box-shadow: 0 0 0 0.2rem rgba(132, 133, 134, 0.5);
}

.btn-gray-color.disabled, .btn-gray-color:disabled {
  color: #212529;
  background-color: #969696;
  border-color: #969696;
}

.btn-gray-color:not(:disabled):not(.disabled):active, .btn-gray-color:not(:disabled):not(.disabled).active,
.show > .btn-gray-color.dropdown-toggle {
  color: #fff;
  background-color: #7d7d7d;
  border-color: #767676;
}

.btn-gray-color:not(:disabled):not(.disabled):active:focus, .btn-gray-color:not(:disabled):not(.disabled).active:focus,
.show > .btn-gray-color.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(132, 133, 134, 0.5);
}

.btn-gray-dark {
  color: #fff;
  background-color: #565b61;
  border-color: #565b61;
}

.btn-gray-dark:hover {
  color: #fff;
  background-color: #44484d;
  border-color: #3e4246;
}

.btn-gray-dark:focus, .btn-gray-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(111, 116, 121, 0.5);
}

.btn-gray-dark.disabled, .btn-gray-dark:disabled {
  color: #fff;
  background-color: #565b61;
  border-color: #565b61;
}

.btn-gray-dark:not(:disabled):not(.disabled):active, .btn-gray-dark:not(:disabled):not(.disabled).active,
.show > .btn-gray-dark.dropdown-toggle {
  color: #fff;
  background-color: #3e4246;
  border-color: #383b3f;
}

.btn-gray-dark:not(:disabled):not(.disabled):active:focus, .btn-gray-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-gray-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(111, 116, 121, 0.5);
}

.btn-smoke {
  color: #212529;
  background-color: #f5f5f5;
  border-color: #f5f5f5;
}

.btn-smoke:hover {
  color: #212529;
  background-color: #e2e2e2;
  border-color: gainsboro;
}

.btn-smoke:focus, .btn-smoke.focus {
  box-shadow: 0 0 0 0.2rem rgba(213, 214, 214, 0.5);
}

.btn-smoke.disabled, .btn-smoke:disabled {
  color: #212529;
  background-color: #f5f5f5;
  border-color: #f5f5f5;
}

.btn-smoke:not(:disabled):not(.disabled):active, .btn-smoke:not(:disabled):not(.disabled).active,
.show > .btn-smoke.dropdown-toggle {
  color: #212529;
  background-color: gainsboro;
  border-color: #d5d5d5;
}

.btn-smoke:not(:disabled):not(.disabled):active:focus, .btn-smoke:not(:disabled):not(.disabled).active:focus,
.show > .btn-smoke.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(213, 214, 214, 0.5);
}

.btn-off-white {
  color: #212529;
  background-color: #dedede;
  border-color: #dedede;
}

.btn-off-white:hover {
  color: #212529;
  background-color: #cbcbcb;
  border-color: #c5c5c5;
}

.btn-off-white:focus, .btn-off-white.focus {
  box-shadow: 0 0 0 0.2rem rgba(194, 194, 195, 0.5);
}

.btn-off-white.disabled, .btn-off-white:disabled {
  color: #212529;
  background-color: #dedede;
  border-color: #dedede;
}

.btn-off-white:not(:disabled):not(.disabled):active, .btn-off-white:not(:disabled):not(.disabled).active,
.show > .btn-off-white.dropdown-toggle {
  color: #212529;
  background-color: #c5c5c5;
  border-color: #bebebe;
}

.btn-off-white:not(:disabled):not(.disabled):active:focus, .btn-off-white:not(:disabled):not(.disabled).active:focus,
.show > .btn-off-white.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(194, 194, 195, 0.5);
}

.btn-light-gray {
  color: #212529;
  background-color: #eee;
  border-color: #eee;
}

.btn-light-gray:hover {
  color: #212529;
  background-color: #dbdbdb;
  border-color: #d5d5d5;
}

.btn-light-gray:focus, .btn-light-gray.focus {
  box-shadow: 0 0 0 0.2rem rgba(207, 208, 208, 0.5);
}

.btn-light-gray.disabled, .btn-light-gray:disabled {
  color: #212529;
  background-color: #eee;
  border-color: #eee;
}

.btn-light-gray:not(:disabled):not(.disabled):active, .btn-light-gray:not(:disabled):not(.disabled).active,
.show > .btn-light-gray.dropdown-toggle {
  color: #212529;
  background-color: #d5d5d5;
  border-color: #cecece;
}

.btn-light-gray:not(:disabled):not(.disabled):active:focus, .btn-light-gray:not(:disabled):not(.disabled).active:focus,
.show > .btn-light-gray.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(207, 208, 208, 0.5);
}

.btn-facebook {
  color: #fff;
  background-color: #6684c4;
  border-color: #6684c4;
}

.btn-facebook:hover {
  color: #fff;
  background-color: #4a6eb9;
  border-color: #4568b2;
}

.btn-facebook:focus, .btn-facebook.focus {
  box-shadow: 0 0 0 0.2rem rgba(125, 150, 205, 0.5);
}

.btn-facebook.disabled, .btn-facebook:disabled {
  color: #fff;
  background-color: #6684c4;
  border-color: #6684c4;
}

.btn-facebook:not(:disabled):not(.disabled):active, .btn-facebook:not(:disabled):not(.disabled).active,
.show > .btn-facebook.dropdown-toggle {
  color: #fff;
  background-color: #4568b2;
  border-color: #4162a9;
}

.btn-facebook:not(:disabled):not(.disabled):active:focus, .btn-facebook:not(:disabled):not(.disabled).active:focus,
.show > .btn-facebook.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(125, 150, 205, 0.5);
}

.btn-outline-primary {
  color: #ff891e;
  border-color: #ff891e;
}

.btn-outline-primary:hover {
  color: #212529;
  background-color: #ff891e;
  border-color: #ff891e;
}

.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 137, 30, 0.5);
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #ff891e;
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #212529;
  background-color: #ff891e;
  border-color: #ff891e;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 137, 30, 0.5);
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-success {
  color: #60a662;
  border-color: #60a662;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #60a662;
  border-color: #60a662;
}

.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(96, 166, 98, 0.5);
}

.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #60a662;
  background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #60a662;
  border-color: #60a662;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(96, 166, 98, 0.5);
}

.btn-outline-info {
  color: #1ab3e8;
  border-color: #1ab3e8;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #1ab3e8;
  border-color: #1ab3e8;
}

.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(26, 179, 232, 0.5);
}

.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #1ab3e8;
  background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #1ab3e8;
  border-color: #1ab3e8;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(26, 179, 232, 0.5);
}

.btn-outline-warning {
  color: #fec701;
  border-color: #fec701;
}

.btn-outline-warning:hover {
  color: #212529;
  background-color: #fec701;
  border-color: #fec701;
}

.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(254, 199, 1, 0.5);
}

.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #fec701;
  background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #fec701;
  border-color: #fec701;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(254, 199, 1, 0.5);
}

.btn-outline-danger {
  color: #e25a5a;
  border-color: #e25a5a;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #e25a5a;
  border-color: #e25a5a;
}

.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(226, 90, 90, 0.5);
}

.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #e25a5a;
  background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #e25a5a;
  border-color: #e25a5a;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(226, 90, 90, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #222;
  border-color: #222;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #222;
  border-color: #222;
}

.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(34, 34, 34, 0.5);
}

.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #222;
  background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #222;
  border-color: #222;
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(34, 34, 34, 0.5);
}

.btn-outline-success-light {
  color: #1cd066;
  border-color: #1cd066;
}

.btn-outline-success-light:hover {
  color: #fff;
  background-color: #1cd066;
  border-color: #1cd066;
}

.btn-outline-success-light:focus, .btn-outline-success-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(28, 208, 102, 0.5);
}

.btn-outline-success-light.disabled, .btn-outline-success-light:disabled {
  color: #1cd066;
  background-color: transparent;
}

.btn-outline-success-light:not(:disabled):not(.disabled):active, .btn-outline-success-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-success-light.dropdown-toggle {
  color: #fff;
  background-color: #1cd066;
  border-color: #1cd066;
}

.btn-outline-success-light:not(:disabled):not(.disabled):active:focus, .btn-outline-success-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(28, 208, 102, 0.5);
}

.btn-outline-black {
  color: #000;
  border-color: #000;
}

.btn-outline-black:hover {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

.btn-outline-black:focus, .btn-outline-black.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}

.btn-outline-black.disabled, .btn-outline-black:disabled {
  color: #000;
  background-color: transparent;
}

.btn-outline-black:not(:disabled):not(.disabled):active, .btn-outline-black:not(:disabled):not(.disabled).active,
.show > .btn-outline-black.dropdown-toggle {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

.btn-outline-black:not(:disabled):not(.disabled):active:focus, .btn-outline-black:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-black.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}

.btn-outline-dark-light {
  color: #262a2e;
  border-color: #262a2e;
}

.btn-outline-dark-light:hover {
  color: #fff;
  background-color: #262a2e;
  border-color: #262a2e;
}

.btn-outline-dark-light:focus, .btn-outline-dark-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 42, 46, 0.5);
}

.btn-outline-dark-light.disabled, .btn-outline-dark-light:disabled {
  color: #262a2e;
  background-color: transparent;
}

.btn-outline-dark-light:not(:disabled):not(.disabled):active, .btn-outline-dark-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark-light.dropdown-toggle {
  color: #fff;
  background-color: #262a2e;
  border-color: #262a2e;
}

.btn-outline-dark-light:not(:disabled):not(.disabled):active:focus, .btn-outline-dark-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 42, 46, 0.5);
}

.btn-outline-stone {
  color: #232629;
  border-color: #232629;
}

.btn-outline-stone:hover {
  color: #fff;
  background-color: #232629;
  border-color: #232629;
}

.btn-outline-stone:focus, .btn-outline-stone.focus {
  box-shadow: 0 0 0 0.2rem rgba(35, 38, 41, 0.5);
}

.btn-outline-stone.disabled, .btn-outline-stone:disabled {
  color: #232629;
  background-color: transparent;
}

.btn-outline-stone:not(:disabled):not(.disabled):active, .btn-outline-stone:not(:disabled):not(.disabled).active,
.show > .btn-outline-stone.dropdown-toggle {
  color: #fff;
  background-color: #232629;
  border-color: #232629;
}

.btn-outline-stone:not(:disabled):not(.disabled):active:focus, .btn-outline-stone:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-stone.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(35, 38, 41, 0.5);
}

.btn-outline-gray-color {
  color: #969696;
  border-color: #969696;
}

.btn-outline-gray-color:hover {
  color: #212529;
  background-color: #969696;
  border-color: #969696;
}

.btn-outline-gray-color:focus, .btn-outline-gray-color.focus {
  box-shadow: 0 0 0 0.2rem rgba(150, 150, 150, 0.5);
}

.btn-outline-gray-color.disabled, .btn-outline-gray-color:disabled {
  color: #969696;
  background-color: transparent;
}

.btn-outline-gray-color:not(:disabled):not(.disabled):active, .btn-outline-gray-color:not(:disabled):not(.disabled).active,
.show > .btn-outline-gray-color.dropdown-toggle {
  color: #212529;
  background-color: #969696;
  border-color: #969696;
}

.btn-outline-gray-color:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-color:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-gray-color.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(150, 150, 150, 0.5);
}

.btn-outline-gray-dark {
  color: #565b61;
  border-color: #565b61;
}

.btn-outline-gray-dark:hover {
  color: #fff;
  background-color: #565b61;
  border-color: #565b61;
}

.btn-outline-gray-dark:focus, .btn-outline-gray-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(86, 91, 97, 0.5);
}

.btn-outline-gray-dark.disabled, .btn-outline-gray-dark:disabled {
  color: #565b61;
  background-color: transparent;
}

.btn-outline-gray-dark:not(:disabled):not(.disabled):active, .btn-outline-gray-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-gray-dark.dropdown-toggle {
  color: #fff;
  background-color: #565b61;
  border-color: #565b61;
}

.btn-outline-gray-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-gray-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(86, 91, 97, 0.5);
}

.btn-outline-smoke {
  color: #f5f5f5;
  border-color: #f5f5f5;
}

.btn-outline-smoke:hover {
  color: #212529;
  background-color: #f5f5f5;
  border-color: #f5f5f5;
}

.btn-outline-smoke:focus, .btn-outline-smoke.focus {
  box-shadow: 0 0 0 0.2rem rgba(245, 245, 245, 0.5);
}

.btn-outline-smoke.disabled, .btn-outline-smoke:disabled {
  color: #f5f5f5;
  background-color: transparent;
}

.btn-outline-smoke:not(:disabled):not(.disabled):active, .btn-outline-smoke:not(:disabled):not(.disabled).active,
.show > .btn-outline-smoke.dropdown-toggle {
  color: #212529;
  background-color: #f5f5f5;
  border-color: #f5f5f5;
}

.btn-outline-smoke:not(:disabled):not(.disabled):active:focus, .btn-outline-smoke:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-smoke.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(245, 245, 245, 0.5);
}

.btn-outline-off-white {
  color: #dedede;
  border-color: #dedede;
}

.btn-outline-off-white:hover {
  color: #212529;
  background-color: #dedede;
  border-color: #dedede;
}

.btn-outline-off-white:focus, .btn-outline-off-white.focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 222, 222, 0.5);
}

.btn-outline-off-white.disabled, .btn-outline-off-white:disabled {
  color: #dedede;
  background-color: transparent;
}

.btn-outline-off-white:not(:disabled):not(.disabled):active, .btn-outline-off-white:not(:disabled):not(.disabled).active,
.show > .btn-outline-off-white.dropdown-toggle {
  color: #212529;
  background-color: #dedede;
  border-color: #dedede;
}

.btn-outline-off-white:not(:disabled):not(.disabled):active:focus, .btn-outline-off-white:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-off-white.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 222, 222, 0.5);
}

.btn-outline-light-gray {
  color: #eee;
  border-color: #eee;
}

.btn-outline-light-gray:hover {
  color: #212529;
  background-color: #eee;
  border-color: #eee;
}

.btn-outline-light-gray:focus, .btn-outline-light-gray.focus {
  box-shadow: 0 0 0 0.2rem rgba(238, 238, 238, 0.5);
}

.btn-outline-light-gray.disabled, .btn-outline-light-gray:disabled {
  color: #eee;
  background-color: transparent;
}

.btn-outline-light-gray:not(:disabled):not(.disabled):active, .btn-outline-light-gray:not(:disabled):not(.disabled).active,
.show > .btn-outline-light-gray.dropdown-toggle {
  color: #212529;
  background-color: #eee;
  border-color: #eee;
}

.btn-outline-light-gray:not(:disabled):not(.disabled):active:focus, .btn-outline-light-gray:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light-gray.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(238, 238, 238, 0.5);
}

.btn-outline-facebook {
  color: #6684c4;
  border-color: #6684c4;
}

.btn-outline-facebook:hover {
  color: #fff;
  background-color: #6684c4;
  border-color: #6684c4;
}

.btn-outline-facebook:focus, .btn-outline-facebook.focus {
  box-shadow: 0 0 0 0.2rem rgba(102, 132, 196, 0.5);
}

.btn-outline-facebook.disabled, .btn-outline-facebook:disabled {
  color: #6684c4;
  background-color: transparent;
}

.btn-outline-facebook:not(:disabled):not(.disabled):active, .btn-outline-facebook:not(:disabled):not(.disabled).active,
.show > .btn-outline-facebook.dropdown-toggle {
  color: #fff;
  background-color: #6684c4;
  border-color: #6684c4;
}

.btn-outline-facebook:not(:disabled):not(.disabled):active:focus, .btn-outline-facebook:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-facebook.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(102, 132, 196, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #ff891e;
  text-decoration: none;
}

.btn-link:hover {
  color: #d16300;
  text-decoration: underline;
}

.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
  box-shadow: none;
}

.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.8rem 1.6rem;
  font-size: 1.125rem;
  line-height: 1.5;
  border-radius: 0.25rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.4rem 1.45rem;
  font-size: 0.875rem;
  line-height: 1.5rem;
  border-radius: 0.25rem;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 0.875rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}

.dropleft .dropdown-toggle::after {
  display: none;
}

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}

.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #ff891e;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.76562rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  -ms-flex: 1 1 auto;
      flex: 1 1 auto;
}

.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}

.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-pack: start;
      justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 1.35938rem;
  padding-left: 1.35938rem;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 1.0875rem;
  padding-left: 1.0875rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 1.2rem;
  padding-left: 1.2rem;
}

.btn-group-vertical {
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-align: start;
      align-items: flex-start;
  -ms-flex-pack: center;
      justify-content: center;
}

.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}

.btn-group-toggle > .btn input[type="radio"],
.btn-group-toggle > .btn input[type="checkbox"],
.btn-group-toggle > .btn-group > .btn input[type="radio"],
.btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-align: stretch;
      align-items: stretch;
  width: 100%;
}

.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  -ms-flex: 1 1 auto;
      flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}

.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}

.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}

.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}

.input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .custom-file {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
}

.input-group > .custom-file:not(:last-child) .custom-file-label,
.input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: -ms-flexbox;
  display: flex;
}

.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}

.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}

.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  padding: 0.7rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.input-group-text input[type="radio"],
.input-group-text input[type="checkbox"] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1.6rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.8rem 1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.5rem;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.8rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.4rem 0.7rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  display: block;
  min-height: 1.3125rem;
  padding-left: 1.5rem;
}

.custom-control-inline {
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #ff891e;
  background-color: #ff891e;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(255, 137, 30, 0.25);
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #ffcc9e;
}

.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #ffe7d1;
  border-color: #ffe7d1;
}

.custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}

.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}

.custom-control-label::before {
  position: absolute;
  top: 0.15625rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #adb5bd solid 1px;
}

.custom-control-label::after {
  position: absolute;
  top: 0.15625rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: no-repeat 50% / 50% 50%;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #ff891e;
  background-color: #ff891e;
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(255, 137, 30, 0.5);
}

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(255, 137, 30, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(255, 137, 30, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}

.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}

.custom-switch .custom-control-label::after {
  top: calc(0.15625rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  transform: translateX(0.75rem);
}

.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(255, 137, 30, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 1.4rem + 2px);
  padding: 0.7rem 1.75rem 0.7rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}

.custom-select:focus {
  border-color: #ffcc9e;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 137, 30, 0.25);
}

.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}

.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}

.custom-select::-ms-expand {
  display: none;
}

.custom-select-sm {
  height: calc(1.5em + 0.8rem + 2px);
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  padding-left: 0.7rem;
  font-size: 0.875rem;
}

.custom-select-lg {
  height: calc(1.5em + 1.6rem + 2px);
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  padding-left: 1rem;
  font-size: 0.875rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 1.4rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 1.4rem + 2px);
  margin: 0;
  opacity: 0;
}

.custom-file-input:focus ~ .custom-file-label {
  border-color: #ffcc9e;
  box-shadow: 0 0 0 0.2rem rgba(255, 137, 30, 0.25);
}

.custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}

.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}

.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 1.4rem + 2px);
  padding: 0.7rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 1.4rem);
  padding: 0.7rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}

.custom-range {
  width: 100%;
  height: calc(1rem + 0.4rem);
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}

.custom-range:focus {
  outline: none;
}

.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(255, 137, 30, 0.25);
}

.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(255, 137, 30, 0.25);
}

.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(255, 137, 30, 0.25);
}

.custom-range::-moz-focus-outer {
  border: 0;
}

.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #ff891e;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
          appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}

.custom-range::-webkit-slider-thumb:active {
  background-color: #ffe7d1;
}

.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #ff891e;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -moz-appearance: none;
       appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}

.custom-range::-moz-range-thumb:active {
  background-color: #ffe7d1;
}

.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #ff891e;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}

.custom-range::-ms-thumb:active {
  background-color: #ffe7d1;
}

.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}

.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}

.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}

.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}

.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}

.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.custom-range:disabled::-moz-range-track {
  cursor: default;
}

.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}

.nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}

.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}

.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}

.nav-tabs .nav-item {
  margin-bottom: -1px;
}

.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}

.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #ff891e;
}

.nav-fill .nav-item {
  -ms-flex: 1 1 auto;
      flex: 1 1 auto;
  text-align: center;
}

.nav-justified .nav-item {
  -ms-flex-preferred-size: 0;
      flex-basis: 0;
  -ms-flex-positive: 1;
      flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: justify;
      justify-content: space-between;
  padding: 0.5rem 1rem;
}

.navbar > .container,
.navbar > .container-fluid {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: justify;
      justify-content: space-between;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.33594rem;
  padding-bottom: 0.33594rem;
  margin-right: 1rem;
  font-size: 1.09375rem;
  line-height: inherit;
  white-space: nowrap;
}

.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  -ms-flex-preferred-size: 100%;
      flex-basis: 100%;
  -ms-flex-positive: 1;
      flex-grow: 1;
  -ms-flex-align: center;
      align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.09375rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    -ms-flex-flow: row nowrap;
        flex-flow: row nowrap;
    -ms-flex-pack: start;
        justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    -ms-flex-direction: row;
        flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
        flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    -ms-flex-flow: row nowrap;
        flex-flow: row nowrap;
    -ms-flex-pack: start;
        justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    -ms-flex-direction: row;
        flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
        flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    -ms-flex-flow: row nowrap;
        flex-flow: row nowrap;
    -ms-flex-pack: start;
        justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    -ms-flex-direction: row;
        flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
        flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    -ms-flex-flow: row nowrap;
        flex-flow: row nowrap;
    -ms-flex-pack: start;
        justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    -ms-flex-direction: row;
        flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
        flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  -ms-flex-flow: row nowrap;
      flex-flow: row nowrap;
  -ms-flex-pack: start;
      justify-content: flex-start;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid {
  padding-right: 0;
  padding-left: 0;
}

.navbar-expand .navbar-nav {
  -ms-flex-direction: row;
      flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid {
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
}

.navbar-expand .navbar-collapse {
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-preferred-size: auto;
      flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}

.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-text a {
  color: #fff;
}

.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid transparent;
  border-radius: 0.25rem;
}

.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.card-body {
  -ms-flex: 1 1 auto;
      flex: 1 1 auto;
  padding: 1.5rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1.5rem;
}

.card-header {
  padding: 0.75rem 1.5rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.06);
  border-bottom: 0 solid transparent;
}

.card-header:first-child {
  border-radius: 0.25rem 0.25rem 0 0;
}

.card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}

.card-footer {
  padding: 0.75rem 1.5rem;
  background-color: rgba(0, 0, 0, 0.06);
  border-top: 0 solid transparent;
}

.card-footer:last-child {
  border-radius: 0 0 0.25rem 0.25rem;
}

.card-header-tabs {
  margin-right: -0.75rem;
  margin-bottom: -0.75rem;
  margin-left: -0.75rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.75rem;
  margin-left: -0.75rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}

.card-img {
  width: 100%;
  border-radius: 0.25rem;
}

.card-img-top {
  width: 100%;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.card-deck {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
}

.card-deck .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-deck {
    -ms-flex-flow: row wrap;
        flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1 0 0%;
        flex: 1 0 0%;
    -ms-flex-direction: column;
        flex-direction: column;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
}

.card-group > .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-group {
    -ms-flex-flow: row wrap;
        flex-flow: row wrap;
  }
  .card-group > .card {
    -ms-flex: 1 0 0%;
        flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion > .card {
  overflow: hidden;
}

.accordion > .card:not(:first-of-type) .card-header:first-child {
  border-radius: 0;
}

.accordion > .card:not(:first-of-type):not(:last-of-type) {
  border-bottom: 0;
  border-radius: 0;
}

.accordion > .card:first-of-type {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.accordion > .card:last-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.accordion > .card .card-header {
  margin-bottom: 0;
}

.breadcrumb {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}

.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.8rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #222;
  background-color: transparent;
  border: 1px solid #dee2e6;
}

.page-link:hover {
  z-index: 2;
  color: #ff891e;
  text-decoration: none;
  background-color: transparent;
  border-color: transparent;
}

.page-link:focus {
  z-index: 2;
  outline: none;
  box-shadow: none;
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.page-item.active .page-link {
  z-index: 1;
  color: #ff891e;
  background-color: #ff891e;
  border-color: transparent;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.09375rem;
  line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.76562rem;
  line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: all 0.3s;
}

@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}

a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #212529;
  background-color: #ff891e;
}

a.badge-primary:hover, a.badge-primary:focus {
  color: #212529;
  background-color: #ea6f00;
}

a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 137, 30, 0.5);
}

.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}

a.badge-secondary:hover, a.badge-secondary:focus {
  color: #fff;
  background-color: #545b62;
}

a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.badge-success {
  color: #fff;
  background-color: #60a662;
}

a.badge-success:hover, a.badge-success:focus {
  color: #fff;
  background-color: #4c874d;
}

a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(96, 166, 98, 0.5);
}

.badge-info {
  color: #fff;
  background-color: #1ab3e8;
}

a.badge-info:hover, a.badge-info:focus {
  color: #fff;
  background-color: #1391bc;
}

a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(26, 179, 232, 0.5);
}

.badge-warning {
  color: #212529;
  background-color: #fec701;
}

a.badge-warning:hover, a.badge-warning:focus {
  color: #212529;
  background-color: #cb9f01;
}

a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(254, 199, 1, 0.5);
}

.badge-danger {
  color: #fff;
  background-color: #e25a5a;
}

a.badge-danger:hover, a.badge-danger:focus {
  color: #fff;
  background-color: #da2f2f;
}

a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(226, 90, 90, 0.5);
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}

a.badge-light:hover, a.badge-light:focus {
  color: #212529;
  background-color: #dae0e5;
}

a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.badge-dark {
  color: #fff;
  background-color: #222;
}

a.badge-dark:hover, a.badge-dark:focus {
  color: #fff;
  background-color: #090909;
}

a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(34, 34, 34, 0.5);
}

.badge-success-light {
  color: #fff;
  background-color: #1cd066;
}

a.badge-success-light:hover, a.badge-success-light:focus {
  color: #fff;
  background-color: #16a350;
}

a.badge-success-light:focus, a.badge-success-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(28, 208, 102, 0.5);
}

.badge-black {
  color: #fff;
  background-color: #000;
}

a.badge-black:hover, a.badge-black:focus {
  color: #fff;
  background-color: black;
}

a.badge-black:focus, a.badge-black.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}

.badge-dark-light {
  color: #fff;
  background-color: #262a2e;
}

a.badge-dark-light:hover, a.badge-dark-light:focus {
  color: #fff;
  background-color: #0f1112;
}

a.badge-dark-light:focus, a.badge-dark-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(38, 42, 46, 0.5);
}

.badge-stone {
  color: #fff;
  background-color: #232629;
}

a.badge-stone:hover, a.badge-stone:focus {
  color: #fff;
  background-color: #0c0d0d;
}

a.badge-stone:focus, a.badge-stone.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(35, 38, 41, 0.5);
}

.badge-gray-color {
  color: #212529;
  background-color: #969696;
}

a.badge-gray-color:hover, a.badge-gray-color:focus {
  color: #212529;
  background-color: #7d7d7d;
}

a.badge-gray-color:focus, a.badge-gray-color.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(150, 150, 150, 0.5);
}

.badge-gray-dark {
  color: #fff;
  background-color: #565b61;
}

a.badge-gray-dark:hover, a.badge-gray-dark:focus {
  color: #fff;
  background-color: #3e4246;
}

a.badge-gray-dark:focus, a.badge-gray-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(86, 91, 97, 0.5);
}

.badge-smoke {
  color: #212529;
  background-color: #f5f5f5;
}

a.badge-smoke:hover, a.badge-smoke:focus {
  color: #212529;
  background-color: gainsboro;
}

a.badge-smoke:focus, a.badge-smoke.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(245, 245, 245, 0.5);
}

.badge-off-white {
  color: #212529;
  background-color: #dedede;
}

a.badge-off-white:hover, a.badge-off-white:focus {
  color: #212529;
  background-color: #c5c5c5;
}

a.badge-off-white:focus, a.badge-off-white.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(222, 222, 222, 0.5);
}

.badge-light-gray {
  color: #212529;
  background-color: #eee;
}

a.badge-light-gray:hover, a.badge-light-gray:focus {
  color: #212529;
  background-color: #d5d5d5;
}

a.badge-light-gray:focus, a.badge-light-gray.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(238, 238, 238, 0.5);
}

.badge-facebook {
  color: #fff;
  background-color: #6684c4;
}

a.badge-facebook:hover, a.badge-facebook:focus {
  color: #fff;
  background-color: #4568b2;
}

a.badge-facebook:focus, a.badge-facebook.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(102, 132, 196, 0.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.5rem;
}

@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 3.8125rem;
}

.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #c26817;
  background-color: #ffe7d2;
  border-color: #ffdec0;
}

.alert-primary hr {
  border-top-color: #ffd1a7;
}

.alert-primary .alert-link {
  color: #945012;
}

.alert-secondary {
  color: #52595f;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}

.alert-secondary hr {
  border-top-color: #c8cbcf;
}

.alert-secondary .alert-link {
  color: #3a3f44;
}

.alert-success {
  color: #497e4a;
  background-color: #dfede0;
  border-color: #d2e6d3;
}

.alert-success hr {
  border-top-color: #c2ddc3;
}

.alert-success .alert-link {
  color: #365e37;
}

.alert-info {
  color: #1488b0;
  background-color: #d1f0fa;
  border-color: #bfeaf9;
}

.alert-info hr {
  border-top-color: #a8e2f7;
}

.alert-info .alert-link {
  color: #0f6582;
}

.alert-warning {
  color: #c19701;
  background-color: #fff4cc;
  border-color: #ffefb8;
}

.alert-warning hr {
  border-top-color: #ffe99f;
}

.alert-warning .alert-link {
  color: #8e6f01;
}

.alert-danger {
  color: #ac4444;
  background-color: #f9dede;
  border-color: #f7d1d1;
}

.alert-danger hr {
  border-top-color: #f3bbbb;
}

.alert-danger .alert-link {
  color: #873636;
}

.alert-light {
  color: #bcbdbe;
  background-color: #fefefe;
  border-color: #fdfdfe;
}

.alert-light hr {
  border-top-color: #ececf6;
}

.alert-light .alert-link {
  color: #a2a4a5;
}

.alert-dark {
  color: #1a1a1a;
  background-color: lightgray;
  border-color: #c1c1c1;
}

.alert-dark hr {
  border-top-color: #b4b4b4;
}

.alert-dark .alert-link {
  color: #010101;
}

.alert-success-light {
  color: #159e4e;
  background-color: #d2f6e0;
  border-color: #bff2d4;
}

.alert-success-light hr {
  border-top-color: #aaeec6;
}

.alert-success-light .alert-link {
  color: #0f7138;
}

.alert-black {
  color: black;
  background-color: #cccccc;
  border-color: #b8b8b8;
}

.alert-black hr {
  border-top-color: #ababab;
}

.alert-black .alert-link {
  color: black;
}

.alert-dark-light {
  color: #1d2023;
  background-color: #d4d4d5;
  border-color: #c2c3c4;
}

.alert-dark-light hr {
  border-top-color: #b5b6b7;
}

.alert-dark-light .alert-link {
  color: #060707;
}

.alert-stone {
  color: #1b1d1f;
  background-color: #d3d4d4;
  border-color: #c1c2c3;
}

.alert-stone hr {
  border-top-color: #b4b5b6;
}

.alert-stone .alert-link {
  color: #030404;
}

.alert-gray-color {
  color: #727272;
  background-color: #eaeaea;
  border-color: #e2e2e2;
}

.alert-gray-color hr {
  border-top-color: #d5d5d5;
}

.alert-gray-color .alert-link {
  color: #595959;
}

.alert-gray-dark {
  color: #41454a;
  background-color: #dddedf;
  border-color: #d0d1d3;
}

.alert-gray-dark hr {
  border-top-color: #c3c4c7;
}

.alert-gray-dark .alert-link {
  color: #292c2f;
}

.alert-smoke {
  color: #bababa;
  background-color: #fdfdfd;
  border-color: #fcfcfc;
}

.alert-smoke hr {
  border-top-color: #efefef;
}

.alert-smoke .alert-link {
  color: #a1a1a1;
}

.alert-off-white {
  color: darkgray;
  background-color: #f8f8f8;
  border-color: #f6f6f6;
}

.alert-off-white hr {
  border-top-color: #e9e9e9;
}

.alert-off-white .alert-link {
  color: #909090;
}

.alert-light-gray {
  color: #b5b5b5;
  background-color: #fcfcfc;
  border-color: #fafafa;
}

.alert-light-gray hr {
  border-top-color: #ededed;
}

.alert-light-gray .alert-link {
  color: #9c9c9c;
}

.alert-facebook {
  color: #4e6495;
  background-color: #e0e6f3;
  border-color: #d4ddee;
}

.alert-facebook hr {
  border-top-color: #c2cfe7;
}

.alert-facebook .alert-link {
  color: #3c4e74;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}

.progress {
  display: -ms-flexbox;
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.65625rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #ff891e;
  transition: width 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.media {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
      align-items: flex-start;
}

.media-body {
  -ms-flex: 1;
      flex: 1;
}

.list-group {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}

.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}

.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #ff891e;
  border-color: #ff891e;
}

.list-group-horizontal {
  -ms-flex-direction: row;
      flex-direction: row;
}

.list-group-horizontal .list-group-item {
  margin-right: -1px;
  margin-bottom: 0;
}

.list-group-horizontal .list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}

.list-group-horizontal .list-group-item:last-child {
  margin-right: 0;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    -ms-flex-direction: row;
        flex-direction: row;
  }
  .list-group-horizontal-sm .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }
  .list-group-horizontal-sm .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    -ms-flex-direction: row;
        flex-direction: row;
  }
  .list-group-horizontal-md .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }
  .list-group-horizontal-md .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    -ms-flex-direction: row;
        flex-direction: row;
  }
  .list-group-horizontal-lg .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }
  .list-group-horizontal-lg .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    -ms-flex-direction: row;
        flex-direction: row;
  }
  .list-group-horizontal-xl .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }
  .list-group-horizontal-xl .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
}

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}

.list-group-flush .list-group-item:last-child {
  margin-bottom: -1px;
}

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0;
}

.list-group-flush:last-child .list-group-item:last-child {
  margin-bottom: 0;
  border-bottom: 0;
}

.list-group-item-primary {
  color: #854710;
  background-color: #ffdec0;
}

.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #854710;
  background-color: #ffd1a7;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #854710;
  border-color: #854710;
}

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}

.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #383d41;
  background-color: #c8cbcf;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}

.list-group-item-success {
  color: #325633;
  background-color: #d2e6d3;
}

.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #325633;
  background-color: #c2ddc3;
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #325633;
  border-color: #325633;
}

.list-group-item-info {
  color: #0e5d79;
  background-color: #bfeaf9;
}

.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #0e5d79;
  background-color: #a8e2f7;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0e5d79;
  border-color: #0e5d79;
}

.list-group-item-warning {
  color: #846701;
  background-color: #ffefb8;
}

.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #846701;
  background-color: #ffe99f;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #846701;
  border-color: #846701;
}

.list-group-item-danger {
  color: #762f2f;
  background-color: #f7d1d1;
}

.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #762f2f;
  background-color: #f3bbbb;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #762f2f;
  border-color: #762f2f;
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}

.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark {
  color: #121212;
  background-color: #c1c1c1;
}

.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #121212;
  background-color: #b4b4b4;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #121212;
  border-color: #121212;
}

.list-group-item-success-light {
  color: #0f6c35;
  background-color: #bff2d4;
}

.list-group-item-success-light.list-group-item-action:hover, .list-group-item-success-light.list-group-item-action:focus {
  color: #0f6c35;
  background-color: #aaeec6;
}

.list-group-item-success-light.list-group-item-action.active {
  color: #fff;
  background-color: #0f6c35;
  border-color: #0f6c35;
}

.list-group-item-black {
  color: black;
  background-color: #b8b8b8;
}

.list-group-item-black.list-group-item-action:hover, .list-group-item-black.list-group-item-action:focus {
  color: black;
  background-color: #ababab;
}

.list-group-item-black.list-group-item-action.active {
  color: #fff;
  background-color: black;
  border-color: black;
}

.list-group-item-dark-light {
  color: #141618;
  background-color: #c2c3c4;
}

.list-group-item-dark-light.list-group-item-action:hover, .list-group-item-dark-light.list-group-item-action:focus {
  color: #141618;
  background-color: #b5b6b7;
}

.list-group-item-dark-light.list-group-item-action.active {
  color: #fff;
  background-color: #141618;
  border-color: #141618;
}

.list-group-item-stone {
  color: #121415;
  background-color: #c1c2c3;
}

.list-group-item-stone.list-group-item-action:hover, .list-group-item-stone.list-group-item-action:focus {
  color: #121415;
  background-color: #b4b5b6;
}

.list-group-item-stone.list-group-item-action.active {
  color: #fff;
  background-color: #121415;
  border-color: #121415;
}

.list-group-item-gray-color {
  color: #4e4e4e;
  background-color: #e2e2e2;
}

.list-group-item-gray-color.list-group-item-action:hover, .list-group-item-gray-color.list-group-item-action:focus {
  color: #4e4e4e;
  background-color: #d5d5d5;
}

.list-group-item-gray-color.list-group-item-action.active {
  color: #fff;
  background-color: #4e4e4e;
  border-color: #4e4e4e;
}

.list-group-item-gray-dark {
  color: #2d2f32;
  background-color: #d0d1d3;
}

.list-group-item-gray-dark.list-group-item-action:hover, .list-group-item-gray-dark.list-group-item-action:focus {
  color: #2d2f32;
  background-color: #c3c4c7;
}

.list-group-item-gray-dark.list-group-item-action.active {
  color: #fff;
  background-color: #2d2f32;
  border-color: #2d2f32;
}

.list-group-item-smoke {
  color: #7f7f7f;
  background-color: #fcfcfc;
}

.list-group-item-smoke.list-group-item-action:hover, .list-group-item-smoke.list-group-item-action:focus {
  color: #7f7f7f;
  background-color: #efefef;
}

.list-group-item-smoke.list-group-item-action.active {
  color: #fff;
  background-color: #7f7f7f;
  border-color: #7f7f7f;
}

.list-group-item-off-white {
  color: #737373;
  background-color: #f6f6f6;
}

.list-group-item-off-white.list-group-item-action:hover, .list-group-item-off-white.list-group-item-action:focus {
  color: #737373;
  background-color: #e9e9e9;
}

.list-group-item-off-white.list-group-item-action.active {
  color: #fff;
  background-color: #737373;
  border-color: #737373;
}

.list-group-item-light-gray {
  color: #7c7c7c;
  background-color: #fafafa;
}

.list-group-item-light-gray.list-group-item-action:hover, .list-group-item-light-gray.list-group-item-action:focus {
  color: #7c7c7c;
  background-color: #ededed;
}

.list-group-item-light-gray.list-group-item-action.active {
  color: #fff;
  background-color: #7c7c7c;
  border-color: #7c7c7c;
}

.list-group-item-facebook {
  color: #354566;
  background-color: #d4ddee;
}

.list-group-item-facebook.list-group-item-action:hover, .list-group-item-facebook.list-group-item-action:focus {
  color: #354566;
  background-color: #c2cfe7;
}

.list-group-item-facebook.list-group-item-action.active {
  color: #fff;
  background-color: #354566;
  border-color: #354566;
}

.close {
  float: right;
  font-size: 1.3125rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
}

.close:hover {
  color: #000;
  text-decoration: none;
}

.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: .75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.25rem;
}

.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}

.toast.showing {
  opacity: 1;
}

.toast.show {
  display: block;
  opacity: 1;
}

.toast.hide {
  display: none;
}

.toast-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal-dialog-scrollable {
  display: -ms-flexbox;
  display: flex;
  max-height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}

.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  -ms-flex-negative: 0;
      flex-shrink: 0;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  content: "";
}

.modal-dialog-centered.modal-dialog-scrollable {
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center;
  height: 100%;
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}

.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
      align-items: flex-start;
  -ms-flex-pack: justify;
      justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  -ms-flex: 1 1 auto;
      flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: end;
      justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.modal-footer > :not(:first-child) {
  margin-left: .25rem;
}

.modal-footer > :not(:last-child) {
  margin-right: .25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
  }
  .modal-sm {
    max-width: 400px;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.76562rem;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: 0.9;
}

.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}

.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0;
}

.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
  bottom: 0;
}

.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem;
}

.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0;
}

.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
  top: 0;
}

.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem;
}

.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.76562rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
}

.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.5rem;
}

.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem;
}

.bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
  bottom: calc((0.5rem + 1px) * -1);
}

.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem;
}

.bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
  left: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.5rem 0;
}

.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem;
}

.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
  top: calc((0.5rem + 1px) * -1);
}

.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}

.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem;
}

.bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
  right: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.5rem 0;
}

.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.5rem - 1px);
  border-top-right-radius: calc(0.5rem - 1px);
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  -ms-touch-action: pan-y;
      touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: 0s 0.6s opacity;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}

.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators li {
  box-sizing: content-box;
  -ms-flex: 0 1 auto;
      flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: .5;
  transition: opacity 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border .75s linear infinite;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
  }
}

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow .75s linear infinite;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #ff891e !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #ea6f00 !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important;
}

.bg-success {
  background-color: #60a662 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #4c874d !important;
}

.bg-info {
  background-color: #1ab3e8 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #1391bc !important;
}

.bg-warning {
  background-color: #fec701 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #cb9f01 !important;
}

.bg-danger {
  background-color: #e25a5a !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #da2f2f !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #222 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #090909 !important;
}

.bg-success-light {
  background-color: #1cd066 !important;
}

a.bg-success-light:hover, a.bg-success-light:focus,
button.bg-success-light:hover,
button.bg-success-light:focus {
  background-color: #16a350 !important;
}

.bg-black {
  background-color: #000 !important;
}

a.bg-black:hover, a.bg-black:focus,
button.bg-black:hover,
button.bg-black:focus {
  background-color: black !important;
}

.bg-dark-light {
  background-color: #262a2e !important;
}

a.bg-dark-light:hover, a.bg-dark-light:focus,
button.bg-dark-light:hover,
button.bg-dark-light:focus {
  background-color: #0f1112 !important;
}

.bg-stone {
  background-color: #232629 !important;
}

a.bg-stone:hover, a.bg-stone:focus,
button.bg-stone:hover,
button.bg-stone:focus {
  background-color: #0c0d0d !important;
}

.bg-gray-color {
  background-color: #969696 !important;
}

a.bg-gray-color:hover, a.bg-gray-color:focus,
button.bg-gray-color:hover,
button.bg-gray-color:focus {
  background-color: #7d7d7d !important;
}

.bg-gray-dark {
  background-color: #565b61 !important;
}

a.bg-gray-dark:hover, a.bg-gray-dark:focus,
button.bg-gray-dark:hover,
button.bg-gray-dark:focus {
  background-color: #3e4246 !important;
}

.bg-smoke {
  background-color: #f5f5f5 !important;
}

a.bg-smoke:hover, a.bg-smoke:focus,
button.bg-smoke:hover,
button.bg-smoke:focus {
  background-color: gainsboro !important;
}

.bg-off-white {
  background-color: #dedede !important;
}

a.bg-off-white:hover, a.bg-off-white:focus,
button.bg-off-white:hover,
button.bg-off-white:focus {
  background-color: #c5c5c5 !important;
}

.bg-light-gray {
  background-color: #eee !important;
}

a.bg-light-gray:hover, a.bg-light-gray:focus,
button.bg-light-gray:hover,
button.bg-light-gray:focus {
  background-color: #d5d5d5 !important;
}

.bg-facebook {
  background-color: #6684c4 !important;
}

a.bg-facebook:hover, a.bg-facebook:focus,
button.bg-facebook:hover,
button.bg-facebook:focus {
  background-color: #4568b2 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #ff891e !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #60a662 !important;
}

.border-info {
  border-color: #1ab3e8 !important;
}

.border-warning {
  border-color: #fec701 !important;
}

.border-danger {
  border-color: #e25a5a !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #222 !important;
}

.border-success-light {
  border-color: #1cd066 !important;
}

.border-black {
  border-color: #000 !important;
}

.border-dark-light {
  border-color: #262a2e !important;
}

.border-stone {
  border-color: #232629 !important;
}

.border-gray-color {
  border-color: #969696 !important;
}

.border-gray-dark {
  border-color: #565b61 !important;
}

.border-smoke {
  border-color: #f5f5f5 !important;
}

.border-off-white {
  border-color: #dedede !important;
}

.border-light-gray {
  border-color: #eee !important;
}

.border-facebook {
  border-color: #6684c4 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.5rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: -ms-flexbox !important;
  display: flex !important;
}

.d-inline-flex {
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-md-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-print-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive::before {
  display: block;
  content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  -ms-flex-direction: row !important;
      flex-direction: row !important;
}

.flex-column {
  -ms-flex-direction: column !important;
      flex-direction: column !important;
}

.flex-row-reverse {
  -ms-flex-direction: row-reverse !important;
      flex-direction: row-reverse !important;
}

.flex-column-reverse {
  -ms-flex-direction: column-reverse !important;
      flex-direction: column-reverse !important;
}

.flex-wrap {
  -ms-flex-wrap: wrap !important;
      flex-wrap: wrap !important;
}

.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
      flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
      flex-wrap: wrap-reverse !important;
}

.flex-fill {
  -ms-flex: 1 1 auto !important;
      flex: 1 1 auto !important;
}

.flex-grow-0 {
  -ms-flex-positive: 0 !important;
      flex-grow: 0 !important;
}

.flex-grow-1 {
  -ms-flex-positive: 1 !important;
      flex-grow: 1 !important;
}

.flex-shrink-0 {
  -ms-flex-negative: 0 !important;
      flex-shrink: 0 !important;
}

.flex-shrink-1 {
  -ms-flex-negative: 1 !important;
      flex-shrink: 1 !important;
}

.justify-content-start {
  -ms-flex-pack: start !important;
      justify-content: flex-start !important;
}

.justify-content-end {
  -ms-flex-pack: end !important;
      justify-content: flex-end !important;
}

.justify-content-center {
  -ms-flex-pack: center !important;
      justify-content: center !important;
}

.justify-content-between {
  -ms-flex-pack: justify !important;
      justify-content: space-between !important;
}

.justify-content-around {
  -ms-flex-pack: distribute !important;
      justify-content: space-around !important;
}

.align-items-start {
  -ms-flex-align: start !important;
      align-items: flex-start !important;
}

.align-items-end {
  -ms-flex-align: end !important;
      align-items: flex-end !important;
}

.align-items-center {
  -ms-flex-align: center !important;
      align-items: center !important;
}

.align-items-baseline {
  -ms-flex-align: baseline !important;
      align-items: baseline !important;
}

.align-items-stretch {
  -ms-flex-align: stretch !important;
      align-items: stretch !important;
}

.align-content-start {
  -ms-flex-line-pack: start !important;
      align-content: flex-start !important;
}

.align-content-end {
  -ms-flex-line-pack: end !important;
      align-content: flex-end !important;
}

.align-content-center {
  -ms-flex-line-pack: center !important;
      align-content: center !important;
}

.align-content-between {
  -ms-flex-line-pack: justify !important;
      align-content: space-between !important;
}

.align-content-around {
  -ms-flex-line-pack: distribute !important;
      align-content: space-around !important;
}

.align-content-stretch {
  -ms-flex-line-pack: stretch !important;
      align-content: stretch !important;
}

.align-self-auto {
  -ms-flex-item-align: auto !important;
      -ms-grid-row-align: auto !important;
      align-self: auto !important;
}

.align-self-start {
  -ms-flex-item-align: start !important;
      align-self: flex-start !important;
}

.align-self-end {
  -ms-flex-item-align: end !important;
      align-self: flex-end !important;
}

.align-self-center {
  -ms-flex-item-align: center !important;
      -ms-grid-row-align: center !important;
      align-self: center !important;
}

.align-self-baseline {
  -ms-flex-item-align: baseline !important;
      align-self: baseline !important;
}

.align-self-stretch {
  -ms-flex-item-align: stretch !important;
      -ms-grid-row-align: stretch !important;
      align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    -ms-flex-direction: row !important;
        flex-direction: row !important;
  }
  .flex-sm-column {
    -ms-flex-direction: column !important;
        flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    -ms-flex: 1 1 auto !important;
        flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    -ms-flex-positive: 0 !important;
        flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    -ms-flex-positive: 1 !important;
        flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    -ms-flex-pack: start !important;
        justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    -ms-flex-pack: end !important;
        justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    -ms-flex-pack: center !important;
        justify-content: center !important;
  }
  .justify-content-sm-between {
    -ms-flex-pack: justify !important;
        justify-content: space-between !important;
  }
  .justify-content-sm-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important;
  }
  .align-items-sm-start {
    -ms-flex-align: start !important;
        align-items: flex-start !important;
  }
  .align-items-sm-end {
    -ms-flex-align: end !important;
        align-items: flex-end !important;
  }
  .align-items-sm-center {
    -ms-flex-align: center !important;
        align-items: center !important;
  }
  .align-items-sm-baseline {
    -ms-flex-align: baseline !important;
        align-items: baseline !important;
  }
  .align-items-sm-stretch {
    -ms-flex-align: stretch !important;
        align-items: stretch !important;
  }
  .align-content-sm-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important;
  }
  .align-content-sm-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important;
  }
  .align-content-sm-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important;
  }
  .align-content-sm-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important;
  }
  .align-content-sm-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important;
  }
  .align-content-sm-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important;
  }
  .align-self-sm-auto {
    -ms-flex-item-align: auto !important;
        -ms-grid-row-align: auto !important;
        align-self: auto !important;
  }
  .align-self-sm-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important;
  }
  .align-self-sm-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important;
  }
  .align-self-sm-center {
    -ms-flex-item-align: center !important;
        -ms-grid-row-align: center !important;
        align-self: center !important;
  }
  .align-self-sm-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important;
  }
  .align-self-sm-stretch {
    -ms-flex-item-align: stretch !important;
        -ms-grid-row-align: stretch !important;
        align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    -ms-flex-direction: row !important;
        flex-direction: row !important;
  }
  .flex-md-column {
    -ms-flex-direction: column !important;
        flex-direction: column !important;
  }
  .flex-md-row-reverse {
    -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    -ms-flex: 1 1 auto !important;
        flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    -ms-flex-positive: 0 !important;
        flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    -ms-flex-positive: 1 !important;
        flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    -ms-flex-pack: start !important;
        justify-content: flex-start !important;
  }
  .justify-content-md-end {
    -ms-flex-pack: end !important;
        justify-content: flex-end !important;
  }
  .justify-content-md-center {
    -ms-flex-pack: center !important;
        justify-content: center !important;
  }
  .justify-content-md-between {
    -ms-flex-pack: justify !important;
        justify-content: space-between !important;
  }
  .justify-content-md-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important;
  }
  .align-items-md-start {
    -ms-flex-align: start !important;
        align-items: flex-start !important;
  }
  .align-items-md-end {
    -ms-flex-align: end !important;
        align-items: flex-end !important;
  }
  .align-items-md-center {
    -ms-flex-align: center !important;
        align-items: center !important;
  }
  .align-items-md-baseline {
    -ms-flex-align: baseline !important;
        align-items: baseline !important;
  }
  .align-items-md-stretch {
    -ms-flex-align: stretch !important;
        align-items: stretch !important;
  }
  .align-content-md-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important;
  }
  .align-content-md-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important;
  }
  .align-content-md-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important;
  }
  .align-content-md-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important;
  }
  .align-content-md-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important;
  }
  .align-content-md-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important;
  }
  .align-self-md-auto {
    -ms-flex-item-align: auto !important;
        -ms-grid-row-align: auto !important;
        align-self: auto !important;
  }
  .align-self-md-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important;
  }
  .align-self-md-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important;
  }
  .align-self-md-center {
    -ms-flex-item-align: center !important;
        -ms-grid-row-align: center !important;
        align-self: center !important;
  }
  .align-self-md-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important;
  }
  .align-self-md-stretch {
    -ms-flex-item-align: stretch !important;
        -ms-grid-row-align: stretch !important;
        align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    -ms-flex-direction: row !important;
        flex-direction: row !important;
  }
  .flex-lg-column {
    -ms-flex-direction: column !important;
        flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    -ms-flex: 1 1 auto !important;
        flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    -ms-flex-positive: 0 !important;
        flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    -ms-flex-positive: 1 !important;
        flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    -ms-flex-pack: start !important;
        justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    -ms-flex-pack: end !important;
        justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    -ms-flex-pack: center !important;
        justify-content: center !important;
  }
  .justify-content-lg-between {
    -ms-flex-pack: justify !important;
        justify-content: space-between !important;
  }
  .justify-content-lg-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important;
  }
  .align-items-lg-start {
    -ms-flex-align: start !important;
        align-items: flex-start !important;
  }
  .align-items-lg-end {
    -ms-flex-align: end !important;
        align-items: flex-end !important;
  }
  .align-items-lg-center {
    -ms-flex-align: center !important;
        align-items: center !important;
  }
  .align-items-lg-baseline {
    -ms-flex-align: baseline !important;
        align-items: baseline !important;
  }
  .align-items-lg-stretch {
    -ms-flex-align: stretch !important;
        align-items: stretch !important;
  }
  .align-content-lg-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important;
  }
  .align-content-lg-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important;
  }
  .align-content-lg-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important;
  }
  .align-content-lg-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important;
  }
  .align-content-lg-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important;
  }
  .align-content-lg-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important;
  }
  .align-self-lg-auto {
    -ms-flex-item-align: auto !important;
        -ms-grid-row-align: auto !important;
        align-self: auto !important;
  }
  .align-self-lg-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important;
  }
  .align-self-lg-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important;
  }
  .align-self-lg-center {
    -ms-flex-item-align: center !important;
        -ms-grid-row-align: center !important;
        align-self: center !important;
  }
  .align-self-lg-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important;
  }
  .align-self-lg-stretch {
    -ms-flex-item-align: stretch !important;
        -ms-grid-row-align: stretch !important;
        align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    -ms-flex-direction: row !important;
        flex-direction: row !important;
  }
  .flex-xl-column {
    -ms-flex-direction: column !important;
        flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    -ms-flex: 1 1 auto !important;
        flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    -ms-flex-positive: 0 !important;
        flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    -ms-flex-positive: 1 !important;
        flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    -ms-flex-pack: start !important;
        justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    -ms-flex-pack: end !important;
        justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    -ms-flex-pack: center !important;
        justify-content: center !important;
  }
  .justify-content-xl-between {
    -ms-flex-pack: justify !important;
        justify-content: space-between !important;
  }
  .justify-content-xl-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important;
  }
  .align-items-xl-start {
    -ms-flex-align: start !important;
        align-items: flex-start !important;
  }
  .align-items-xl-end {
    -ms-flex-align: end !important;
        align-items: flex-end !important;
  }
  .align-items-xl-center {
    -ms-flex-align: center !important;
        align-items: center !important;
  }
  .align-items-xl-baseline {
    -ms-flex-align: baseline !important;
        align-items: baseline !important;
  }
  .align-items-xl-stretch {
    -ms-flex-align: stretch !important;
        align-items: stretch !important;
  }
  .align-content-xl-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important;
  }
  .align-content-xl-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important;
  }
  .align-content-xl-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important;
  }
  .align-content-xl-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important;
  }
  .align-content-xl-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important;
  }
  .align-content-xl-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important;
  }
  .align-self-xl-auto {
    -ms-flex-item-align: auto !important;
        -ms-grid-row-align: auto !important;
        align-self: auto !important;
  }
  .align-self-xl-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important;
  }
  .align-self-xl-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important;
  }
  .align-self-xl-center {
    -ms-flex-item-align: center !important;
        -ms-grid-row-align: center !important;
        align-self: center !important;
  }
  .align-self-xl-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important;
  }
  .align-self-xl-stretch {
    -ms-flex-item-align: stretch !important;
        -ms-grid-row-align: stretch !important;
        align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.31rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.31rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.31rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.31rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.31rem !important;
}

.m-2 {
  margin: 0.63rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.63rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.63rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.63rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.63rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.25rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.25rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.25rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.25rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.25rem !important;
}

.m-5 {
  margin: 1.56rem !important;
}

.mt-5,
.my-5 {
  margin-top: 1.56rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 1.56rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 1.56rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 1.56rem !important;
}

.m-6 {
  margin: 1.88rem !important;
}

.mt-6,
.my-6 {
  margin-top: 1.88rem !important;
}

.mr-6,
.mx-6 {
  margin-right: 1.88rem !important;
}

.mb-6,
.my-6 {
  margin-bottom: 1.88rem !important;
}

.ml-6,
.mx-6 {
  margin-left: 1.88rem !important;
}

.m-7 {
  margin: 3.13rem !important;
}

.mt-7,
.my-7 {
  margin-top: 3.13rem !important;
}

.mr-7,
.mx-7 {
  margin-right: 3.13rem !important;
}

.mb-7,
.my-7 {
  margin-bottom: 3.13rem !important;
}

.ml-7,
.mx-7 {
  margin-left: 3.13rem !important;
}

.m-8 {
  margin: 3.75rem !important;
}

.mt-8,
.my-8 {
  margin-top: 3.75rem !important;
}

.mr-8,
.mx-8 {
  margin-right: 3.75rem !important;
}

.mb-8,
.my-8 {
  margin-bottom: 3.75rem !important;
}

.ml-8,
.mx-8 {
  margin-left: 3.75rem !important;
}

.m-9 {
  margin: 4.96rem !important;
}

.mt-9,
.my-9 {
  margin-top: 4.96rem !important;
}

.mr-9,
.mx-9 {
  margin-right: 4.96rem !important;
}

.mb-9,
.my-9 {
  margin-bottom: 4.96rem !important;
}

.ml-9,
.mx-9 {
  margin-left: 4.96rem !important;
}

.m-10 {
  margin: 6.25rem !important;
}

.mt-10,
.my-10 {
  margin-top: 6.25rem !important;
}

.mr-10,
.mx-10 {
  margin-right: 6.25rem !important;
}

.mb-10,
.my-10 {
  margin-bottom: 6.25rem !important;
}

.ml-10,
.mx-10 {
  margin-left: 6.25rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.31rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.31rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.31rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.31rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.31rem !important;
}

.p-2 {
  padding: 0.63rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.63rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.63rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.63rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.63rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.25rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.25rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.25rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.25rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.25rem !important;
}

.p-5 {
  padding: 1.56rem !important;
}

.pt-5,
.py-5 {
  padding-top: 1.56rem !important;
}

.pr-5,
.px-5 {
  padding-right: 1.56rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 1.56rem !important;
}

.pl-5,
.px-5 {
  padding-left: 1.56rem !important;
}

.p-6 {
  padding: 1.88rem !important;
}

.pt-6,
.py-6 {
  padding-top: 1.88rem !important;
}

.pr-6,
.px-6 {
  padding-right: 1.88rem !important;
}

.pb-6,
.py-6 {
  padding-bottom: 1.88rem !important;
}

.pl-6,
.px-6 {
  padding-left: 1.88rem !important;
}

.p-7 {
  padding: 3.13rem !important;
}

.pt-7,
.py-7 {
  padding-top: 3.13rem !important;
}

.pr-7,
.px-7 {
  padding-right: 3.13rem !important;
}

.pb-7,
.py-7 {
  padding-bottom: 3.13rem !important;
}

.pl-7,
.px-7 {
  padding-left: 3.13rem !important;
}

.p-8 {
  padding: 3.75rem !important;
}

.pt-8,
.py-8 {
  padding-top: 3.75rem !important;
}

.pr-8,
.px-8 {
  padding-right: 3.75rem !important;
}

.pb-8,
.py-8 {
  padding-bottom: 3.75rem !important;
}

.pl-8,
.px-8 {
  padding-left: 3.75rem !important;
}

.p-9 {
  padding: 4.96rem !important;
}

.pt-9,
.py-9 {
  padding-top: 4.96rem !important;
}

.pr-9,
.px-9 {
  padding-right: 4.96rem !important;
}

.pb-9,
.py-9 {
  padding-bottom: 4.96rem !important;
}

.pl-9,
.px-9 {
  padding-left: 4.96rem !important;
}

.p-10 {
  padding: 6.25rem !important;
}

.pt-10,
.py-10 {
  padding-top: 6.25rem !important;
}

.pr-10,
.px-10 {
  padding-right: 6.25rem !important;
}

.pb-10,
.py-10 {
  padding-bottom: 6.25rem !important;
}

.pl-10,
.px-10 {
  padding-left: 6.25rem !important;
}

.m-n1 {
  margin: -0.31rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.31rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.31rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.31rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.31rem !important;
}

.m-n2 {
  margin: -0.63rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.63rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.63rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.63rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.63rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.25rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.25rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.25rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.25rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.25rem !important;
}

.m-n5 {
  margin: -1.56rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -1.56rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -1.56rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -1.56rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -1.56rem !important;
}

.m-n6 {
  margin: -1.88rem !important;
}

.mt-n6,
.my-n6 {
  margin-top: -1.88rem !important;
}

.mr-n6,
.mx-n6 {
  margin-right: -1.88rem !important;
}

.mb-n6,
.my-n6 {
  margin-bottom: -1.88rem !important;
}

.ml-n6,
.mx-n6 {
  margin-left: -1.88rem !important;
}

.m-n7 {
  margin: -3.13rem !important;
}

.mt-n7,
.my-n7 {
  margin-top: -3.13rem !important;
}

.mr-n7,
.mx-n7 {
  margin-right: -3.13rem !important;
}

.mb-n7,
.my-n7 {
  margin-bottom: -3.13rem !important;
}

.ml-n7,
.mx-n7 {
  margin-left: -3.13rem !important;
}

.m-n8 {
  margin: -3.75rem !important;
}

.mt-n8,
.my-n8 {
  margin-top: -3.75rem !important;
}

.mr-n8,
.mx-n8 {
  margin-right: -3.75rem !important;
}

.mb-n8,
.my-n8 {
  margin-bottom: -3.75rem !important;
}

.ml-n8,
.mx-n8 {
  margin-left: -3.75rem !important;
}

.m-n9 {
  margin: -4.96rem !important;
}

.mt-n9,
.my-n9 {
  margin-top: -4.96rem !important;
}

.mr-n9,
.mx-n9 {
  margin-right: -4.96rem !important;
}

.mb-n9,
.my-n9 {
  margin-bottom: -4.96rem !important;
}

.ml-n9,
.mx-n9 {
  margin-left: -4.96rem !important;
}

.m-n10 {
  margin: -6.25rem !important;
}

.mt-n10,
.my-n10 {
  margin-top: -6.25rem !important;
}

.mr-n10,
.mx-n10 {
  margin-right: -6.25rem !important;
}

.mb-n10,
.my-n10 {
  margin-bottom: -6.25rem !important;
}

.ml-n10,
.mx-n10 {
  margin-left: -6.25rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.31rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.31rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.31rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.31rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.31rem !important;
  }
  .m-sm-2 {
    margin: 0.63rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.63rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.63rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.63rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.63rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.25rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.25rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.25rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.25rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.25rem !important;
  }
  .m-sm-5 {
    margin: 1.56rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 1.56rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 1.56rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 1.56rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 1.56rem !important;
  }
  .m-sm-6 {
    margin: 1.88rem !important;
  }
  .mt-sm-6,
  .my-sm-6 {
    margin-top: 1.88rem !important;
  }
  .mr-sm-6,
  .mx-sm-6 {
    margin-right: 1.88rem !important;
  }
  .mb-sm-6,
  .my-sm-6 {
    margin-bottom: 1.88rem !important;
  }
  .ml-sm-6,
  .mx-sm-6 {
    margin-left: 1.88rem !important;
  }
  .m-sm-7 {
    margin: 3.13rem !important;
  }
  .mt-sm-7,
  .my-sm-7 {
    margin-top: 3.13rem !important;
  }
  .mr-sm-7,
  .mx-sm-7 {
    margin-right: 3.13rem !important;
  }
  .mb-sm-7,
  .my-sm-7 {
    margin-bottom: 3.13rem !important;
  }
  .ml-sm-7,
  .mx-sm-7 {
    margin-left: 3.13rem !important;
  }
  .m-sm-8 {
    margin: 3.75rem !important;
  }
  .mt-sm-8,
  .my-sm-8 {
    margin-top: 3.75rem !important;
  }
  .mr-sm-8,
  .mx-sm-8 {
    margin-right: 3.75rem !important;
  }
  .mb-sm-8,
  .my-sm-8 {
    margin-bottom: 3.75rem !important;
  }
  .ml-sm-8,
  .mx-sm-8 {
    margin-left: 3.75rem !important;
  }
  .m-sm-9 {
    margin: 4.96rem !important;
  }
  .mt-sm-9,
  .my-sm-9 {
    margin-top: 4.96rem !important;
  }
  .mr-sm-9,
  .mx-sm-9 {
    margin-right: 4.96rem !important;
  }
  .mb-sm-9,
  .my-sm-9 {
    margin-bottom: 4.96rem !important;
  }
  .ml-sm-9,
  .mx-sm-9 {
    margin-left: 4.96rem !important;
  }
  .m-sm-10 {
    margin: 6.25rem !important;
  }
  .mt-sm-10,
  .my-sm-10 {
    margin-top: 6.25rem !important;
  }
  .mr-sm-10,
  .mx-sm-10 {
    margin-right: 6.25rem !important;
  }
  .mb-sm-10,
  .my-sm-10 {
    margin-bottom: 6.25rem !important;
  }
  .ml-sm-10,
  .mx-sm-10 {
    margin-left: 6.25rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.31rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.31rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.31rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.31rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.31rem !important;
  }
  .p-sm-2 {
    padding: 0.63rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.63rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.63rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.63rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.63rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.25rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.25rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.25rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.25rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.25rem !important;
  }
  .p-sm-5 {
    padding: 1.56rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 1.56rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 1.56rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 1.56rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 1.56rem !important;
  }
  .p-sm-6 {
    padding: 1.88rem !important;
  }
  .pt-sm-6,
  .py-sm-6 {
    padding-top: 1.88rem !important;
  }
  .pr-sm-6,
  .px-sm-6 {
    padding-right: 1.88rem !important;
  }
  .pb-sm-6,
  .py-sm-6 {
    padding-bottom: 1.88rem !important;
  }
  .pl-sm-6,
  .px-sm-6 {
    padding-left: 1.88rem !important;
  }
  .p-sm-7 {
    padding: 3.13rem !important;
  }
  .pt-sm-7,
  .py-sm-7 {
    padding-top: 3.13rem !important;
  }
  .pr-sm-7,
  .px-sm-7 {
    padding-right: 3.13rem !important;
  }
  .pb-sm-7,
  .py-sm-7 {
    padding-bottom: 3.13rem !important;
  }
  .pl-sm-7,
  .px-sm-7 {
    padding-left: 3.13rem !important;
  }
  .p-sm-8 {
    padding: 3.75rem !important;
  }
  .pt-sm-8,
  .py-sm-8 {
    padding-top: 3.75rem !important;
  }
  .pr-sm-8,
  .px-sm-8 {
    padding-right: 3.75rem !important;
  }
  .pb-sm-8,
  .py-sm-8 {
    padding-bottom: 3.75rem !important;
  }
  .pl-sm-8,
  .px-sm-8 {
    padding-left: 3.75rem !important;
  }
  .p-sm-9 {
    padding: 4.96rem !important;
  }
  .pt-sm-9,
  .py-sm-9 {
    padding-top: 4.96rem !important;
  }
  .pr-sm-9,
  .px-sm-9 {
    padding-right: 4.96rem !important;
  }
  .pb-sm-9,
  .py-sm-9 {
    padding-bottom: 4.96rem !important;
  }
  .pl-sm-9,
  .px-sm-9 {
    padding-left: 4.96rem !important;
  }
  .p-sm-10 {
    padding: 6.25rem !important;
  }
  .pt-sm-10,
  .py-sm-10 {
    padding-top: 6.25rem !important;
  }
  .pr-sm-10,
  .px-sm-10 {
    padding-right: 6.25rem !important;
  }
  .pb-sm-10,
  .py-sm-10 {
    padding-bottom: 6.25rem !important;
  }
  .pl-sm-10,
  .px-sm-10 {
    padding-left: 6.25rem !important;
  }
  .m-sm-n1 {
    margin: -0.31rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.31rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.31rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.31rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.31rem !important;
  }
  .m-sm-n2 {
    margin: -0.63rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.63rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.63rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.63rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.63rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.25rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.25rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.25rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.25rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.25rem !important;
  }
  .m-sm-n5 {
    margin: -1.56rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -1.56rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -1.56rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -1.56rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -1.56rem !important;
  }
  .m-sm-n6 {
    margin: -1.88rem !important;
  }
  .mt-sm-n6,
  .my-sm-n6 {
    margin-top: -1.88rem !important;
  }
  .mr-sm-n6,
  .mx-sm-n6 {
    margin-right: -1.88rem !important;
  }
  .mb-sm-n6,
  .my-sm-n6 {
    margin-bottom: -1.88rem !important;
  }
  .ml-sm-n6,
  .mx-sm-n6 {
    margin-left: -1.88rem !important;
  }
  .m-sm-n7 {
    margin: -3.13rem !important;
  }
  .mt-sm-n7,
  .my-sm-n7 {
    margin-top: -3.13rem !important;
  }
  .mr-sm-n7,
  .mx-sm-n7 {
    margin-right: -3.13rem !important;
  }
  .mb-sm-n7,
  .my-sm-n7 {
    margin-bottom: -3.13rem !important;
  }
  .ml-sm-n7,
  .mx-sm-n7 {
    margin-left: -3.13rem !important;
  }
  .m-sm-n8 {
    margin: -3.75rem !important;
  }
  .mt-sm-n8,
  .my-sm-n8 {
    margin-top: -3.75rem !important;
  }
  .mr-sm-n8,
  .mx-sm-n8 {
    margin-right: -3.75rem !important;
  }
  .mb-sm-n8,
  .my-sm-n8 {
    margin-bottom: -3.75rem !important;
  }
  .ml-sm-n8,
  .mx-sm-n8 {
    margin-left: -3.75rem !important;
  }
  .m-sm-n9 {
    margin: -4.96rem !important;
  }
  .mt-sm-n9,
  .my-sm-n9 {
    margin-top: -4.96rem !important;
  }
  .mr-sm-n9,
  .mx-sm-n9 {
    margin-right: -4.96rem !important;
  }
  .mb-sm-n9,
  .my-sm-n9 {
    margin-bottom: -4.96rem !important;
  }
  .ml-sm-n9,
  .mx-sm-n9 {
    margin-left: -4.96rem !important;
  }
  .m-sm-n10 {
    margin: -6.25rem !important;
  }
  .mt-sm-n10,
  .my-sm-n10 {
    margin-top: -6.25rem !important;
  }
  .mr-sm-n10,
  .mx-sm-n10 {
    margin-right: -6.25rem !important;
  }
  .mb-sm-n10,
  .my-sm-n10 {
    margin-bottom: -6.25rem !important;
  }
  .ml-sm-n10,
  .mx-sm-n10 {
    margin-left: -6.25rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.31rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.31rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.31rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.31rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.31rem !important;
  }
  .m-md-2 {
    margin: 0.63rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.63rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.63rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.63rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.63rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.25rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.25rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.25rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.25rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.25rem !important;
  }
  .m-md-5 {
    margin: 1.56rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 1.56rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 1.56rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 1.56rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 1.56rem !important;
  }
  .m-md-6 {
    margin: 1.88rem !important;
  }
  .mt-md-6,
  .my-md-6 {
    margin-top: 1.88rem !important;
  }
  .mr-md-6,
  .mx-md-6 {
    margin-right: 1.88rem !important;
  }
  .mb-md-6,
  .my-md-6 {
    margin-bottom: 1.88rem !important;
  }
  .ml-md-6,
  .mx-md-6 {
    margin-left: 1.88rem !important;
  }
  .m-md-7 {
    margin: 3.13rem !important;
  }
  .mt-md-7,
  .my-md-7 {
    margin-top: 3.13rem !important;
  }
  .mr-md-7,
  .mx-md-7 {
    margin-right: 3.13rem !important;
  }
  .mb-md-7,
  .my-md-7 {
    margin-bottom: 3.13rem !important;
  }
  .ml-md-7,
  .mx-md-7 {
    margin-left: 3.13rem !important;
  }
  .m-md-8 {
    margin: 3.75rem !important;
  }
  .mt-md-8,
  .my-md-8 {
    margin-top: 3.75rem !important;
  }
  .mr-md-8,
  .mx-md-8 {
    margin-right: 3.75rem !important;
  }
  .mb-md-8,
  .my-md-8 {
    margin-bottom: 3.75rem !important;
  }
  .ml-md-8,
  .mx-md-8 {
    margin-left: 3.75rem !important;
  }
  .m-md-9 {
    margin: 4.96rem !important;
  }
  .mt-md-9,
  .my-md-9 {
    margin-top: 4.96rem !important;
  }
  .mr-md-9,
  .mx-md-9 {
    margin-right: 4.96rem !important;
  }
  .mb-md-9,
  .my-md-9 {
    margin-bottom: 4.96rem !important;
  }
  .ml-md-9,
  .mx-md-9 {
    margin-left: 4.96rem !important;
  }
  .m-md-10 {
    margin: 6.25rem !important;
  }
  .mt-md-10,
  .my-md-10 {
    margin-top: 6.25rem !important;
  }
  .mr-md-10,
  .mx-md-10 {
    margin-right: 6.25rem !important;
  }
  .mb-md-10,
  .my-md-10 {
    margin-bottom: 6.25rem !important;
  }
  .ml-md-10,
  .mx-md-10 {
    margin-left: 6.25rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.31rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.31rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.31rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.31rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.31rem !important;
  }
  .p-md-2 {
    padding: 0.63rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.63rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.63rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.63rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.63rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.25rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.25rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.25rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.25rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.25rem !important;
  }
  .p-md-5 {
    padding: 1.56rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 1.56rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 1.56rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 1.56rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 1.56rem !important;
  }
  .p-md-6 {
    padding: 1.88rem !important;
  }
  .pt-md-6,
  .py-md-6 {
    padding-top: 1.88rem !important;
  }
  .pr-md-6,
  .px-md-6 {
    padding-right: 1.88rem !important;
  }
  .pb-md-6,
  .py-md-6 {
    padding-bottom: 1.88rem !important;
  }
  .pl-md-6,
  .px-md-6 {
    padding-left: 1.88rem !important;
  }
  .p-md-7 {
    padding: 3.13rem !important;
  }
  .pt-md-7,
  .py-md-7 {
    padding-top: 3.13rem !important;
  }
  .pr-md-7,
  .px-md-7 {
    padding-right: 3.13rem !important;
  }
  .pb-md-7,
  .py-md-7 {
    padding-bottom: 3.13rem !important;
  }
  .pl-md-7,
  .px-md-7 {
    padding-left: 3.13rem !important;
  }
  .p-md-8 {
    padding: 3.75rem !important;
  }
  .pt-md-8,
  .py-md-8 {
    padding-top: 3.75rem !important;
  }
  .pr-md-8,
  .px-md-8 {
    padding-right: 3.75rem !important;
  }
  .pb-md-8,
  .py-md-8 {
    padding-bottom: 3.75rem !important;
  }
  .pl-md-8,
  .px-md-8 {
    padding-left: 3.75rem !important;
  }
  .p-md-9 {
    padding: 4.96rem !important;
  }
  .pt-md-9,
  .py-md-9 {
    padding-top: 4.96rem !important;
  }
  .pr-md-9,
  .px-md-9 {
    padding-right: 4.96rem !important;
  }
  .pb-md-9,
  .py-md-9 {
    padding-bottom: 4.96rem !important;
  }
  .pl-md-9,
  .px-md-9 {
    padding-left: 4.96rem !important;
  }
  .p-md-10 {
    padding: 6.25rem !important;
  }
  .pt-md-10,
  .py-md-10 {
    padding-top: 6.25rem !important;
  }
  .pr-md-10,
  .px-md-10 {
    padding-right: 6.25rem !important;
  }
  .pb-md-10,
  .py-md-10 {
    padding-bottom: 6.25rem !important;
  }
  .pl-md-10,
  .px-md-10 {
    padding-left: 6.25rem !important;
  }
  .m-md-n1 {
    margin: -0.31rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.31rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.31rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.31rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.31rem !important;
  }
  .m-md-n2 {
    margin: -0.63rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.63rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.63rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.63rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.63rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.25rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.25rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.25rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.25rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.25rem !important;
  }
  .m-md-n5 {
    margin: -1.56rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -1.56rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -1.56rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -1.56rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -1.56rem !important;
  }
  .m-md-n6 {
    margin: -1.88rem !important;
  }
  .mt-md-n6,
  .my-md-n6 {
    margin-top: -1.88rem !important;
  }
  .mr-md-n6,
  .mx-md-n6 {
    margin-right: -1.88rem !important;
  }
  .mb-md-n6,
  .my-md-n6 {
    margin-bottom: -1.88rem !important;
  }
  .ml-md-n6,
  .mx-md-n6 {
    margin-left: -1.88rem !important;
  }
  .m-md-n7 {
    margin: -3.13rem !important;
  }
  .mt-md-n7,
  .my-md-n7 {
    margin-top: -3.13rem !important;
  }
  .mr-md-n7,
  .mx-md-n7 {
    margin-right: -3.13rem !important;
  }
  .mb-md-n7,
  .my-md-n7 {
    margin-bottom: -3.13rem !important;
  }
  .ml-md-n7,
  .mx-md-n7 {
    margin-left: -3.13rem !important;
  }
  .m-md-n8 {
    margin: -3.75rem !important;
  }
  .mt-md-n8,
  .my-md-n8 {
    margin-top: -3.75rem !important;
  }
  .mr-md-n8,
  .mx-md-n8 {
    margin-right: -3.75rem !important;
  }
  .mb-md-n8,
  .my-md-n8 {
    margin-bottom: -3.75rem !important;
  }
  .ml-md-n8,
  .mx-md-n8 {
    margin-left: -3.75rem !important;
  }
  .m-md-n9 {
    margin: -4.96rem !important;
  }
  .mt-md-n9,
  .my-md-n9 {
    margin-top: -4.96rem !important;
  }
  .mr-md-n9,
  .mx-md-n9 {
    margin-right: -4.96rem !important;
  }
  .mb-md-n9,
  .my-md-n9 {
    margin-bottom: -4.96rem !important;
  }
  .ml-md-n9,
  .mx-md-n9 {
    margin-left: -4.96rem !important;
  }
  .m-md-n10 {
    margin: -6.25rem !important;
  }
  .mt-md-n10,
  .my-md-n10 {
    margin-top: -6.25rem !important;
  }
  .mr-md-n10,
  .mx-md-n10 {
    margin-right: -6.25rem !important;
  }
  .mb-md-n10,
  .my-md-n10 {
    margin-bottom: -6.25rem !important;
  }
  .ml-md-n10,
  .mx-md-n10 {
    margin-left: -6.25rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.31rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.31rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.31rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.31rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.31rem !important;
  }
  .m-lg-2 {
    margin: 0.63rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.63rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.63rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.63rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.63rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.25rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.25rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.25rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.25rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.25rem !important;
  }
  .m-lg-5 {
    margin: 1.56rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 1.56rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 1.56rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 1.56rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 1.56rem !important;
  }
  .m-lg-6 {
    margin: 1.88rem !important;
  }
  .mt-lg-6,
  .my-lg-6 {
    margin-top: 1.88rem !important;
  }
  .mr-lg-6,
  .mx-lg-6 {
    margin-right: 1.88rem !important;
  }
  .mb-lg-6,
  .my-lg-6 {
    margin-bottom: 1.88rem !important;
  }
  .ml-lg-6,
  .mx-lg-6 {
    margin-left: 1.88rem !important;
  }
  .m-lg-7 {
    margin: 3.13rem !important;
  }
  .mt-lg-7,
  .my-lg-7 {
    margin-top: 3.13rem !important;
  }
  .mr-lg-7,
  .mx-lg-7 {
    margin-right: 3.13rem !important;
  }
  .mb-lg-7,
  .my-lg-7 {
    margin-bottom: 3.13rem !important;
  }
  .ml-lg-7,
  .mx-lg-7 {
    margin-left: 3.13rem !important;
  }
  .m-lg-8 {
    margin: 3.75rem !important;
  }
  .mt-lg-8,
  .my-lg-8 {
    margin-top: 3.75rem !important;
  }
  .mr-lg-8,
  .mx-lg-8 {
    margin-right: 3.75rem !important;
  }
  .mb-lg-8,
  .my-lg-8 {
    margin-bottom: 3.75rem !important;
  }
  .ml-lg-8,
  .mx-lg-8 {
    margin-left: 3.75rem !important;
  }
  .m-lg-9 {
    margin: 4.96rem !important;
  }
  .mt-lg-9,
  .my-lg-9 {
    margin-top: 4.96rem !important;
  }
  .mr-lg-9,
  .mx-lg-9 {
    margin-right: 4.96rem !important;
  }
  .mb-lg-9,
  .my-lg-9 {
    margin-bottom: 4.96rem !important;
  }
  .ml-lg-9,
  .mx-lg-9 {
    margin-left: 4.96rem !important;
  }
  .m-lg-10 {
    margin: 6.25rem !important;
  }
  .mt-lg-10,
  .my-lg-10 {
    margin-top: 6.25rem !important;
  }
  .mr-lg-10,
  .mx-lg-10 {
    margin-right: 6.25rem !important;
  }
  .mb-lg-10,
  .my-lg-10 {
    margin-bottom: 6.25rem !important;
  }
  .ml-lg-10,
  .mx-lg-10 {
    margin-left: 6.25rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.31rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.31rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.31rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.31rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.31rem !important;
  }
  .p-lg-2 {
    padding: 0.63rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.63rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.63rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.63rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.63rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.25rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.25rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.25rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.25rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.25rem !important;
  }
  .p-lg-5 {
    padding: 1.56rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 1.56rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 1.56rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 1.56rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 1.56rem !important;
  }
  .p-lg-6 {
    padding: 1.88rem !important;
  }
  .pt-lg-6,
  .py-lg-6 {
    padding-top: 1.88rem !important;
  }
  .pr-lg-6,
  .px-lg-6 {
    padding-right: 1.88rem !important;
  }
  .pb-lg-6,
  .py-lg-6 {
    padding-bottom: 1.88rem !important;
  }
  .pl-lg-6,
  .px-lg-6 {
    padding-left: 1.88rem !important;
  }
  .p-lg-7 {
    padding: 3.13rem !important;
  }
  .pt-lg-7,
  .py-lg-7 {
    padding-top: 3.13rem !important;
  }
  .pr-lg-7,
  .px-lg-7 {
    padding-right: 3.13rem !important;
  }
  .pb-lg-7,
  .py-lg-7 {
    padding-bottom: 3.13rem !important;
  }
  .pl-lg-7,
  .px-lg-7 {
    padding-left: 3.13rem !important;
  }
  .p-lg-8 {
    padding: 3.75rem !important;
  }
  .pt-lg-8,
  .py-lg-8 {
    padding-top: 3.75rem !important;
  }
  .pr-lg-8,
  .px-lg-8 {
    padding-right: 3.75rem !important;
  }
  .pb-lg-8,
  .py-lg-8 {
    padding-bottom: 3.75rem !important;
  }
  .pl-lg-8,
  .px-lg-8 {
    padding-left: 3.75rem !important;
  }
  .p-lg-9 {
    padding: 4.96rem !important;
  }
  .pt-lg-9,
  .py-lg-9 {
    padding-top: 4.96rem !important;
  }
  .pr-lg-9,
  .px-lg-9 {
    padding-right: 4.96rem !important;
  }
  .pb-lg-9,
  .py-lg-9 {
    padding-bottom: 4.96rem !important;
  }
  .pl-lg-9,
  .px-lg-9 {
    padding-left: 4.96rem !important;
  }
  .p-lg-10 {
    padding: 6.25rem !important;
  }
  .pt-lg-10,
  .py-lg-10 {
    padding-top: 6.25rem !important;
  }
  .pr-lg-10,
  .px-lg-10 {
    padding-right: 6.25rem !important;
  }
  .pb-lg-10,
  .py-lg-10 {
    padding-bottom: 6.25rem !important;
  }
  .pl-lg-10,
  .px-lg-10 {
    padding-left: 6.25rem !important;
  }
  .m-lg-n1 {
    margin: -0.31rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.31rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.31rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.31rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.31rem !important;
  }
  .m-lg-n2 {
    margin: -0.63rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.63rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.63rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.63rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.63rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.25rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.25rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.25rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.25rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.25rem !important;
  }
  .m-lg-n5 {
    margin: -1.56rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -1.56rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -1.56rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -1.56rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -1.56rem !important;
  }
  .m-lg-n6 {
    margin: -1.88rem !important;
  }
  .mt-lg-n6,
  .my-lg-n6 {
    margin-top: -1.88rem !important;
  }
  .mr-lg-n6,
  .mx-lg-n6 {
    margin-right: -1.88rem !important;
  }
  .mb-lg-n6,
  .my-lg-n6 {
    margin-bottom: -1.88rem !important;
  }
  .ml-lg-n6,
  .mx-lg-n6 {
    margin-left: -1.88rem !important;
  }
  .m-lg-n7 {
    margin: -3.13rem !important;
  }
  .mt-lg-n7,
  .my-lg-n7 {
    margin-top: -3.13rem !important;
  }
  .mr-lg-n7,
  .mx-lg-n7 {
    margin-right: -3.13rem !important;
  }
  .mb-lg-n7,
  .my-lg-n7 {
    margin-bottom: -3.13rem !important;
  }
  .ml-lg-n7,
  .mx-lg-n7 {
    margin-left: -3.13rem !important;
  }
  .m-lg-n8 {
    margin: -3.75rem !important;
  }
  .mt-lg-n8,
  .my-lg-n8 {
    margin-top: -3.75rem !important;
  }
  .mr-lg-n8,
  .mx-lg-n8 {
    margin-right: -3.75rem !important;
  }
  .mb-lg-n8,
  .my-lg-n8 {
    margin-bottom: -3.75rem !important;
  }
  .ml-lg-n8,
  .mx-lg-n8 {
    margin-left: -3.75rem !important;
  }
  .m-lg-n9 {
    margin: -4.96rem !important;
  }
  .mt-lg-n9,
  .my-lg-n9 {
    margin-top: -4.96rem !important;
  }
  .mr-lg-n9,
  .mx-lg-n9 {
    margin-right: -4.96rem !important;
  }
  .mb-lg-n9,
  .my-lg-n9 {
    margin-bottom: -4.96rem !important;
  }
  .ml-lg-n9,
  .mx-lg-n9 {
    margin-left: -4.96rem !important;
  }
  .m-lg-n10 {
    margin: -6.25rem !important;
  }
  .mt-lg-n10,
  .my-lg-n10 {
    margin-top: -6.25rem !important;
  }
  .mr-lg-n10,
  .mx-lg-n10 {
    margin-right: -6.25rem !important;
  }
  .mb-lg-n10,
  .my-lg-n10 {
    margin-bottom: -6.25rem !important;
  }
  .ml-lg-n10,
  .mx-lg-n10 {
    margin-left: -6.25rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.31rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.31rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.31rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.31rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.31rem !important;
  }
  .m-xl-2 {
    margin: 0.63rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.63rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.63rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.63rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.63rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.25rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.25rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.25rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.25rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.25rem !important;
  }
  .m-xl-5 {
    margin: 1.56rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 1.56rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 1.56rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 1.56rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 1.56rem !important;
  }
  .m-xl-6 {
    margin: 1.88rem !important;
  }
  .mt-xl-6,
  .my-xl-6 {
    margin-top: 1.88rem !important;
  }
  .mr-xl-6,
  .mx-xl-6 {
    margin-right: 1.88rem !important;
  }
  .mb-xl-6,
  .my-xl-6 {
    margin-bottom: 1.88rem !important;
  }
  .ml-xl-6,
  .mx-xl-6 {
    margin-left: 1.88rem !important;
  }
  .m-xl-7 {
    margin: 3.13rem !important;
  }
  .mt-xl-7,
  .my-xl-7 {
    margin-top: 3.13rem !important;
  }
  .mr-xl-7,
  .mx-xl-7 {
    margin-right: 3.13rem !important;
  }
  .mb-xl-7,
  .my-xl-7 {
    margin-bottom: 3.13rem !important;
  }
  .ml-xl-7,
  .mx-xl-7 {
    margin-left: 3.13rem !important;
  }
  .m-xl-8 {
    margin: 3.75rem !important;
  }
  .mt-xl-8,
  .my-xl-8 {
    margin-top: 3.75rem !important;
  }
  .mr-xl-8,
  .mx-xl-8 {
    margin-right: 3.75rem !important;
  }
  .mb-xl-8,
  .my-xl-8 {
    margin-bottom: 3.75rem !important;
  }
  .ml-xl-8,
  .mx-xl-8 {
    margin-left: 3.75rem !important;
  }
  .m-xl-9 {
    margin: 4.96rem !important;
  }
  .mt-xl-9,
  .my-xl-9 {
    margin-top: 4.96rem !important;
  }
  .mr-xl-9,
  .mx-xl-9 {
    margin-right: 4.96rem !important;
  }
  .mb-xl-9,
  .my-xl-9 {
    margin-bottom: 4.96rem !important;
  }
  .ml-xl-9,
  .mx-xl-9 {
    margin-left: 4.96rem !important;
  }
  .m-xl-10 {
    margin: 6.25rem !important;
  }
  .mt-xl-10,
  .my-xl-10 {
    margin-top: 6.25rem !important;
  }
  .mr-xl-10,
  .mx-xl-10 {
    margin-right: 6.25rem !important;
  }
  .mb-xl-10,
  .my-xl-10 {
    margin-bottom: 6.25rem !important;
  }
  .ml-xl-10,
  .mx-xl-10 {
    margin-left: 6.25rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.31rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.31rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.31rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.31rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.31rem !important;
  }
  .p-xl-2 {
    padding: 0.63rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.63rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.63rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.63rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.63rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.25rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.25rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.25rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.25rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.25rem !important;
  }
  .p-xl-5 {
    padding: 1.56rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 1.56rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 1.56rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 1.56rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 1.56rem !important;
  }
  .p-xl-6 {
    padding: 1.88rem !important;
  }
  .pt-xl-6,
  .py-xl-6 {
    padding-top: 1.88rem !important;
  }
  .pr-xl-6,
  .px-xl-6 {
    padding-right: 1.88rem !important;
  }
  .pb-xl-6,
  .py-xl-6 {
    padding-bottom: 1.88rem !important;
  }
  .pl-xl-6,
  .px-xl-6 {
    padding-left: 1.88rem !important;
  }
  .p-xl-7 {
    padding: 3.13rem !important;
  }
  .pt-xl-7,
  .py-xl-7 {
    padding-top: 3.13rem !important;
  }
  .pr-xl-7,
  .px-xl-7 {
    padding-right: 3.13rem !important;
  }
  .pb-xl-7,
  .py-xl-7 {
    padding-bottom: 3.13rem !important;
  }
  .pl-xl-7,
  .px-xl-7 {
    padding-left: 3.13rem !important;
  }
  .p-xl-8 {
    padding: 3.75rem !important;
  }
  .pt-xl-8,
  .py-xl-8 {
    padding-top: 3.75rem !important;
  }
  .pr-xl-8,
  .px-xl-8 {
    padding-right: 3.75rem !important;
  }
  .pb-xl-8,
  .py-xl-8 {
    padding-bottom: 3.75rem !important;
  }
  .pl-xl-8,
  .px-xl-8 {
    padding-left: 3.75rem !important;
  }
  .p-xl-9 {
    padding: 4.96rem !important;
  }
  .pt-xl-9,
  .py-xl-9 {
    padding-top: 4.96rem !important;
  }
  .pr-xl-9,
  .px-xl-9 {
    padding-right: 4.96rem !important;
  }
  .pb-xl-9,
  .py-xl-9 {
    padding-bottom: 4.96rem !important;
  }
  .pl-xl-9,
  .px-xl-9 {
    padding-left: 4.96rem !important;
  }
  .p-xl-10 {
    padding: 6.25rem !important;
  }
  .pt-xl-10,
  .py-xl-10 {
    padding-top: 6.25rem !important;
  }
  .pr-xl-10,
  .px-xl-10 {
    padding-right: 6.25rem !important;
  }
  .pb-xl-10,
  .py-xl-10 {
    padding-bottom: 6.25rem !important;
  }
  .pl-xl-10,
  .px-xl-10 {
    padding-left: 6.25rem !important;
  }
  .m-xl-n1 {
    margin: -0.31rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.31rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.31rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.31rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.31rem !important;
  }
  .m-xl-n2 {
    margin: -0.63rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.63rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.63rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.63rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.63rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.25rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.25rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.25rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.25rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.25rem !important;
  }
  .m-xl-n5 {
    margin: -1.56rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -1.56rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -1.56rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -1.56rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -1.56rem !important;
  }
  .m-xl-n6 {
    margin: -1.88rem !important;
  }
  .mt-xl-n6,
  .my-xl-n6 {
    margin-top: -1.88rem !important;
  }
  .mr-xl-n6,
  .mx-xl-n6 {
    margin-right: -1.88rem !important;
  }
  .mb-xl-n6,
  .my-xl-n6 {
    margin-bottom: -1.88rem !important;
  }
  .ml-xl-n6,
  .mx-xl-n6 {
    margin-left: -1.88rem !important;
  }
  .m-xl-n7 {
    margin: -3.13rem !important;
  }
  .mt-xl-n7,
  .my-xl-n7 {
    margin-top: -3.13rem !important;
  }
  .mr-xl-n7,
  .mx-xl-n7 {
    margin-right: -3.13rem !important;
  }
  .mb-xl-n7,
  .my-xl-n7 {
    margin-bottom: -3.13rem !important;
  }
  .ml-xl-n7,
  .mx-xl-n7 {
    margin-left: -3.13rem !important;
  }
  .m-xl-n8 {
    margin: -3.75rem !important;
  }
  .mt-xl-n8,
  .my-xl-n8 {
    margin-top: -3.75rem !important;
  }
  .mr-xl-n8,
  .mx-xl-n8 {
    margin-right: -3.75rem !important;
  }
  .mb-xl-n8,
  .my-xl-n8 {
    margin-bottom: -3.75rem !important;
  }
  .ml-xl-n8,
  .mx-xl-n8 {
    margin-left: -3.75rem !important;
  }
  .m-xl-n9 {
    margin: -4.96rem !important;
  }
  .mt-xl-n9,
  .my-xl-n9 {
    margin-top: -4.96rem !important;
  }
  .mr-xl-n9,
  .mx-xl-n9 {
    margin-right: -4.96rem !important;
  }
  .mb-xl-n9,
  .my-xl-n9 {
    margin-bottom: -4.96rem !important;
  }
  .ml-xl-n9,
  .mx-xl-n9 {
    margin-left: -4.96rem !important;
  }
  .m-xl-n10 {
    margin: -6.25rem !important;
  }
  .mt-xl-n10,
  .my-xl-n10 {
    margin-top: -6.25rem !important;
  }
  .mr-xl-n10,
  .mx-xl-n10 {
    margin-right: -6.25rem !important;
  }
  .mb-xl-n10,
  .my-xl-n10 {
    margin-bottom: -6.25rem !important;
  }
  .ml-xl-n10,
  .mx-xl-n10 {
    margin-left: -6.25rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #ff891e !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #d16300 !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important;
}

.text-success {
  color: #60a662 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #437744 !important;
}

.text-info {
  color: #1ab3e8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #117fa5 !important;
}

.text-warning {
  color: #fec701 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #b28b01 !important;
}

.text-danger {
  color: #e25a5a !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #cc2424 !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #222 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: black !important;
}

.text-success-light {
  color: #1cd066 !important;
}

a.text-success-light:hover, a.text-success-light:focus {
  color: #138d45 !important;
}

.text-black {
  color: #000 !important;
}

a.text-black:hover, a.text-black:focus {
  color: black !important;
}

.text-dark-light {
  color: #262a2e !important;
}

a.text-dark-light:hover, a.text-dark-light:focus {
  color: #030404 !important;
}

.text-stone {
  color: #232629 !important;
}

a.text-stone:hover, a.text-stone:focus {
  color: black !important;
}

.text-gray-color {
  color: #969696 !important;
}

a.text-gray-color:hover, a.text-gray-color:focus {
  color: #707070 !important;
}

.text-gray-dark {
  color: #565b61 !important;
}

a.text-gray-dark:hover, a.text-gray-dark:focus {
  color: #323538 !important;
}

.text-smoke {
  color: #f5f5f5 !important;
}

a.text-smoke:hover, a.text-smoke:focus {
  color: #cfcfcf !important;
}

.text-off-white {
  color: #dedede !important;
}

a.text-off-white:hover, a.text-off-white:focus {
  color: #b8b8b8 !important;
}

.text-light-gray {
  color: #eee !important;
}

a.text-light-gray:hover, a.text-light-gray:focus {
  color: #c8c8c8 !important;
}

.text-facebook {
  color: #6684c4 !important;
}

a.text-facebook:hover, a.text-facebook:focus {
  color: #3e5da0 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #dee2e6;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}

/* Custom Text */
p {
  font-size: 0.875rem;
  color: #969696;
  font-family: "Montserrat", sans-serif;
  line-height: 21px;
  font-weight: 400;
}

/* Link */
a {
  transition: all .3s;
  display: inline-block;
}

a:hover {
  text-decoration: none;
}

/* Text Decoration  */
.text-underline {
  text-decoration: underline !important;
}

.text-underline:hover {
  text-decoration: underline !important;
}

.text-hover-underline {
  text-decoration: none !important;
}

.text-hover-underline:hover {
  text-decoration: underline !important;
}

/* selection Color */
::-moz-selection {
  color: #fff;
  background-color: #ff891e;
}
::selection {
  color: #fff;
  background-color: #ff891e;
}

/* Font Weight */
.font-weight-medium {
  font-weight: 500 !important;
}

.font-weight-semibold {
  font-weight: 600 !important;
}

/* Font Size */
.font-size-base {
  font-size: 0.875rem !important;
}

.font-size-15 {
  font-size: 0.945rem !important;
}

.font-size-22 {
  font-size: 1.37375rem !important;
}

/* Custom Letter spacing */
.letter-spacing-2 {
  letter-spacing: 2px;
}

.letter-spacing-3 {
  letter-spacing: 3px;
}

.letter-spacing-4 {
  letter-spacing: 4px;
}

.letter-spacing-5 {
  letter-spacing: 5px;
}

.letter-spacing-10 {
  letter-spacing: 10px;
}

.letter-spacing-15 {
  letter-spacing: 15px;
}

/* Custom Line Height */
.line-hight-16 {
  line-height: 1rem;
}

.line-hight-20 {
  line-height: 1.25rem;
}

.line-hight-21 {
  line-height: 1.3125rem;
}

.line-height-24 {
  line-height: 1.5rem !important;
}

.line-height-26 {
  line-height: 1.625rem !important;
}

.line-height-28 {
  line-height: 1.75rem !important;
}

/* Border Width Custom */
.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.border-6 {
  border-width: 6px !important;
}

.border-7 {
  border-width: 7px !important;
}

.border-15 {
  border-width: 15px !important;
}

.border-top-5 {
  border-width: 5px !important;
}

/* dashed Width Custom */
.dashed {
  border: 2px dashed;
}

.border-gray {
  border-color: #dee2e6 !important;
}

.dashed-top {
  border-top: 2px dashed;
}

.dashed-bottom {
  border-bottom: 2px dashed;
}

.dashed-left {
  border-left: 2px dashed;
}

.dashed-right {
  border-right: 2px dashed;
}

/* Custom Border-color */
.border-primary-light {
  border-color: rgba(255, 137, 30, 0.3) !important;
}

.rounded-top-0 {
  border-radius: 0 0 0.25rem 0.25rem !important;
}

/* Background Parallax */
.bg-parallax {
  background-attachment: fixed;
}

/* Background Repeat */
.bg-repeat {
  background-repeat: repeat;
}

.bg-img {
  background-size: cover !important;
  background-position: center !important;
  transition: all 0.3s linear;
  position: relative;
}

.bg-overlay:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.bg-overlay-dark:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
}

.bg-overlay-darken:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
}

.bg-overlay-warning:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 137, 30, 0.7);
}

.img-overlay {
  position: relative;
  overflow: hidden;
}

.img-overlay-dark {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.3);
}

/* Opacity With background */
.opacity-7 {
  opacity: .7;
}

.opacity-75 {
  opacity: .75;
}

.opacity-8 {
  opacity: .8;
}

.opacity-85 {
  opacity: .85;
}

.hover-bg-primary {
  transition: all .3s ease-in-out;
}

.hover-bg-primary:hover {
  background-color: #ff891e;
  border-color: #ff891e;
  color: #fff;
}

.hover-bg-secondary {
  transition: all .3s ease-in-out;
}

.hover-bg-secondary:hover {
  background-color: #6c757d;
  border-color: #6c757d;
  color: #fff;
}

.hover-bg-success {
  transition: all .3s ease-in-out;
}

.hover-bg-success:hover {
  background-color: #60a662;
  border-color: #60a662;
  color: #fff;
}

.hover-bg-danger {
  transition: all .3s ease-in-out;
}

.hover-bg-danger:hover {
  background-color: #e25a5a;
  border-color: #e25a5a;
  color: #fff;
}

.hover-bg-warning {
  transition: all .3s ease-in-out;
}

.hover-bg-warning:hover {
  background-color: #fec701;
  border-color: #fec701;
  color: #fff;
}

.hover-bg-info {
  transition: all .3s ease-in-out;
}

.hover-bg-info:hover {
  background-color: #1ab3e8;
  border-color: #1ab3e8;
  color: #fff;
}

.hover-overlay {
  transition: all .3s ease-in-out;
}

.hover-overlay:hover {
  background-color: rgba(34, 34, 34, 0.5);
}

.hover-img-overlay-dark {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
}

.hover-img-overlay-dark:hover {
  background-color: rgba(34, 34, 34, 0.3);
}

a.hover-text-primary:hover, a.hover-text-primary:focus {
  color: #ff891e !important;
}

a.hover-text-success:hover, a.hover-text-success:focus {
  color: #60a662 !important;
}

a.hover-text-danger:hover, a.hover-text-danger:focus {
  color: #e25a5a !important;
}

a.hover-text-info:hover, a.hover-text-info:focus {
  color: #1ab3e8 !important;
}

a.hover-text-warning:hover, a.hover-text-warning:focus {
  color: #fec701 !important;
}

a.hover-text-success-light:hover, a.hover-text-success-light:focus {
  color: #1cd066 !important;
}

a.hover-text-gray-color:hover, a.hover-text-gray-color:focus {
  color: #969696 !important;
}

.hover-profile:hover .icon-setting {
  opacity: 1;
}

/* Header */
.header {
  position: relative;
}

/* Top bar */
.top-bar {
  position: relative;
  background-color: #f5f5f5;
  padding: 8px 0;
}

.top-bar a {
  color: #969696;
  font-size: 13px;
  font-family: sans-serif;
  transition: all .3s;
}

.top-bar .top-content {
  border-bottom: 1px solid rgba(225, 225, 225, 0.2);
  padding-top: 1.0625rem;
  padding-bottom: 1.125rem;
}

.search-item {
  position: relative;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
  display: table;
  table-layout: fixed;
  z-index: 10000;
}

.search-item .search-box {
  position: absolute;
  top: 30px;
  right: 0;
  background-color: #fff;
  width: 350px;
  padding: 15px;
  display: none;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.175);
  z-index: 10;
}

.search-item .search-box.visible {
  display: block !important;
}

.icon-toggle {
  display: table-cell;
  transition: all 0.3s ease 0s;
  vertical-align: middle;
}

.icon-toggle.active > i {
  opacity: 0;
  transform: translateY(-20px) scale(0.7);
}

.icon-toggle.active::after {
  opacity: 1;
  transform: translateY(0px) scale(1);
}

.icon-toggle > i {
  display: inline-block;
  font-style: normal;
  opacity: 1;
  position: relative;
  transform: translateY(0px) scale(1);
}

.icon-toggle:after {
  content: "\f00d";
  display: block;
  font-family: 'FontAwesome';
  position: absolute;
  top: 0;
  opacity: 0;
  transform: translateY(20px) scale(0.7);
}

/* Navbar Custom */
.navbar {
  padding: 11px 0;
  z-index: 100;
  transition: .4s ease-in-out;
  position: relative;
}

@media (min-width: 768px) {
  .navbar {
    padding: 0;
  }
}

.navbar > .container {
  padding-left: 0;
  padding-right: 0;
  position: relative;
}

.navbar .navbar-brand {
  margin-left: 15px;
  margin-right: 0;
  padding: 16px;
}

@media (min-width: 768px) {
  .navbar .navbar-brand {
    padding: 0;
  }
}

.navbar .navbar-brand img {
  width: 75%;
}

@media (min-width: 768px) {
  .navbar .navbar-brand img {
    width: 80%;
  }
}

@media (min-width: 992px) {
  .navbar .navbar-brand img {
    width: 100%;
  }
}

.navbar .navbar-toggler {
  margin-right: 20px;
  background-color: #fff;
  border-color: #ddd;
  padding: 0.4375rem .75rem;
  color: #fff;
  outline: 0;
}

.navbar .navbar-toggler .icon-bar {
  display: block;
  width: 22px;
  height: 2px;
  border-radius: 1px;
  background-color: #888;
  margin: 4px 0;
}

.navbar .navbar-collapse {
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  z-index: 10;
  background-color: #fff;
  max-height: 400px;
  margin-top: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.175);
}

@media (min-width: 768px) {
  .navbar .navbar-collapse {
    position: static;
    width: auto;
    margin-top: 0;
    background-color: transparent;
    box-shadow: none;
  }
}

.navbar .navbar-collapse.show {
  overflow-y: auto;
}

@media (min-width: 768px) {
  .navbar .navbar-collapse.show {
    overflow-y: hidden;
  }
}

.dropdown-menu {
  border-radius: 0;
}

.navbar-nav {
  margin-top: 15px;
  margin-bottom: 15px;
  padding-left: 0;
  padding-right: 0;
}

@media (min-width: 768px) {
  .navbar-nav {
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 15px;
    padding-right: 15px;
  }
}

.navbar-nav .dropdown > a {
  font-size: .875rem;
}

.navbar-nav .dropdown .nav-link {
  line-height: normal;
  font-size: 13px;
  color: #000;
  font-weight: normal;
  text-transform: capitalize;
  margin-left: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.navbar-nav .dropdown .nav-link i {
  margin-right: 7px;
}

@media (min-width: 768px) {
  .navbar-nav .dropdown .nav-link {
    display: block;
    width: auto;
    margin-left: 0;
    padding: 28px 7px 10px;
    font-size: 13px;
  }
}

@media (min-width: 992px) {
  .navbar-nav .dropdown .nav-link {
    padding: 30px 15px 17px;
    font-size: 14px;
  }
}

@media (min-width: 1200px) {
  .navbar-nav .dropdown .nav-link {
    padding: 30px 15px 17px;
  }
}

.navbar-nav .dropdown .dropdown-menu {
  border: 0;
  padding-top: 0;
  padding-bottom: 0;
  z-index: 200;
}

@media (min-width: 768px) {
  .navbar-nav .dropdown .dropdown-menu {
    border: 1px solid rgba(0, 0, 0, 0.15);
    margin-top: 0;
    width: 210px;
    padding: 5px;
    padding-top: 15px;
    padding-bottom: 21px;
    padding-left: 15px;
    margin-top: -1px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.175);
  }
}

@media (min-width: 992px) {
  .navbar-nav .dropdown .dropdown-menu {
    width: 230px;
  }
}

.navbar-nav .dropdown .dropdown-menu li {
  position: relative;
}

.navbar-nav .dropdown .dropdown-menu ul li a {
  display: inline-block;
  color: #969696;
  padding-top: 6px;
  padding-bottom: 6px;
  font-size: .8125rem;
  text-transform: uppercase;
  font-weight: 400;
  transition: all, .3s ease-in-out;
}

@media (min-width: 768px) {
  .navbar-nav .dropdown .dropdown-menu ul li a {
    padding: 10px 12px;
    display: inline-block;
    text-transform: capitalize;
    font-size: .75rem;
  }
}

@media (min-width: 992px) {
  .navbar-nav .dropdown .dropdown-menu ul li a {
    font-size: .875rem;
  }
}

.navbar-nav .dropdown .dropdown-menu .dropdown-item {
  color: #969696;
  padding-top: 6px;
  padding-bottom: 6px;
  font-size: .8125rem;
  text-transform: uppercase;
  font-weight: 400;
  padding-left: 50px;
  transition: all, .3s ease-in-out;
}

@media (min-width: 768px) {
  .navbar-nav .dropdown .dropdown-menu .dropdown-item {
    padding: 10px 12px;
    display: inline-block;
    text-transform: capitalize;
    font-size: .75rem;
  }
}

@media (min-width: 992px) {
  .navbar-nav .dropdown .dropdown-menu .dropdown-item {
    font-size: .875rem;
  }
}

.navbar-nav .dropdown .dropdown-menu .sub-menu {
  list-style: none;
  padding-left: 20px;
  margin-left: 50px;
  position: relative;
}

@media (min-width: 768px) {
  .navbar-nav .dropdown .dropdown-menu .sub-menu {
    position: absolute;
    left: 100%;
    top: 0;
    background-color: #fff;
    width: 185px;
    visibility: hidden;
    opacity: 0;
    margin-left: 3px;
    padding: 5px;
    border-radius: 4px;
    border-left: 4px solid;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.175);
  }
}

@media (min-width: 992px) {
  .navbar-nav .dropdown .dropdown-menu .sub-menu {
    width: 190px;
  }
}

@media (min-width: 1200px) {
  .navbar-nav .dropdown .dropdown-menu .sub-menu {
    width: 210px;
  }
}

.navbar-nav .dropdown .dropdown-menu .sub-menu:before {
  position: absolute;
  left: 0;
  height: 90%;
  top: 50%;
  transform: translateY(-50%);
  width: 3px;
  background-color: #dee2e6;
  content: '';
}

@media (min-width: 768px) {
  .navbar-nav .dropdown .dropdown-menu .sub-menu:before {
    display: none;
  }
}

@media (min-width: 768px) {
  .navbar-nav .dropdown .dropdown-menu .sub-menu:after {
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-right: 7px solid #000;
    top: 20px;
    margin-top: -7px;
    content: "";
    display: inline-block;
    left: -10px;
    position: absolute;
  }
}

.navbar-nav .dropdown .dropdown-menu li:hover .sub-menu {
  visibility: visible;
  opacity: 1;
}

.navbar-nav .dropdown .dropdown-menu i {
  display: none;
}

@media (min-width: 768px) {
  .navbar-nav .dropdown .dropdown-menu i {
    display: inline-block;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.navbar-nav .dropdown .dropdown-menu.show .nav-link.dropdown-toggle:after {
  content: "\f106";
}

@media (min-width: 768px) {
  .navbar-nav .dropdown .dropdown-menu.show .nav-link.dropdown-toggle:after {
    content: '';
  }
}

.navbar-nav .dropdown .dropdown-toggle:after {
  display: inline-block;
  width: auto;
  height: auto;
  font-family: fontawesome;
  margin-left: 5px;
  vertical-align: middle;
  content: "\f107";
  border: 0;
  color: inherit;
  position: absolute;
  top: 20px;
  right: 15px;
  transform: translateY(-50%);
  font-size: 1.1rem;
}

@media (min-width: 768px) {
  .navbar-nav .dropdown .dropdown-toggle:after {
    content: '';
  }
}

.navbar-nav .dropdown.show .dropdown-toggle:after {
  content: "\f106";
}

@media (min-width: 768px) {
  .navbar-nav .dropdown.show .dropdown-toggle:after {
    content: '';
  }
}

@media (min-width: 768px) {
  .navbar-expand-md .navbar-nav .nav-link {
    padding-top: 14px;
    padding-bottom: 14px;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md .navbar-nav .nav-link {
    padding-top: 23px;
    padding-bottom: 23px;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md .mega-dropdown {
    position: static;
  }
}

.nav-icon {
  width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  border-radius: 100%;
  display: inline-block;
  color: #fff;
  font-size: 1rem;
  margin-right: 10px;
}

@media (min-width: 768px) {
  .nav-icon {
    margin: 0 auto 10px;
    margin-bottom: 10px;
    display: block;
  }
}

.dropdown-menu.row.show {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

@media (min-width: 768px) {
  .dropdown-menu.row.show {
    width: 100%;
    padding-bottom: 25px;
    padding-left: 0;
    padding-right: 0;
  }
}

.dropdown-menu.row.show .list-unstyled {
  position: relative;
}

.dropdown-menu.row.show .list-unstyled li {
  padding-left: 35px;
  padding-top: 6px;
  padding-bottom: 6px;
  font-size: .8125rem;
}

@media (min-width: 768px) {
  .dropdown-menu.row.show .list-unstyled li {
    padding-top: 0;
    padding-bottom: 0;
    font-size: .94rem;
    padding-left: 0;
  }
}

.dropdown-menu.row.show .list-unstyled li a {
  color: #969696;
}

.dropdown-menu.row.show .list-unstyled li:first-child {
  font-weight: 400;
  padding-left: 25px;
  font-size: .875rem;
  text-transform: uppercase;
}

@media (min-width: 768px) {
  .dropdown-menu.row.show .list-unstyled li:first-child {
    margin-top: 13px;
    margin-bottom: 13px;
    padding-bottom: 12px;
    padding-left: 0;
    margin-left: 13px;
    font-size: .8125rem;
  }
}

@media (min-width: 992px) {
  .dropdown-menu.row.show .list-unstyled li:first-child {
    font-size: .875rem;
  }
}

.dropdown-menu.row.show .list-unstyled:before {
  position: absolute;
  left: 0;
  height: 60%;
  top: 50%;
  transform: translateY(-28%);
  width: 3px;
  background-color: transparent;
  content: '';
}

@media (min-width: 768px) {
  .dropdown-menu.row.show .list-unstyled:before {
    display: none;
  }
}

.nav-item.dropdown {
  background-color: transparent !important;
}

.nav-item.dropdown .nav-link {
  color: #222;
  font-weight: 400;
}

@media (min-width: 768px) {
  .nav-item.dropdown .nav-link {
    color: #fff;
  }
}

.nav-item.dropdown .nav-link.active {
  color: #ff891e !important;
}

@media (min-width: 768px) {
  .nav-item.dropdown .nav-link.active {
    color: #fff !important;
  }
}

.nav-item.dropdown .nav-link:hover {
  color: #ff891e !important;
}

@media (min-width: 768px) {
  .nav-item.dropdown .nav-link:hover {
    color: #fff !important;
  }
}

.nav-item.dropdown .dropdown-menu {
  border-radius: 5px;
}

.nav-item.dropdown .dropdown-menu li a:hover {
  background-color: transparent;
  color: #ff891e;
  border-radius: 10px;
}

.nav-item.dropdown .dropdown-menu li a.active {
  background-color: transparent;
  color: #222 !important;
  border-radius: 10px;
}

@media (min-width: 768px) {
  .nav-item.dropdown .dropdown-menu li a.active {
    color: #ff891e !important;
  }
}

.nav-item.dropdown .dropdown-menu li .dropdown-item.active {
  background-color: transparent;
  color: #ff891e;
}

.nav-item.dropdown .dropdown-menu li .dropdown-item.active:hover {
  background-color: transparent;
  color: #fff;
}

.nav-item.dropdown .dropdown-menu li .sub-menu {
  border-left-color: #ff891e;
}

.nav-item.dropdown .dropdown-menu li .sub-menu:after {
  border-right-color: #ff891e;
}

.nav-item.dropdown .dropdown-menu li:first-child {
  color: #222;
}

@media (min-width: 768px) {
  .nav-item.dropdown .dropdown-menu li a:hover {
    background-color: transparent;
    color: #ff891e !important;
  }
  .nav-item.dropdown .dropdown-menu li a.active {
    color: #ff891e;
  }
}

.nav-item.dropdown .dropdown-toggle.active:after {
  color: #ff891e;
}

/* Navbar Nav Right*/
@media (min-width: 768px) {
  .navbar-nav-right {
    padding-right: 30px;
  }
}

.navbar-nav-right .nav-item-left.dropdown {
  margin-right: 10px;
}

.navbar-nav-right .nav-item-left.dropdown .nav-link .icon {
  color: rgba(255, 255, 255, 0.4);
}

.navbar-nav-right .nav-item-left.dropdown .nav-link .badge-pill {
  position: absolute;
  top: -6px;
  right: 3px;
  border-radius: 3px;
  font-size: 11px;
  padding: 0 5px;
}

.navbar-nav-right .nav-item-left.dropdown .dropdown-left {
  top: 100%;
  min-width: 228px;
  margin: 0;
  margin-top: 12px;
  padding: 0;
}

@media (min-width: 768px) {
  .navbar-nav-right .nav-item-left.dropdown .dropdown-left {
    min-width: 320px;
  }
}

.navbar-nav-right .nav-item-left.dropdown .dropdown-left::before {
  position: absolute;
  content: "";
  background: #fff;
  width: 12px;
  height: 12px;
  border-radius: 3px 0 0 0;
  transform: rotate(45deg);
  top: -6px;
  right: 16px;
}

.navbar-nav-right .nav-item-left.dropdown .dropdown-left .list-group .list-group-item {
  padding: 0.92rem 1.25rem;
}

@media (min-width: 992px) {
  .navbar-nav-right .nav-item-left.dropdown .dropdown-left .list-group .list-group-item {
    padding: 0.78rem 1.25rem;
  }
}

.navbar-nav-right .nav-item-left.dropdown .dropdown-left .list-group .list-group-item:hover {
  background-color: #fafafa;
}

.navbar-nav-right .nav-item-left.dropdown .dropdown-left .list-group .list-group-item.first-child {
  border-radius: 0.25rem 0.25rem 0 0 !important;
}

.navbar-nav-right .nav-item-left.dropdown .dropdown-left .list-group .list-group-item.last-child {
  border-radius: 0 0 0.25rem 0.25rem !important;
}

.navbar-nav-right .nav-item-left {
  position: relative;
  right: 0;
}

.navbar-nav-right .nav-item-left .dropdown-toggle {
  position: relative;
}

.navbar-nav-right .nav-item-left .dropdown-toggle::after {
  content: "\f107";
  font-family: 'fontawesome';
  border-top: 0;
  color: rgba(255, 255, 255, 0.4);
  border: 0;
  top: 50%;
  position: absolute;
  transform: translateY(-50%);
}

.nav-components {
  -ms-flex-direction: column;
      flex-direction: column;
}

.nav-components .nav-item .nav-link {
  color: #969696;
  border-radius: .8rem;
  padding-top: 0.63rem;
  padding-bottom: 0.63rem;
}

.nav-components .nav-item .nav-link:hover {
  color: #ff891e;
}

.nav-components .nav-item .nav-link.active {
  color: #ff891e;
}

/* Navbar btn */
.navbar-btn {
  position: relative;
  margin-left: 30px;
  margin-right: 30px;
  padding-top: 15px;
}

.navbar-btn .btn {
  padding: 9px 19px;
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
  transition: all .3s;
}

.navbar-btn .btn:hover {
  opacity: .7;
}

@media (min-width: 768px) {
  .navbar-btn .btn {
    font-size: 10px;
  }
}

@media (min-width: 992px) {
  .navbar-btn .btn {
    font-size: 14px;
  }
}

@media (min-width: 768px) {
  .navbar-btn {
    padding-top: 28px;
    margin-left: 7px;
    margin-right: 0;
  }
}

@media (min-width: 992px) {
  .navbar-btn {
    padding-top: 30px;
  }
}

/* Navbar Dark Light */
.navbar-dark-light {
  background-color: #262a2e;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
}

@media (min-width: 768px) {
  .navbar-dark-light {
    box-shadow: none;
  }
}

@media (min-width: 768px) {
  .navbar-dark-light .nav-item.dropdown .nav-link {
    color: rgba(255, 255, 255, 0.4);
  }
}

/* Nav Custom */
.video-tab {
  position: relative;
  top: -50px;
  margin-bottom: 35px;
}

@media (min-width: 768px) {
  .video-tab {
    width: 70%;
    left: 50%;
    top: -129px;
    transform: translateX(-50%);
    margin-bottom: 0;
  }
}

@media (min-width: 992px) {
  .video-tab {
    width: 100%;
    left: 0;
    transform: translateY(-50%);
    position: absolute;
    top: 50%;
  }
}

.video-tab .content .tab-heading h2 {
  color: #fff;
  font-size: 19px;
}

@media (min-width: 992px) {
  .video-tab .content .tab-heading h2 span {
    display: block;
    font-size: 25px;
  }
}

@media (min-width: 768px) {
  .video-tab .content .tab-heading h2 {
    font-size: 25px;
  }
}

@media (min-width: 992px) {
  .video-tab .content .tab-heading h2 {
    font-size: 32px;
  }
}

.video-tab .content .tab-features {
  background-color: #fff;
  border-radius: 5px;
}

.video-tab .content .tab-features .nav-tabs {
  border-bottom: 0;
}

.video-tab .content .tab-features .nav-tabs .nav-item {
  width: 25%;
}

.video-tab .content .tab-features .nav-tabs .nav-item .nav-link {
  padding: 13px 0;
  border: 1px solid #dedede;
  border-top-width: 2px;
  border-top-color: transparent;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.video-tab .content .tab-features .nav-tabs .nav-item .nav-link.first-child {
  border-left-color: transparent;
}

.video-tab .content .tab-features .nav-tabs .nav-item .nav-link.last-child {
  border-right-color: transparent;
}

@media (min-width: 768px) {
  .video-tab .content .tab-features .nav-tabs .nav-item .nav-link {
    padding: 20px 0;
  }
}

.video-tab .content .tab-features .nav-tabs .nav-item .nav-link i {
  font-size: 20px;
  color: #969696;
}

@media (min-width: 768px) {
  .video-tab .content .tab-features .nav-tabs .nav-item .nav-link i {
    font-size: 25px;
  }
}

@media (min-width: 992px) {
  .video-tab .content .tab-features .nav-tabs .nav-item .nav-link i {
    font-size: 30px;
  }
}

.video-tab .content .tab-features .nav-tabs .nav-item .nav-link span {
  font-size: 12px;
  color: #969696;
}

@media (min-width: 768px) {
  .video-tab .content .tab-features .nav-tabs .nav-item .nav-link span {
    font-size: 14px;
  }
}

.video-tab .content .tab-features .nav-tabs .nav-item .nav-link.active {
  border-top-color: #ff891e;
  border-bottom-color: transparent;
}

.video-tab .content .tab-features .nav-tabs .nav-item .nav-link.active i {
  color: #ff891e;
}

.video-tab .content .tab-features .nav-tabs .nav-item .nav-link.active span {
  color: #222;
}

/* Destination Tabs */
.destination-tabs {
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-direction: column;
      flex-direction: column;
  text-align: center;
  width: -webkit-fill-available;
  margin-bottom: 30px;
}

@media (min-width: 768px) {
  .destination-tabs {
    -ms-flex-direction: row;
        flex-direction: row;
    -ms-flex-pack: start;
        justify-content: flex-start;
  }
}

@media (min-width: 768px) {
  .destination-tabs .nav-item {
    margin: 0;
  }
}

.destination-tabs .nav-item .nav-link {
  color: #222;
  text-transform: uppercase;
  border: transparent;
  display: inline-block;
  position: relative;
  background-color: transparent;
  z-index: 1;
}

.destination-tabs .nav-item .nav-link:before {
  position: absolute;
  content: '';
  width: 0;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  border-radius: inherit;
  transition: all 300ms ease-out;
  border-bottom: 3px solid #ff891e;
  opacity: 0;
}

.destination-tabs .nav-item .nav-link.active, .destination-tabs .nav-item .nav-link:hover {
  cursor: pointer;
}

.destination-tabs .nav-item .nav-link.active:before, .destination-tabs .nav-item .nav-link:hover:before {
  width: 100%;
  opacity: 1;
}

.destination-tabs .nav-item .nav-link.active:hover {
  cursor: default;
}

@media (min-width: 768px) {
  .destination-tabs .nav-item .nav-link {
    padding: 10px 15px;
  }
}

@media (min-width: 992px) {
  .destination-tabs .nav-item .nav-link {
    padding: 10px 13px;
    margin-bottom: 0;
  }
}

@media (min-width: 992px) {
  .destination-tabs .nav-item .nav-link {
    padding: 10px 15px;
  }
}

.destination-tabs .nav-item:last-child {
  padding: 10px 15px;
}

/* Blog Tabs */
.blog-tabs {
  border-bottom: transparent;
  -ms-flex-direction: column;
      flex-direction: column;
}

@media (min-width: 768px) {
  .blog-tabs {
    -ms-flex-direction: row;
        flex-direction: row;
  }
}

.blog-tabs .nav-item {
  margin-bottom: 4px;
}

@media (min-width: 768px) {
  .blog-tabs .nav-item {
    margin-bottom: 0;
    margin-right: 4px;
  }
  .blog-tabs .nav-item:last-child {
    margin-right: 0;
  }
}

.blog-tabs .nav-item .nav-link {
  color: #222;
  border-color: #eee;
  border-radius: 4px;
}

@media (min-width: 768px) {
  .blog-tabs .nav-item .nav-link {
    border-radius: 0;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }
}

.blog-tabs .nav-item .nav-link.active, .blog-tabs .nav-item .nav-link:hover {
  border-color: #eee #eee #f5f5f5;
  background-color: #f5f5f5;
}

.blog-tabs .nav-item .nav-link.active {
  cursor: default;
}

/* Buttons Customize */
.btn-primary {
  color: #fff;
}

.btn-primary.btn-sale:hover {
  background-color: transparent !important;
  border-color: #fff;
}

.btn-outline-primary:hover {
  color: #fff;
}

.btn-outline-primary.btn-sale {
  border-color: #fff;
  color: #fff;
}

.btn-outline-primary.btn-sale:hover {
  border-color: #ff891e;
}

.btn-append {
  background-color: #565b61;
}

.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
}

.btn-hover.btn-outline-secondary,
.btn-xs.btn-outline-secondary,
.btn-sm.btn-outline-secondary,
.btn-group-sm > .btn-outline-secondary.btn {
  border-color: #ddd;
  color: #969696;
}

.btn-hover.btn-outline-secondary:hover,
.btn-xs.btn-outline-secondary:hover,
.btn-sm.btn-outline-secondary:hover,
.btn-group-sm > .btn-outline-secondary.btn:hover {
  border-color: #ff891e;
  background-color: #ff891e;
  color: #fff;
}

.btn-hover.btn-outline-secondary.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-hover.btn-outline-secondary .btn-outline-secondary:not(:disabled):not(.disabled).active, .btn-hover.btn-outline-secondary .show > .btn-outline-secondary.dropdown-toggle,
.btn-xs.btn-outline-secondary.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-xs.btn-outline-secondary .btn-outline-secondary:not(:disabled):not(.disabled).active,
.btn-xs.btn-outline-secondary .show > .btn-outline-secondary.dropdown-toggle,
.btn-sm.btn-outline-secondary.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-group-sm > .btn-outline-secondary.btn-outline-secondary.btn:not(:disabled):not(.disabled):active,
.btn-sm.btn-outline-secondary .btn-outline-secondary:not(:disabled):not(.disabled).active,
.btn-group-sm > .btn-outline-secondary.btn .btn-outline-secondary:not(:disabled):not(.disabled).active,
.btn-sm.btn-outline-secondary .show > .btn-outline-secondary.dropdown-toggle,
.btn-group-sm > .btn-outline-secondary.btn .show > .btn-outline-secondary.dropdown-toggle {
  border-color: #ff891e;
  background-color: #ff891e;
}

.btn-xs {
  font-size: 0.8125rem;
  padding: 0.56rem 0.6rem;
  line-height: 1rem;
}

.btn-white {
  background-color: #fff;
  color: #ff891e !important;
}

.btn-outline-white {
  border-color: #fff;
  color: #969696;
}

.btn-outline-white:hover {
  border-color: #ff891e;
  background-color: #ff891e;
  color: #fff;
}

.btn-booking {
  font-size: 15px;
}

.btn-booking.btn-white {
  border-color: #fff;
  background-color: #fff;
  color: #ff891e;
}

.btn-booking.btn-white:hover {
  color: #ff891e;
}

.btn-booking.btn-outline-white {
  background-color: transparent;
  color: #fff;
}

.btn-booking.btn-outline-white:hover {
  background-color: #fff;
  border-color: #fff;
  color: #ff891e;
}

.btn-payment:hover {
  background-color: #ff891e !important;
  border-color: #ff891e !important;
  color: #fff !important;
}

/* Buttons Of Fillter */
.button-group {
  text-align: center;
  margin-bottom: 30px;
}

.button-group .button {
  padding: 0 1.56rem;
  height: 39px;
  font-size: .875rem;
  color: #222;
  text-transform: uppercase;
  display: inline-block;
  background-color: transparent;
  border: 1px solid #ddd;
  color: #969696;
  border-radius: 0.25rem;
  margin: 2px;
  cursor: pointer;
  transition: all 0.3s;
}

.button-group .button:hover {
  background-color: #ff891e;
  border-color: #ff891e;
  color: #fff;
}

.button-group .button:focus {
  outline: 0;
}

.button-group .button.is-checked {
  background-color: #ff891e;
  border-color: #ff891e;
  color: #fff;
  transition: all 0.3s;
}

/* Revolution Slider */
#rev_slider_12_1 .metis.tparrows {
  background: rgba(255, 255, 255, 0.15);
  padding: 0px;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  width: 40px;
  height: 40px;
  box-sizing: border-box;
}

@media (min-width: 768px) {
  #rev_slider_12_1 .metis.tparrows {
    padding: 10px;
    width: 60px;
    height: 60px;
  }
}

@media (min-width: 992px) {
  #rev_slider_12_1 .metis.tparrows {
    padding: 27px;
    width: 85px;
    height: 85px;
  }
}

@media (min-width: 1200px) {
  #rev_slider_12_1 .metis.tparrows {
    padding: 25px;
  }
}

#rev_slider_12_1 .rev-btn {
  background-color: #ff891e !important;
}

#rev_slider_12_1 .metis.tparrows:hover {
  background: rgba(255, 255, 255, 0.2);
}

#rev_slider_12_1 .metis.tparrows:before {
  color: white;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
}

#rev_slider_12_1 .metis.tparrows:hover:before {
  transform: scale(1.5);
}

.hesperiden.tp-bullets:before {
  content: " ";
  position: absolute;
  width: 100%;
  height: 100%;
  background: transparent;
  padding: 10px;
  margin-left: -10px;
  margin-top: -10px;
  box-sizing: content-box;
  border-radius: 8px;
}

.hesperiden .tp-bullet {
  width: 12px;
  height: 12px;
  position: absolute;
  background: rgba(255, 255, 255, 0.01);
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.01) 0%, rgba(255, 255, 255, 0.01) 100%);
  border: 2px solid white;
  border-radius: 50%;
  cursor: pointer;
  box-sizing: content-box;
}

.hesperiden .tp-bullet:hover,
.hesperiden .tp-bullet.selected {
  background: rgba(225, 225, 225, 0.7);
}

/* Home City */
#rev_slider_18_1 .metis.tparrows {
  background: rgba(255, 255, 255, 0.15);
  padding: 0px;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  width: 40px;
  height: 40px;
  box-sizing: border-box;
}

@media (min-width: 768px) {
  #rev_slider_18_1 .metis.tparrows {
    padding: 10px;
    width: 60px;
    height: 60px;
  }
}

@media (min-width: 992px) {
  #rev_slider_18_1 .metis.tparrows {
    padding: 27px;
    width: 85px;
    height: 85px;
  }
}

@media (min-width: 1200px) {
  #rev_slider_18_1 .metis.tparrows {
    padding: 25px;
  }
}

#rev_slider_18_1 .rev-btn:hover {
  background-color: #ff891e !important;
  border-color: #ff891e !important;
}

#rev_slider_18_1 .metis.tparrows:hover {
  background: rgba(255, 255, 255, 0.2);
}

#rev_slider_18_1 .metis.tparrows:before {
  color: white;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
}

#rev_slider_18_1 .metis.tparrows:hover:before {
  transform: scale(1.5);
}

/* Revolution Slider Navigation */
#rev_slider_wrapper1 #rev_slider_container .slide-layer-4 {
  text-transform: uppercase;
  background-color: #ff891e;
  border: 0;
}

#rev_slider_wrapper1 #rev_slider_container .slide-layer-4 a {
  color: #fff;
}

#rev_slider_wrapper1 #rev_slider_container .slide-layer-4:hover {
  background: #ff891e;
  cursor: pointer;
}

#rev_slider_wrapper2 #rev_slider_container .slide-layer-1 {
  font-weight: 700;
  line-height: 55px;
  color: #fff;
  text-transform: uppercase;
  z-index: 10;
}

#rev_slider_wrapper2 #rev_slider_container .slide-layer-2 {
  font-size: 20px;
  font-weight: 400;
  line-height: 21px;
  color: #fff;
  z-index: 10;
}

#rev_slider_wrapper2 #rev_slider_container .slide-layer-3 {
  color: #fff;
  text-transform: uppercase;
  border: 2px solid #fff;
}

#rev_slider_wrapper2 #rev_slider_container .slide-layer-3:hover {
  background: #ff891e;
  border: 2px solid #ff891e;
  cursor: pointer;
}

#rev_slider_wrapper2 #rev_slider_container .slide-layer-3:focus {
  text-decoration: none;
}

#rev_slider_wrapper2 #rev_slider_container .slide-layer-4 {
  color: #fff;
  text-transform: uppercase;
  border: 2px solid #fff;
}

#rev_slider_wrapper2 #rev_slider_container .slide-layer-4:hover {
  background: #ff891e;
  border: 2px solid #ff891e;
  cursor: pointer;
}

#rev_slider_wrapper2 #rev_slider_container .slide-layer-4:focus {
  text-decoration: none;
}

#rev_slider_wrapper3 #rev_slider_container .slide-layer-2 {
  text-transform: uppercase;
}

#rev_slider_wrapper3 #rev_slider_container .slide-layer-3 .container-container-custom {
  width: 530px !important;
  margin-left: -15px;
  margin-right: -15px;
}

#rev_slider_wrapper3 #rev_slider_container .tp-rightarrow {
  display: none;
}

#rev_slider_wrapper3 #rev_slider_container .tp-leftarrow {
  display: none;
}

#rev_slider_wrapper3 #rev_slider_container .tp-bullet {
  display: none;
}

/* Banner Container */
.bannercontainer .rev_slider_wrapper .fullscreenbanner {
  position: relative;
  font-family: "Montserrat", sans-serif;
  /*Revolution Slider  Arrows */
  /*Revolution Slider  Bullets */
}

.bannercontainer .rev_slider_wrapper .fullscreenbanner .tp-dottedoverlay.twoxtwo {
  background: rgba(0, 0, 0, 0.15);
  background-image: none !important;
  z-index: 1;
}

.bannercontainer .rev_slider_wrapper .fullscreenbanner:hover .tp-rightarrow.tparrows,
.bannercontainer .rev_slider_wrapper .fullscreenbanner:hover .tp-leftarrow.tparrows {
  display: block;
}

.bannercontainer .rev_slider_wrapper .fullscreenbanner .tp-rightarrow.tparrows {
  left: 101.5% !important;
  display: none;
  border-radius: 0;
  background-color: rgba(255, 255, 255, 0.1);
}

@media (min-width: 768px) {
  .bannercontainer .rev_slider_wrapper .fullscreenbanner .tp-rightarrow.tparrows {
    width: 60px;
    height: 60px;
  }
}

@media (min-width: 992px) {
  .bannercontainer .rev_slider_wrapper .fullscreenbanner .tp-rightarrow.tparrows {
    width: 85px;
    height: 85px;
  }
}

.bannercontainer .rev_slider_wrapper .fullscreenbanner .tp-rightarrow.tparrows:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.bannercontainer .rev_slider_wrapper .fullscreenbanner .tp-rightarrow.tparrows:before {
  content: '\e825';
}

@media (min-width: 768px) {
  .bannercontainer .rev_slider_wrapper .fullscreenbanner .tp-rightarrow.tparrows:before {
    line-height: 60px;
  }
}

@media (min-width: 992px) {
  .bannercontainer .rev_slider_wrapper .fullscreenbanner .tp-rightarrow.tparrows:before {
    line-height: 85px;
  }
}

.bannercontainer .rev_slider_wrapper .fullscreenbanner .tp-leftarrow.tparrows {
  display: none;
  left: -20px !important;
  border-radius: 0;
  background-color: rgba(255, 255, 255, 0.1);
}

@media (min-width: 768px) {
  .bannercontainer .rev_slider_wrapper .fullscreenbanner .tp-leftarrow.tparrows {
    width: 60px;
    height: 60px;
  }
}

@media (min-width: 992px) {
  .bannercontainer .rev_slider_wrapper .fullscreenbanner .tp-leftarrow.tparrows {
    width: 85px;
    height: 85px;
  }
}

.bannercontainer .rev_slider_wrapper .fullscreenbanner .tp-leftarrow.tparrows:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.bannercontainer .rev_slider_wrapper .fullscreenbanner .tp-leftarrow.tparrows:before {
  content: '\e824';
}

@media (min-width: 768px) {
  .bannercontainer .rev_slider_wrapper .fullscreenbanner .tp-leftarrow.tparrows:before {
    line-height: 60px;
  }
}

@media (min-width: 992px) {
  .bannercontainer .rev_slider_wrapper .fullscreenbanner .tp-leftarrow.tparrows:before {
    line-height: 85px;
  }
}

.bannercontainer .rev_slider_wrapper .fullscreenbanner .tp-bullets {
  top: 97% !important;
  opacity: 1 !important;
  height: auto !important;
}

@media (min-width: 992px) {
  .bannercontainer .rev_slider_wrapper .fullscreenbanner .tp-bullets {
    bottom: 96px !important;
  }
}

.bannercontainer .rev_slider_wrapper .fullscreenbanner .tp-bullets .tp-bullet {
  background: rgba(255, 255, 255, 0.5) !important;
  border-radius: 50%;
  box-shadow: none !important;
  width: 6px !important;
  height: 6px !important;
  border: 2px solid rgba(255, 255, 255, 0) !important;
  display: inline-block;
  margin-bottom: 0px !important;
  transition: background-color 0.2s, border-color 0.2s;
}

@media (prefers-reduced-motion: reduce) {
  .bannercontainer .rev_slider_wrapper .fullscreenbanner .tp-bullets .tp-bullet {
    transition: none;
  }
}

.bannercontainer .rev_slider_wrapper .fullscreenbanner .tp-bullets .tp-bullet.selected {
  box-shadow: none !important;
  background: rgba(255, 255, 255, 0) !important;
  width: 14px !important;
  height: 14px !important;
  border: 2px solid white !important;
  margin-right: 0px !important;
  margin-left: -4px !important;
  margin-bottom: -3px !important;
  margin-top: -3px;
}

.bannercontainer .rev_slider_wrapper .fullscreenbanner .tp-bullets .tp-bullet.hover {
  box-shadow: none !important;
  background: rgba(255, 255, 255, 0) !important;
  width: 14px !important;
  height: 14px !important;
  border: 2px solid white !important;
  margin-bottom: -3px !important;
}

/* Revolution Slider  boxed version */
.boxed .forcefullwidth_wrapper_tp_banner {
  overflow: hidden !important;
}

.default {
  background-image: url("../img/patterns/pattern1.png");
  background-repeat: repeat;
  background-attachment: fixed;
  background-position: center;
}

.pattern-01 {
  background-image: url("../img/patterns/pattern2.png");
  background-repeat: repeat;
  background-attachment: fixed;
  background-position: center;
}

.pattern-02 {
  background-image: url("../img/patterns/pattern3.png");
  background-repeat: repeat;
  background-attachment: fixed;
  background-position: center;
}

.pattern-03 {
  background-image: url("../img/patterns/pattern4.png");
  background-repeat: repeat;
  background-attachment: fixed;
  background-position: center;
}

.pattern-04 {
  background-image: url("../img/patterns/pattern5.png");
  background-repeat: repeat;
  background-attachment: fixed;
  background-position: center;
}

/* Boxed Layout */
body.boxed {
  margin: 0 auto;
  position: relative;
}

@media (min-width: 768px) {
  body.boxed {
    max-width: 700px;
  }
  body.boxed .navbar-sticky {
    max-width: 700px;
  }
}

@media (min-width: 992px) {
  body.boxed {
    max-width: 950px;
  }
  body.boxed .navbar-sticky {
    max-width: 950px;
  }
}

@media (min-width: 1200px) {
  body.boxed {
    max-width: 1250px;
  }
  body.boxed .navbar-sticky {
    max-width: 1250px;
  }
}

body.boxed .main-wrapper {
  background: #fff;
}

/* Custom Gallery */
img.fancybox-image {
  padding: 15px;
  background: #fff;
  border-radius: 4px;
}

/* Media */
.media {
  position: relative;
  transition: all .3s;
}

/* Media Image Overlay */
.media-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

/* Media Object */
.media-object {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
}

/* Media Hoverable*/
.media-hoverable {
  overflow: hidden;
  border-radius: 0.25rem;
}

@media (min-width: 992px) {
  .media-hoverable:hover .media-img {
    transform: scale(1.2) rotate(0);
  }
  .media-hoverable:hover .media-object h3 {
    color: #ff891e;
  }
  .media-hoverable:hover .media-img-overlay {
    visibility: visible;
    opacity: 1;
  }
  .media-hoverable:hover .media-img-overlay .overlay-content {
    top: 50%;
  }
}

.media-hoverable .media-content {
  position: relative;
  width: 100%;
}

.media-hoverable .media-img {
  transition: transform .8s ease-in-out;
  width: 100%;
  transform: scale(1.2) rotate(0);
}

@media (min-width: 992px) {
  .media-hoverable .media-img {
    transform: scale(1.01) rotate(0);
  }
}

.media-hoverable .media-object {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  transition: all .3s ease-in-out;
}

.media-hoverable .media-object h3 {
  color: #ff891e;
  font-weight: 700;
  transition: all .3s ease-in-out;
}

@media (min-width: 992px) {
  .media-hoverable .media-object h3 {
    color: #fff;
  }
}

.media-hoverable .media-img-overlay {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  transition: all .3s ease-in-out;
}

@media (min-width: 992px) {
  .media-hoverable .media-img-overlay {
    visibility: hidden;
    opacity: 0;
  }
}

.media-hoverable .media-img-overlay .overlay-content {
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: all .3s ease-in-out;
}

@media (min-width: 992px) {
  .media-hoverable .media-img-overlay .overlay-content {
    top: 57%;
  }
}

.media-hoverable .media-img-overlay .overlay-content h3 {
  text-transform: uppercase;
  color: #fff;
  margin-bottom: 55px;
}

/* Media Hover Overlay*/
.media-hover-overlay {
  overflow: hidden;
  border-radius: 0.25rem;
}

@media (min-width: 992px) {
  .media-hover-overlay:hover .media-img-overlay {
    visibility: visible;
    opacity: 1;
  }
  .media-hover-overlay:hover .media-img-overlay .overlay-content {
    bottom: 0;
  }
  .media-hover-overlay:hover .media-object .content {
    top: 35%;
  }
}

@media (min-width: 992px) and (min-width: 768px) {
  .media-hover-overlay:hover .media-object .content {
    top: 40%;
  }
}

@media (min-width: 992px) and (min-width: 992px) {
  .media-hover-overlay:hover .media-object .content {
    top: 25%;
  }
}

@media (min-width: 992px) and (min-width: 1200px) {
  .media-hover-overlay:hover .media-object .content {
    top: 45%;
  }
}

.media-hover-overlay .media-content {
  position: relative;
  width: 100%;
}

.media-hover-overlay .media-img {
  transition: transform .8s ease-in-out;
  width: 100%;
  transform: scale(1.01) rotate(0);
}

.media-hover-overlay .media-object {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center;
  transition: all .3s ease-in-out;
}

.media-hover-overlay .media-object .content {
  position: absolute;
  left: 30px;
  top: 35%;
  transition: all .3s ease-in-out;
}

.media-hover-overlay .media-object .content h3, .media-hover-overlay .media-object .content h6 {
  color: #fff;
  font-weight: 600;
  transition: all .3s ease-in-out;
}

.media-hover-overlay .media-object .content h6 {
  font-weight: 400;
}

@media (min-width: 768px) {
  .media-hover-overlay .media-object .content {
    top: 45%;
  }
}

@media (min-width: 992px) {
  .media-hover-overlay .media-object .content {
    top: 60%;
  }
}

@media (min-width: 1200px) {
  .media-hover-overlay .media-object .content {
    top: 68%;
  }
}

.media-hover-overlay .media-img-overlay {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
  transition: all .3s ease-in-out;
}

@media (min-width: 992px) {
  .media-hover-overlay .media-img-overlay {
    visibility: hidden;
    opacity: 0;
  }
}

.media-hover-overlay .media-img-overlay .overlay-content {
  position: absolute;
  bottom: 0;
  transform: translateY(-50%);
  left: 30px;
  z-index: 10;
  transition: all .3s ease-in-out;
}

@media (min-width: 992px) {
  .media-hover-overlay .media-img-overlay .overlay-content {
    bottom: -65px;
  }
}

/* Media Overlay*/
.media-overlay {
  overflow: hidden;
  border-radius: 0.25rem;
}

.media-overlay .media-content {
  position: relative;
  width: 100%;
}

.media-overlay .media-img {
  transition: transform .8s ease-in-out;
  width: 100%;
  transform: scale(1.01) rotate(0);
}

.media-overlay .media-object {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  transition: all .3s ease-in-out;
}

.media-overlay .media-object h3 {
  color: #fff;
  font-weight: 700;
  transition: all .3s ease-in-out;
}

.media-overlay .media-img-overlay {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  visibility: hidden;
  opacity: 0;
  transition: all .3s ease-in-out;
}

.media-overlay .media-img-overlay .overlay-content {
  text-align: center;
  position: absolute;
  top: 57%;
  transform: translateY(-50%);
  transition: all .3s ease-in-out;
}

.media-overlay .media-img-overlay .overlay-content h3 {
  text-transform: uppercase;
  color: #fff;
  margin-bottom: 55px;
}

.media-overlay:hover .media-img {
  transform: scale(1.2) rotate(0);
}

.media-overlay:hover .media-img-overlay {
  visibility: visible;
  opacity: 1;
}

.media-overlay:hover .media-object h3 {
  color: #ff891e;
}

.media-overlay:hover .overlay-content {
  top: 50%;
}

/* Media Top*/
.media-top {
  margin-top: 0;
}

@media (min-width: 768px) {
  .media-top {
    margin-top: -50px;
  }
}

@media (min-width: 992px) {
  .media-top {
    margin-top: 0;
  }
}

/* Media Hover*/
.media-hover .media-img {
  transition: all .3s;
  width: 100%;
}

.media-hover:hover .media-img {
  opacity: .8;
}

/* Media Large*/
.media-large {
  border-radius: 5px;
}

.media-large img {
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 5px 5px 0 0;
  transition: all .4s;
}

@media (min-width: 768px) {
  .media-large img {
    width: auto;
    border-radius: 5px 0 0 5px;
  }
}

@media (min-width: 992px) {
  .media-large img {
    width: 100%;
  }
}

.media-large img:hover {
  opacity: .8;
}

.media-large .media-body:first-child {
  padding: 20px;
}

@media (min-width: 992px) {
  .media-large .media-body:first-child {
    padding: 40px;
  }
}

@media (min-width: 1200px) {
  .media-large .media-body:first-child {
    padding: 50px;
  }
}

.media-large .media-body .list-unstyled li {
  font-size: 14px;
  color: #fff;
}

.media-large .media-body .list-unstyled li:first-child {
  text-transform: uppercase;
}

.media-large .media-body .list-unstyled li:first-child i {
  color: #ff891e;
}

.media-large .media-body .list-unstyled li a {
  color: #969696;
  font-size: 14px;
  transition: all .4s;
}

@media (min-width: 768px) {
  .media-large .media-body .list-unstyled li a {
    font-size: 13px;
  }
}

@media (min-width: 992px) {
  .media-large .media-body .list-unstyled li a {
    font-size: 14px;
  }
}

.media-large .media-body .list-unstyled li a i {
  font-size: 8px;
  transform: rotate(45deg);
}

.media-large .media-body .list-unstyled li a:hover {
  color: #ff891e;
}

/* Media Wrapper*/
.media-img-wrapper .media-img {
  width: 100%;
  overflow: hidden;
}

@media (min-width: 768px) {
  .media-img-wrapper .media-img {
    width: auto;
  }
}

@media (min-width: 992px) {
  .media-img-wrapper .media-img {
    width: 172px;
  }
}

@media (min-width: 1200px) {
  .media-img-wrapper .media-img {
    width: 215px;
  }
}

.media-img-wrapper .media-img img {
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  transition: all .4s;
}

@media (min-width: 992px) {
  .media-img-wrapper .media-img img {
    width: auto;
  }
}

.media-img-wrapper .media-body {
  padding: 1.5rem 0;
}

@media (min-width: 768px) {
  .media-img-wrapper .media-body {
    padding: 1.5rem 2rem;
  }
}

@media (min-width: 992px) {
  .media-img-wrapper .media-body {
    padding: 1.5rem 1rem 1.5rem 2rem;
  }
}

@media (min-width: 1200px) {
  .media-img-wrapper .media-body {
    padding: 1.6rem;
  }
}

.media-img-wrapper .media-body h6 {
  line-height: 26px;
}

.media-img-wrapper .media-body h6 a {
  color: #222;
}

.media-img-wrapper .media-body .list-unstyled a {
  font-size: 14px;
  color: #969696;
}

.media-img-wrapper .media-body > a {
  font-size: 14px;
  color: #969696;
  text-decoration: underline;
}

.media-img-wrapper .media-body > a:hover {
  color: #ff891e;
}

/* Media Center*/
.media-center {
  text-align: center;
}

@media (min-width: 768px) {
  .media-center {
    text-align: left;
  }
}

.media-center .media-img {
  position: relative;
}

.media-center .media-img img {
  width: 100%;
}

.media-center .media-img .media-content {
  width: 105px;
  height: 105px;
  border-radius: 50%;
  border: 1px solid #e5e5e5;
  background-color: #fff;
  position: absolute;
  right: 15px;
  top: -50px;
}

.media-center .media-img .media-content span {
  font-size: 14px;
  top: 50%;
  position: relative;
  text-align: center;
  transform: translateY(-50%);
}

/* Media SM*/
.media-sm {
  margin-bottom: 25px;
  padding-bottom: 25px;
}

.media-sm img {
  border-radius: 0.25rem;
  margin-right: 11px;
}

.media-sm .media-body h6 {
  font-size: 15px;
  font-weight: 700;
  margin-bottom: 0;
}

.media-sm .media-body h6 a {
  color: #222;
}

.media-sm .media-body > a {
  color: #969696;
  font-size: 13px;
}

/* Media List & Fullwidth View */
.media-list-view {
  background-color: #fff;
  border-radius: 5px;
}

.media-list-view .media-img {
  width: 100%;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  overflow: hidden;
}

@media (min-width: 768px) {
  .media-list-view .media-img {
    width: 40%;
    border-top-right-radius: 0;
    border-bottom-left-radius: 5px;
  }
}

.media-list-view .media-img img {
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  transition: all .3s;
}

@media (min-width: 768px) {
  .media-list-view .media-img img {
    height: 247px;
  }
}

@media (min-width: 1200px) {
  .media-list-view .media-img img {
    height: auto;
  }
}

.media-list-view .media-img .media-img-overlay {
  transition: all .6s;
  transform: scale(1) rotate(0deg);
}

.media-list-view .media-img:hover .media-img-overlay {
  background-color: rgba(0, 0, 0, 0.5);
}

.media-list-view .media-body {
  padding: 1.25rem;
}

.media-list-view .media-body .media-title {
  color: #222;
  font-weight: 700;
  text-transform: uppercase;
}

.media-list-view .media-body .rating-view {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  line-height: 15px;
  margin-bottom: 10px;
}

.media-list-view .media-body .rating-view .content-view {
  font-size: 13px;
  border-right: 1px solid #dedede;
  color: #969696;
  padding-right: 5px;
}

.media-list-view .media-body .rating-view .list-inline-rating {
  color: #fec701;
}

.media-list-view .media-body .rating-view .list-inline-rating .list-inline-item {
  margin-right: 0;
}

.media-list-view .media-body .date-view {
  margin-bottom: 1.5rem;
}

@media (min-width: 768px) {
  .media-list-view .media-body .date-view {
    position: absolute;
    bottom: 0;
    margin-bottom: 0;
  }
}

.media-list-view .media-body .date-view span {
  height: 35px;
  line-height: 35px;
  border-radius: 4px;
  border: 1px solid #dedede;
  padding: 0 14px;
  color: #969696;
  text-transform: uppercase;
  font-size: 13px;
  display: inline-block;
}

.media-list-view .media-body .view-details {
  border-radius: 5px;
}

.media-list-view:hover .media-title {
  color: #ff891e;
}

.media-list-view.media-border .media-body {
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

@media (min-width: 768px) {
  .media-list-view.media-border .media-body {
    border-bottom-left-radius: 0;
    border-top-right-radius: 5px;
  }
}

@media (min-width: 768px) {
  .media-list-view.media-border .media-body .date-view {
    position: absolute;
  }
}

/* Media List Fullwidth */
@media (min-width: 768px) {
  .media-list-fullwidth {
    -ms-flex-align: center;
        align-items: center;
  }
}

@media (min-width: 768px) {
  .media-list-fullwidth .media-img {
    width: 35%;
  }
}

@media (min-width: 1200px) {
  .media-list-fullwidth .media-img img {
    height: auto !important;
  }
}

@media (min-width: 768px) {
  .media-list-fullwidth .media-img img {
    height: 300px;
  }
}

.media-list-fullwidth .media-body {
  padding: 2rem;
}

@media (min-width: 1200px) {
  .media-list-fullwidth .media-body {
    padding: 2rem !important;
  }
}

@media (min-width: 768px) {
  .media-list-fullwidth .media-body {
    padding: 1.45rem;
  }
}

@media (min-width: 768px) {
  .media-list-fullwidth .media-body .date-view {
    position: relative;
  }
}

@media (min-width: 992px) {
  .media-list-fullwidth .media-body .date-view {
    position: absolute;
    bottom: 0;
  }
}

@media (min-width: 1200px) {
  .media-list-fullwidth.media-border .media-img {
    width: 32%;
  }
}

@media (min-width: 1200px) {
  .media-list-fullwidth.media-border .media-img img {
    height: 271px !important;
  }
}

@media (min-width: 768px) {
  .media-list-fullwidth.media-border .media-img img {
    height: 255px;
  }
}

/* Media List*/
.media-list .list-group .list-group-item {
  background-color: transparent;
  border-color: transparent;
  border-bottom-color: #dedede;
  padding: 15px 0;
}

.media-list .list-group .list-group-item a {
  font-size: 13px;
  color: #969696;
}

/* Media List View Sm */
.media-list-view-sm {
  padding: 9px;
  border-radius: 4px;
}

.media-list-view-sm > .media-body {
  width: 100%;
}

.media-list-view-sm > .media-body .media-list-sm {
  border-radius: 4px;
  border: 1px solid #eee;
}

.media-list-view-sm > .media-body .media-list-sm .media-img {
  position: relative;
  overflow: hidden;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.media-list-view-sm > .media-body .media-list-sm .media-img img {
  width: 100%;
  position: relative;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.media-list-view-sm > .media-body .media-list-sm .media-img:hover .media-img-overlay {
  background-color: rgba(0, 0, 0, 0.3);
}

/* Media List Content */
.media-list-content {
  margin-bottom: 12px;
}

.media-list-content i {
  transform: rotate(45deg);
  font-size: 10px;
  color: #67686a;
  margin-right: 12px;
}

/* Media Profile List */
.media-profile-list {
  font-size: 13px;
  margin-bottom: 10px;
}

.media-profile-list strong {
  color: #343434;
}

@media (min-width: 768px) {
  .media-profile-list {
    font-size: 14px;
  }
}

@media (min-width: 992px) {
  .media-profile-list {
    margin-bottom: 12px;
  }
}

@media (min-width: 1200px) {
  .media-profile-list {
    margin-bottom: 14px;
  }
}

/** Form Control **/
.form-control {
  border-color: #e5e5e5;
}

.form-control:-ms-input-placeholder {
  color: #969696;
}

.form-control::placeholder {
  color: #969696;
}

.form-control:focus {
  box-shadow: none;
  border-color: #e5e5e5;
  color: #969696;
}

.form-control-plaintext {
  outline: 0;
}

.form-control-white {
  border-color: #fff;
  color: #fff !important;
  background-color: transparent;
}

.form-control-white:-ms-input-placeholder {
  color: #fff;
}

.form-control-white::placeholder {
  color: #fff;
}

.form-control-white:focus {
  background-color: transparent;
}

.form-transparent {
  background-color: transparent !important;
  border: 1px solid #4a4e52 !important;
  border-radius: 5px;
}

.form-search {
  background-color: transparent !important;
  border-color: rgba(155, 153, 152, 0.25) !important;
}

.form-subscribe .input-group-append {
  margin-left: -5px;
}

.input-group-append {
  margin-left: 0;
}

.input-group-prepend .btn,
.input-group-append .btn {
  z-index: 3;
}

.form-group-icon-default,
.form-group-icon {
  position: relative;
}

.form-group-icon-default input.form-control,
.form-group-icon input.form-control {
  height: 45px;
}

.form-group-icon-default i,
.form-group-icon i {
  top: 50%;
  right: 2px;
  font-size: .81rem;
  position: absolute;
  color: rgba(255, 255, 255, 0.3);
  transform: translateY(-50%);
}

.form-group-icon-default i {
  right: 13px;
  color: #969696;
}

.form-group-icon-category-2 i,
.form-group-icon-white i {
  color: #fff;
  right: 16px;
}

.form-group-icon-category-2 i,
.form-group-icon-dark i {
  color: #555;
}

.form-check-label {
  cursor: pointer;
}

.custom-control-label {
  outline: 0;
}

.custom-control-label span {
  color: #969696;
  margin-left: 8px;
  font-size: 13px;
}

.custom-checkbox .custom-control-label::after,
.custom-checkbox .custom-control-label::before {
  border-radius: 0;
}

.custom-control-label::before,
.custom-control-label::after {
  width: 22px;
  height: 22px;
  top: 0;
  border-color: #dedede;
  box-shadow: none !important;
  outline: 0 !important;
}

.settings-control-label::before,
.settings-control-label::after {
  width: 14px;
  height: 14px;
  top: 4px;
}

.radio-control-label::before,
.radio-control-label::after {
  width: 15px;
  height: 15px;
  top: 2px;
}

.custom-control-input:not(:disabled):active ~ .custom-control-label::before,
.custom-control-input:not(:disabled):active ~ .custom-control-label::after {
  border-color: #dedede !important;
  background-color: transparent !important;
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before,
.custom-control-input:focus:not(:checked) ~ .custom-control-label::after {
  border-color: #dedede !important;
}

.count-control-label {
  font-size: 13px;
  color: #969696;
}

.count-input {
  position: relative;
  width: 140px;
}

@media (min-width: 768px) {
  .count-input {
    margin: auto;
  }
}

@media (min-width: 992px) {
  .count-input {
    margin-left: 0;
    width: 120px;
  }
}

@media (min-width: 1200px) {
  .count-input {
    width: 140px;
  }
}

.count-input .incr-btn {
  color: #666;
  display: block;
  font-size: 30px;
  font-weight: 300;
  height: 48px;
  line-height: 45px;
  position: absolute;
  right: 0;
  text-align: center;
  text-decoration: none;
  top: 0;
  width: 48px;
}

@media (min-width: 992px) {
  .count-input .incr-btn {
    width: 40px;
  }
}

@media (min-width: 1200px) {
  .count-input .incr-btn {
    width: 48px;
  }
}

.count-input .incr-btn:first-child {
  left: 0;
  right: auto;
  border-right: 1px solid #eaeaea;
}

.count-input .incr-btn:last-child {
  border-left: 1px solid #eaeaea;
}

.count-input input {
  -moz-appearance: none;
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: 1px solid #eaeaea;
  border-radius: 0.25rem;
  height: 48px;
  font-weight: 700;
  color: #666;
  text-align: center;
  width: 100%;
}

.count-input input:focus {
  outline: 0;
}

input[type=number]::-webkit-inner-spin-button {
  display: none;
}

.icon-default {
  width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
}

.icon-sm {
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
}

.icon-md {
  width: 45px;
  height: 45px;
  line-height: 45px;
  font-size: 1.25rem;
  text-align: center;
}

.icon-lg {
  width: 96px;
  height: 96px;
  line-height: 96px;
  font-size: 42px;
  text-align: center;
}

.icon-xl {
  width: 120px;
  height: 120px;
  line-height: 120px;
  font-size: 3.125rem;
  text-align: center;
}

.icon-border {
  border: 1px solid #4a4e52;
}

.icon-light-border {
  border: 1px solid #e7e7e7;
}

.icon-setting {
  opacity: 0;
  transition: all 0.5s;
}

/* Icon Border-2 */
.icon-border-secondary {
  border: 1px solid #969696;
}

/* Icon Color */
.icon-gray {
  color: #969696;
}

/* Icon Play */
.icon-play {
  width: 110px;
  height: 110px;
  border-radius: 50%;
  border: 5px solid #fff;
  font-size: 60px;
  color: #fff !important;
  line-height: 100px;
  padding-left: 10px;
}

/* Icon Item */
.icon-item-default {
  width: 60px;
  height: 60px;
  line-height: 60px;
  font-size: 35px;
  text-align: center;
}

/* Icon Contact */
.icon-contact-default {
  width: 75px;
  height: 75px;
  line-height: 75px;
  font-size: 35px;
  text-align: center;
}

/* Icon Social */
.icon-social {
  background-color: rgba(255, 255, 255, 0.4);
  color: rgba(0, 0, 0, 0.4);
}

/* Icon Medium */
.icon-medium {
  font-size: 1.12875rem !important;
}

/* Menuzord Nav */
.nav-menuzord {
  position: absolute;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  transition: all .4s;
}

@media (min-width: 992px) {
  .nav-menuzord {
    position: relative;
  }
}

.nav-menuzord .menuzord {
  background-color: transparent !important;
  padding: 0;
  /* Menuzord Menu */
}

.nav-menuzord .menuzord .menuzord-brand {
  margin: 0 0 0 10px;
  padding: 27px 0;
  width: 150px;
}

@media (min-width: 768px) {
  .nav-menuzord .menuzord .menuzord-brand {
    margin: 0;
  }
}

@media (min-width: 1200px) {
  .nav-menuzord .menuzord .menuzord-brand {
    padding: 20px 0;
    width: auto;
  }
}

.nav-menuzord .menuzord .menuzord-brand img {
  width: 100%;
}

.nav-menuzord .menuzord .menuzord-logo-brand {
  background-image: url(../img/logo.png);
  background-position: 15px 20px;
  background-size: 150px;
  background-repeat: no-repeat;
  width: 230px;
  height: 80px;
}

@media (min-width: 768px) {
  .nav-menuzord .menuzord .menuzord-logo-brand {
    background-size: auto;
    background-position: 15px 15px;
  }
}

@media (min-width: 992px) {
  .nav-menuzord .menuzord .menuzord-logo-brand {
    background-position: 15px 20px;
  }
}

.nav-menuzord .menuzord .showhide {
  width: 50px;
  height: 40px;
  padding: 10px 0 0;
  background-color: #ff891e;
  border-radius: 0.25rem;
  margin-top: 25px !important;
  margin-right: 10px !important;
}

@media (min-width: 768px) {
  .nav-menuzord .menuzord .showhide {
    margin-right: 0 !important;
  }
}

.nav-menuzord .menuzord .showhide em {
  background-color: #fff;
  margin: 3px 15px 0px;
}

.nav-menuzord .menuzord .menuzord-menu {
  /* Mega Menu */
}

.nav-menuzord .menuzord .menuzord-menu .indicator {
  width: 100%;
  border-left: solid 1px transparent;
  margin-left: 0;
}

.nav-menuzord .menuzord .menuzord-menu .indicator i {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}

@media (min-width: 992px) {
  .nav-menuzord .menuzord .menuzord-menu {
    padding-right: 50px;
  }
  .nav-menuzord .menuzord .menuzord-menu .indicator {
    display: none;
  }
}

.nav-menuzord .menuzord .menuzord-menu ul.dropdown .indicator {
  display: block;
}

@media (min-width: 992px) {
  .nav-menuzord .menuzord .menuzord-menu ul.dropdown .indicator i {
    top: 3px;
    right: 0;
    transform: translateY(0);
  }
}

@media (min-width: 992px) {
  .nav-menuzord .menuzord .menuzord-menu ul.dropdown {
    border-radius: 5px;
    border-top: solid 1px #f0f0f0;
    padding: 5px 0;
    border: 1px solid rgba(0, 0, 0, 0.15);
  }
}

@media (min-width: 992px) {
  .nav-menuzord .menuzord .menuzord-menu ul.dropdown li ul.dropdown {
    border-left: 3px solid #ff891e;
    width: 100%;
  }
  .nav-menuzord .menuzord .menuzord-menu ul.dropdown li ul.dropdown::before {
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-right: 7px solid #ff891e;
    top: 20px;
    margin-top: -8px;
    content: "";
    display: inline-block;
    left: -10px;
    position: absolute;
  }
}

@media (min-width: 992px) {
  .nav-menuzord .menuzord .menuzord-menu ul.dropdown li ul.dropdown.drop-sub-menu-left {
    left: -100%;
  }
}

@media (min-width: 1200px) {
  .nav-menuzord .menuzord .menuzord-menu ul.dropdown li ul.dropdown.drop-sub-menu-left {
    left: 100%;
  }
}

.nav-menuzord .menuzord .menuzord-menu ul.dropdown li > a {
  transition: all .3s;
  border-radius: 5px;
  color: #969696;
}

.nav-menuzord .menuzord .menuzord-menu ul.dropdown li > a:hover {
  background-color: transparent !important;
}

@media (min-width: 992px) {
  .nav-menuzord .menuzord .menuzord-menu ul.dropdown li > a:hover {
    background-color: #f5f5f5 !important;
  }
}

.nav-menuzord .menuzord .menuzord-menu li.active > a:after {
  width: 30%;
}

.nav-menuzord .menuzord .menuzord-menu li:hover > a:after {
  width: 100%;
}

.nav-menuzord .menuzord .menuzord-menu li.active > a, .nav-menuzord .menuzord .menuzord-menu li:hover > a {
  color: #ff891e !important;
}

.nav-menuzord .menuzord .menuzord-menu > li > a {
  font-weight: 400;
  color: #000;
  text-transform: uppercase;
}

@media (min-width: 992px) {
  .nav-menuzord .menuzord .menuzord-menu > li > a {
    padding: 37px 0;
    margin: 0 11px;
    position: relative;
  }
  .nav-menuzord .menuzord .menuzord-menu > li > a:before {
    position: absolute;
    content: '';
    left: 0;
    right: 0;
    bottom: 30px;
    width: 100%;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.1);
    transition: all 300ms ease-out;
  }
  .nav-menuzord .menuzord .menuzord-menu > li > a:after {
    position: absolute;
    content: '';
    left: 0;
    right: 0;
    bottom: 28px;
    width: 0;
    height: 3px;
    background-color: #ff891e;
    transition: all 300ms ease-out;
    border-radius: 2px;
  }
}

@media (min-width: 1200px) {
  .nav-menuzord .menuzord .menuzord-menu > li > a {
    padding: 37px 0;
    margin: 0 15px;
  }
}

.nav-menuzord .menuzord .menuzord-menu > li > .megamenu {
  padding: 0;
}

@media (min-width: 992px) {
  .nav-menuzord .menuzord .menuzord-menu > li > .megamenu {
    border-radius: 5px;
    padding: 10px 30px 15px;
    border: 1px solid rgba(0, 0, 0, 0.15);
  }
}

.nav-menuzord .menuzord .menuzord-menu > li > .megamenu .list-unstyled li {
  transition: all .3s;
  border-bottom: solid 1px #f0f0f0;
  padding-left: 40px;
}

.nav-menuzord .menuzord .menuzord-menu > li > .megamenu .list-unstyled li:hover {
  background: #f5f5f5;
}

.nav-menuzord .menuzord .menuzord-menu > li > .megamenu .list-unstyled li.active > a {
  color: #ff891e;
}

.nav-menuzord .menuzord .menuzord-menu > li > .megamenu .list-unstyled li:first-child {
  background-color: transparent !important;
  padding: 12px 20px;
  padding-left: 40px;
}

@media (min-width: 992px) {
  .nav-menuzord .menuzord .menuzord-menu > li > .megamenu .list-unstyled li:first-child {
    padding-left: 0;
  }
}

.nav-menuzord .menuzord .menuzord-menu > li > .megamenu .list-unstyled li a {
  display: block;
  color: #969696;
  font-size: 12px;
  padding: 10px 25px 10px 0;
  transition: all .3s;
}

.nav-menuzord .menuzord .menuzord-menu > li > .megamenu .list-unstyled li a:hover {
  margin-left: 8px;
  color: #ff891e;
}

@media (min-width: 992px) {
  .nav-menuzord .menuzord .menuzord-menu > li > .megamenu .list-unstyled li {
    border-bottom: 0;
    padding-left: 0;
  }
}

.nav-menuzord .menuzord .menuzord-menu > li > .megamenu .megamenu-row [class*="col"] {
  margin-top: 0;
}

.nav-menuzord .menuzord .menuzord-menu .menuzord-btn {
  text-align: center;
}

@media (min-width: 992px) {
  .nav-menuzord .menuzord .menuzord-menu .menuzord-btn {
    display: inline-block;
    padding-left: 12px;
  }
}

@media (min-width: 1200px) {
  .nav-menuzord .menuzord .menuzord-menu .menuzord-btn {
    padding-left: 15px;
  }
}

.nav-menuzord .menuzord .menuzord-menu .menuzord-btn .btn {
  color: #fff;
  text-transform: uppercase;
  font-weight: 700;
  padding: 7px 19px;
  margin: 15px 0;
  transition: all .3s;
}

@media (min-width: 992px) {
  .nav-menuzord .menuzord .menuzord-menu .menuzord-btn .btn {
    margin-top: 27px;
  }
}

.nav-menuzord .nav-item.dropdown {
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  right: 0;
}

@media (min-width: 992px) {
  .nav-menuzord .nav-item.dropdown {
    display: inline-block !important;
  }
}

.nav-menuzord .nav-item.dropdown .dropdown-menu {
  width: 350px;
  padding: 15px 20px;
  margin-top: 27px;
  border: 1px solid rgba(0, 0, 0, 0.15);
}

.nav-menuzord .nav-item.dropdown > .btn {
  padding: 10px 12px;
}

li.default-nav-item > .btn {
  margin: 0 !important;
  background-color: #ff891e !important;
  border-color: #ff891e !important;
}

li.default-nav-item > .btn i {
  color: #fff !important;
}

.nav-menuzord .menuzord .menuzord-menu > li.default-nav-item > a:before,
.nav-menuzord .menuzord .menuzord-menu > li.default-nav-item > a:after {
  background-color: transparent !important;
}

/* Static Navbar*/
.static .navbar-sticky {
  display: none;
}

/* Fixed navber when scroll up */
.up-scroll .navbar-scrollUp.navbar-sticky {
  transform: translateY(-100%);
  transition: .4s ease-in-out;
  box-shadow: none;
}

.nav-menuzord-transparent {
  position: absolute;
  width: 100%;
  background-color: transparent;
  box-shadow: none;
}

.nav-menuzord-transparent .menuzord-menu {
  background-color: #fff;
  padding: 0 20px;
}

@media (min-width: 992px) {
  .nav-menuzord-transparent .menuzord-menu {
    background-color: transparent;
  }
  .nav-menuzord-transparent .menuzord-menu > li > a {
    color: #fff !important;
  }
  .nav-menuzord-transparent .menuzord-menu > li > a:before {
    background-color: rgba(255, 255, 255, 0.3) !important;
  }
}

/* navbar-sticky */
.navbar-sticky {
  position: fixed;
  width: 100%;
  z-index: 1000;
  top: 0;
}

.navbar-sticky.nav-menuzord {
  background-color: #fff;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
}

.navbar-sticky.nav-menuzord .menuzord .menuzord-logo-brand {
  background-image: url(../img/logo-color-big.png);
}

@media (min-width: 992px) {
  .navbar-sticky.nav-menuzord .menuzord-menu > li > a {
    color: #000 !important;
  }
  .navbar-sticky.nav-menuzord .menuzord-menu > li > a:before {
    background-color: rgba(0, 0, 0, 0.1) !important;
  }
}

/* Section Titile */
.section-title, .section-title2 {
  position: relative;
  margin-bottom: 30px;
}

@media (min-width: 768px) {
  .section-title, .section-title2 {
    margin-bottom: 48px;
  }
}

.section-title:before, .section-title2:before {
  content: "";
  top: 11px;
  width: 100%;
  height: 1px;
  display: block;
  position: absolute;
  background-color: #e5e5e5;
}

@media (min-width: 768px) {
  .section-title:before, .section-title2:before {
    top: 14px;
  }
}

.section-title span, .section-title2 span {
  padding-left: 38px;
  padding-right: 38px;
}

.section-title h2, .section-title2 h2 {
  font-size: 20px;
  margin-bottom: 16px;
}

@media (min-width: 768px) {
  .section-title h2, .section-title2 h2 {
    font-size: 26px;
  }
}

.section-title p, .section-title2 p {
  font-size: 14px;
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .section-title p, .section-title2 p {
    font-size: 18px;
  }
}

.section-title2 span {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.section-title2:before {
  display: none;
}

@media (min-width: 768px) {
  .section-title-bg {
    background-image: url(../img/home/devider.png);
    background-position: right center;
    background-repeat: no-repeat;
    background-size: 8px 45px;
  }
}

.section-title-bg h1 {
  font-size: 14px;
}

@media (min-width: 992px) {
  .section-title-bg h1 {
    font-size: 18px;
  }
}

@media (min-width: 1200px) {
  .section-title-bg h1 {
    font-size: 20px;
  }
}

.section-title-bg h2 {
  font-size: 30px;
}

@media (min-width: 992px) {
  .section-title-bg h2 {
    font-size: 22px;
  }
}

@media (min-width: 1200px) {
  .section-title-bg h2 {
    font-size: 26px;
  }
}

.section-top {
  padding-top: 94px;
}

@media (min-width: 992px) {
  .section-top {
    padding-top: 0;
  }
}

.element-title {
  margin-bottom: 1rem;
}

/* Secelete2 */
.select2-select {
  width: 100%;
}

.select2-container--default .select2-selection--single:focus {
  outline: 0;
}

.select-dropdown-top {
  margin-top: 0;
}

.select-dropdown-top .select2-container--default .select2-selection--single {
  background-color: transparent;
  border: 0;
  border-radius: 0;
  height: 30px;
  line-height: 30px;
  position: relative;
}

.select-dropdown-top .select2-container--default .select2-selection--single:before {
  position: absolute;
  content: '\f107';
  font-family: 'FontAwesome';
  font-style: normal;
  right: 0;
  color: #fff;
}

.select-dropdown-top .select2-container--default .select2-selection--single .select2-selection__arrow b {
  top: 100%;
  left: 0;
  display: none;
}

.select-dropdown-top .select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #fff;
  font-weight: 400;
  line-height: 30px;
  padding-left: 20px;
}

.select-dropdown-top .select2-container--default.select2-container--open.select2-container--below .select2-selection--single, .select-dropdown-top .select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.select-dropdown {
  transition: all .9s;
}

.select-dropdown .select2-container--default .select2-selection--single {
  transition: all .9s;
  background-color: transparent;
  border: 1px solid transparent;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 0;
  height: 45px;
  line-height: 45px;
  position: relative;
}

.select-dropdown .select2-container--default .select2-selection--single:before {
  position: absolute;
  content: '\f107';
  font-family: 'FontAwesome';
  font-style: normal;
  right: 0;
  color: rgba(255, 255, 255, 0.3);
}

.select-dropdown .select2-container--default .select2-selection--single .select2-selection__arrow b {
  top: 100%;
  left: 0;
  display: none;
}

.select-dropdown .select2-container--default .select2-selection--single .select2-selection__rendered {
  color: rgba(255, 255, 255, 0.3);
  font-size: 13px;
  font-weight: 400;
  line-height: 45px;
  padding-left: 0;
}

.select-dropdown .select2-container--default.select2-container--open.select2-container--below .select2-selection--single, .select-dropdown .select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.select-dropdown.text-color .select2-container--default .select2-selection--single {
  border-bottom: 1px solid #dedede !important;
}

.select-dropdown.text-color .select2-container--default .select2-selection--single .select2-selection__rendered, .select-dropdown.text-color .select2-container--default .select2-selection--single:before {
  color: #969696 !important;
}

.select-dropdown-option .select2-container--default .select2-selection--single {
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0;
  height: 50px;
  line-height: 50px;
  position: relative;
}

.select-dropdown-option .select2-container--default .select2-selection--single:before {
  position: absolute;
  content: '\f017';
  font-family: 'FontAwesome';
  font-style: normal;
  right: 20px;
  color: #969696;
}

.select-dropdown-option .select2-container--default .select2-selection--single .select2-selection__arrow b {
  top: 100%;
  left: 0;
  display: none;
}

.select-dropdown-option .select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #969696;
  font-weight: 400;
  line-height: 50px;
  padding-left: 20px;
}

.select-dropdown-option .select2-container--default.select2-container--open.select2-container--below .select2-selection--single, .select-dropdown-option .select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.select2-search--dropdown {
  display: none;
}

.select2-container--open .select2-dropdown--below {
  border: 1px solid #ddd;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.select2-results__option[aria-selected] {
  border-bottom: 1px solid #ddd;
  font-family: open sans,sans-serif;
  font-size: 12px;
  padding: 11px 17px;
  text-transform: capitalize;
  transition: all .4s;
  color: #969696;
}

.select2-container--default .select2-results__option[aria-selected=true] {
  background-color: #fff;
  color: #969696;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #fff;
  color: #969696;
}

.select2-container--default .select2-results__option--highlighted[aria-selected]:hover {
  color: #ff891e;
  background-color: #f8f9fa;
}

.select2-container--default .select2-results > .select2-results__options {
  color: #969696;
}

.daterangepicker td.active, .daterangepicker td.active:hover {
  background-color: #ff891e;
  border-color: transparent;
  color: #fff;
}

.daterange-picker {
  padding: 0;
  outline: 0;
  font-size: 13px;
  border-radius: 0;
  box-shadow: none !important;
  color: rgba(255, 255, 255, 0.3);
  font-weight: 400;
  border-color: transparent !important;
  background-color: transparent !important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3) !important;
}

.daterange-picker:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.3);
}

.daterange-picker::placeholder {
  color: rgba(255, 255, 255, 0.3);
}

.sidebar-daterange-picker {
  color: #969696 !important;
  border-bottom: 1px solid #dedede !important;
}

.sidebar-daterange-picker:-ms-input-placeholder {
  color: #969696 !important;
}

.sidebar-daterange-picker::placeholder {
  color: #969696 !important;
}

.daterange-picker-white {
  border-radius: 5px;
  color: #fff !important;
  text-transform: uppercase;
  border-color: #fff !important;
  background-color: transparent !important;
  padding: 0.71rem 1rem;
  font-family: sans-serif;
}

.daterange-picker-white:-ms-input-placeholder {
  color: #fff;
}

.daterange-picker-white::placeholder {
  color: #fff;
}

.daterange-picker-category-2 {
  border-radius: 3px;
  color: #666 !important;
  text-transform: uppercase;
  border-color: #dedede !important;
  background-color: transparent !important;
  padding: 0.71rem 1rem;
  font-family: sans-serif;
}

.daterange-picker-category-2:-ms-input-placeholder {
  color: #969696;
}

.daterange-picker-category-2::placeholder {
  color: #969696;
}

/* Card */
.card {
  transition: all .3s;
}

.card img {
  transition: all .3s;
}

.card .card-header {
  padding: 0.93rem 1.5rem;
}

.card .card-title {
  font-size: 15px;
  font-weight: 700;
  color: #222;
  transition: all .3s;
}

.card:hover .card-title {
  color: #ff891e;
}

/* Card Large */
.card-lg img {
  -o-object-fit: cover;
     object-fit: cover;
  height: 300px;
}

@media (min-width: 768px) {
  .card-lg img {
    height: 400px;
  }
}

@media (min-width: 992px) {
  .card-lg img {
    height: 350px;
  }
}

@media (min-width: 1200px) {
  .card-lg img {
    height: auto;
  }
}

/* Card Medium */
.card-md .card-body {
  padding-top: 43px;
  padding-bottom: 43px;
}

/* Card Hover */
.card-hover:hover {
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
}

/* Card Bg */
.card-bg {
  background-color: #f5f5f5;
}

.card-bg:hover {
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

/* Card Bg White */
.card-bg-white {
  padding: 70px 0 40px;
}

.card-bg-white .card-body {
  padding: 1.8rem;
}

.card-bg-white .card-body h6 {
  line-height: 26px;
  margin-bottom: 25px;
}

.card-bg-white .card-body a {
  font-size: 13px;
  color: #969696;
  text-decoration: underline;
  transition: all .3s;
}

.card-bg-white .card-body a:hover {
  color: #ff891e;
}

/* Card Opacity */
@media (min-width: 768px) {
  .card-opacity img {
    width: auto;
  }
}

@media (min-width: 992px) {
  .card-opacity img {
    width: 100%;
  }
}

.card-opacity .card-body {
  padding-top: 15px;
}

.card-opacity .card-body h5 > a {
  margin-bottom: 5px;
}

.card-opacity:hover .card-hover-overlay {
  background-color: rgba(0, 0, 0, 0.3);
}

/* Card Transparent */
.card-transparent {
  background-color: transparent;
}

.card-transparent .card-footer {
  padding: 0;
  background-color: transparent;
}

/* Card Hover Overlay */
.card-hover-overlay {
  transition: all .5s;
}

.card-hover-overlay:hover {
  background-color: rgba(0, 0, 0, 0.3);
}

/* Card Hoverable */
.card-hoverable {
  transition: all .3s ease-in-out;
  background-color: rgba(0, 0, 0, 0.7);
}

@media (min-width: 992px) {
  .card-hoverable:hover {
    background-color: rgba(0, 0, 0, 0.7);
  }
  .card-hoverable:hover .top-content {
    bottom: 185px;
    margin-bottom: 0;
  }
  .card-hoverable:hover .bottom-content {
    opacity: 1;
    visibility: visible;
    transition: all .99s;
  }
}

@media (min-width: 992px) {
  .card-hoverable {
    background-color: transparent;
  }
}

.card-hoverable .top-content {
  transition: all .3s ease-in-out;
  bottom: 185px;
  position: absolute;
  margin-bottom: 0;
}

@media (min-width: 992px) {
  .card-hoverable .top-content {
    bottom: 0;
    margin-bottom: 30px;
  }
}

.card-hoverable .bottom-content {
  transition: all .2s;
}

@media (min-width: 992px) {
  .card-hoverable .bottom-content {
    opacity: 0;
    visibility: hidden;
    transition: all .2s;
  }
}

/* Card Bg Img */
.card-bg-img .card-img-top {
  height: auto;
}

@media (min-width: 1200px) {
  .card-bg-img .card-img-top {
    height: 450px !important;
  }
}

@media (min-width: 768px) {
  .card-bg-img .card-img-top {
    height: 400px;
  }
}

/* Meta Post */
.meta-post-sm .meta-tag {
  font-size: 12px;
}

/* Icon Badge */
.icon-badge {
  display: inline-block;
  text-align: center;
  position: relative;
}

.icon-badge .badge {
  position: absolute;
  top: -5px;
  right: -10px;
  padding: 0.1875rem 0.44rem;
  border-radius: 0.625rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

/* Badge Rounded And Rounded Circle */
.badge-rounded,
.badge-rounded-circle {
  left: 20px;
  top: 20px;
  text-align: center;
  transition: all .3s ease-in-out;
}

.badge-rounded span,
.badge-rounded-circle span {
  font-size: 18px;
  text-transform: uppercase;
  top: 50%;
  position: relative;
  transform: translateY(-50%);
}

.badge-rounded {
  font-size: 1rem;
  padding: .89rem 1.375rem;
  color: #fff;
  line-height: 1;
  font-weight: 700;
  border-radius: 4px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.badge-rounded-circle {
  padding: 0;
  width: 76px;
  height: 76px;
  border-radius: 50%;
  box-shadow: none;
  text-align: center;
}

/* Badge Wide */
.badge-large {
  position: absolute;
  font-size: 1.25rem;
  color: #fff;
  text-transform: capitalize;
  line-height: 1;
  padding: 0 .625rem;
}

@media (min-width: 992px) {
  .badge-large {
    font-size: 1.88rem;
    padding: 0.4375rem 1.8rem;
  }
}

.badge-large-left {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: .375rem;
  border-bottom-right-radius: .375rem;
  top: 15px;
}

@media (min-width: 992px) {
  .badge-large-left {
    top: 55px;
  }
}

.badge-large-right {
  border-top-left-radius: .375rem;
  border-bottom-left-radius: .375rem;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  top: 15px;
  right: 0;
}

@media (min-width: 992px) {
  .badge-large-right {
    top: 55px;
  }
}

.badge-medium-center,
.badge-large-center {
  border-radius: .375rem;
  bottom: 15px;
  left: 50%;
  transform: translate(-50%);
}

.badge-medium {
  position: absolute;
  font-size: 1.125rem;
  color: #fff;
  text-transform: capitalize;
  padding: .937rem 0;
  width: 100%;
}

@media (min-width: 768px) {
  .badge-medium {
    font-size: 1.25rem;
    padding: .937rem 2.81rem;
    width: auto;
  }
}

@media (min-width: 992px) {
  .badge-medium {
    padding: 22px 33px;
    bottom: 30px;
  }
  .badge-medium span {
    font-size: 1.5625rem;
  }
}

.badge-sm {
  width: 60px !important;
  height: 60px !important;
  line-height: 60px !important;
  background-color: rgba(0, 0, 0, 0.06);
}

.badge-sm span {
  font-size: 30px !important;
}

.badge-primary {
  color: #fff;
}

.badge-primary:hover {
  color: #fff !important;
}

.badge-black-Offset {
  background-color: rgba(0, 0, 0, 0.35);
}

.badge-hover {
  transition: all .3s;
}

.badge-hover:hover {
  opacity: .8;
}

.badge-pill-sm {
  font-weight: 400;
  border: 1px solid #dedede;
  color: #969696;
  padding: 10px;
  font-size: 12px;
  border-radius: 5px;
  text-transform: uppercase;
}

/* Custom Counter */
.counter-up .icon.bg-primary {
  background-color: rgba(255, 137, 30, 0.8) !important;
}

.counter-up .icon.bg-secondary {
  background-color: rgba(108, 117, 125, 0.8) !important;
}

.counter-up .icon.bg-success {
  background-color: rgba(96, 166, 98, 0.8) !important;
}

.counter-up .icon.bg-danger {
  background-color: rgba(226, 90, 90, 0.8) !important;
}

.counter-up .icon.bg-warning {
  background-color: rgba(254, 199, 1, 0.8) !important;
}

.counter-up .icon.bg-info {
  background-color: rgba(26, 179, 232, 0.8) !important;
}

.counter-up h1 {
  font-size: 32px;
  color: #fff;
}

@media (min-width: 992px) {
  .counter-up h1 {
    font-size: 52px;
  }
}

.counter-up h6 {
  color: #fff;
  font-size: 13px;
  text-transform: uppercase;
}

@media (min-width: 992px) {
  .counter-up h6 {
    font-size: 15px;
  }
}

/*=== 6.4 PRELOADER ===*/
.smooth-loader-wrapper {
  background-color: #ff891e;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 9999999;
}

.smooth-loader-wrapper .smooth-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2100;
  line-height: 0;
  transform: translate(-50%, -50%);
}

.sk-circle {
  margin: 0 auto;
  width: 40px;
  height: 40px;
  position: relative;
}

@media (min-width: 768px) {
  .sk-circle {
    width: 60px;
    height: 60px;
  }
}

.sk-circle .sk-child {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.sk-circle .sk-child:before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: #fff;
  border-radius: 100%;
  animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
}

.sk-circle .sk-circle2 {
  transform: rotate(30deg);
}

.sk-circle .sk-circle3 {
  transform: rotate(60deg);
}

.sk-circle .sk-circle4 {
  transform: rotate(90deg);
}

.sk-circle .sk-circle5 {
  transform: rotate(120deg);
}

.sk-circle .sk-circle6 {
  transform: rotate(150deg);
}

.sk-circle .sk-circle7 {
  transform: rotate(180deg);
}

.sk-circle .sk-circle8 {
  transform: rotate(210deg);
}

.sk-circle .sk-circle9 {
  transform: rotate(240deg);
}

.sk-circle .sk-circle10 {
  transform: rotate(270deg);
}

.sk-circle .sk-circle11 {
  transform: rotate(300deg);
}

.sk-circle .sk-circle12 {
  transform: rotate(330deg);
}

.sk-circle .sk-circle2:before {
  animation-delay: -1.1s;
}

.sk-circle .sk-circle3:before {
  animation-delay: -1s;
}

.sk-circle .sk-circle4:before {
  animation-delay: -0.9s;
}

.sk-circle .sk-circle5:before {
  animation-delay: -0.8s;
}

.sk-circle .sk-circle6:before {
  animation-delay: -0.7s;
}

.sk-circle .sk-circle7:before {
  animation-delay: -0.6s;
}

.sk-circle .sk-circle8:before {
  animation-delay: -0.5s;
}

.sk-circle .sk-circle9:before {
  animation-delay: -0.4s;
}

.sk-circle .sk-circle10:before {
  animation-delay: -0.3s;
}

.sk-circle .sk-circle11:before {
  animation-delay: -0.2s;
}

.sk-circle .sk-circle12:before {
  animation-delay: -0.1s;
}

@keyframes sk-circleBounceDelay {
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}

.loaded #preloader {
  opacity: 0;
  visibility: hidden;
  transition: all 0.7s ease-out;
}

@media (prefers-reduced-motion: reduce) {
  .loaded #preloader {
    transition: none;
  }
}

.dzsparallaxer:not(.mode-oneelement) {
  transition-duration: 0.9s;
}

.dzsparallaxer.use-loading .dzsparallaxer--target {
  transition-duration: 0.9s;
}

.paralax-container {
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all .9s;
}

/* Promotion Paralax */
.promotion-paralax {
  height: 403px !important;
  transition: all .9s;
}

/* Countup Paralax */
.counterup-paralax {
  height: 550px !important;
  transition: all .9s;
}

@media (min-width: 768px) {
  .counterup-paralax {
    height: 452px !important;
  }
}

/* City Paralax */
.city-paralax {
  height: 440px !important;
}

@media (min-width: 768px) {
  .city-paralax {
    height: 359px !important;
  }
}

/* White Paralax */
.white-paralax {
  height: 600px !important;
}

@media (min-width: 768px) {
  .white-paralax {
    height: 450px !important;
  }
}

/* Signup Paralax */
.signup-paralax {
  height: 450px !important;
}

@media (min-width: 768px) {
  .signup-paralax {
    height: 400px !important;
  }
}

@media (min-width: 992px) {
  .signup-paralax {
    height: 386px !important;
  }
}

/* City Paralax */
.travel-paralax {
  height: 600px !important;
}

@media (min-width: 768px) {
  .travel-paralax {
    height: 524px !important;
  }
}

/* Video Paralax */
.video-paralax {
  height: 350px !important;
}

@media (min-width: 768px) {
  .video-paralax {
    height: 550px !important;
  }
}

/* Video Paralax */
.sale-paralax {
  height: 400px !important;
}

/** Custom  Modal **/
.modal-sm .modal-content {
  border-radius: 6px;
}

.modal-sm .modal-content .modal-header {
  border-top: 6px solid #ff891e;
  border-bottom: 0;
  padding: 10px 20px;
}

.modal-sm .modal-content .modal-header .close {
  font-size: 30px;
  color: #222;
  opacity: 1;
  font-weight: 400;
  padding: 0;
  margin: 0;
}

.modal-sm .modal-content .modal-header .close:focus {
  outline: 0;
}

.modal-sm .modal-content .modal-body {
  padding: 10px 20px;
}

.modal-sm .modal-content .modal-footer {
  border-top: 0;
}

/* Slick Slider */
.gallery-slider .slick-prev,
.gallery-slider .slick-next {
  display: none !important;
}

@media (min-width: 768px) {
  .gallery-slider .slick-prev,
  .gallery-slider .slick-next {
    display: block !important;
  }
}

.gallery-slider .slick-prev {
  left: 0;
  z-index: 1;
}

@media (min-width: 768px) {
  .gallery-slider .slick-prev {
    left: -60px;
  }
}

@media (min-width: 992px) {
  .gallery-slider .slick-prev {
    left: -80px;
  }
}

.gallery-slider .slick-prev:before {
  content: "\f053";
  font-family: 'FontAwesome';
  color: #ff891e;
  font-size: 25px;
  display: block;
}

@media (min-width: 768px) {
  .gallery-slider .slick-prev:before {
    display: block;
    color: #a5a5a5;
  }
}

.gallery-slider .slick-next {
  right: 0px;
  z-index: 1;
}

@media (min-width: 768px) {
  .gallery-slider .slick-next {
    right: -60px;
  }
}

@media (min-width: 992px) {
  .gallery-slider .slick-next {
    right: -80px;
  }
}

.gallery-slider .slick-next:before {
  content: "\f054";
  font-family: 'FontAwesome';
  color: #ff891e;
  font-size: 25px;
  display: block;
}

@media (min-width: 768px) {
  .gallery-slider .slick-next:before {
    display: block;
    color: #a5a5a5;
  }
}

.gallery-slider .item {
  margin: 0;
  outline: 0;
  z-index: 0 !important;
  transform: scale(1) rotate(0);
}

@media (min-width: 768px) {
  .gallery-slider .item {
    transform: scale(0.8) rotate(0);
    opacity: 0.3;
    margin: 0px -100px;
    transition: transform 0.4s ease;
  }
}

.gallery-slider .item .slick-img img {
  margin: 0 auto;
  width: 100%;
}

@media (min-width: 768px) {
  .gallery-slider .item .slick-img img {
    width: 525px;
  }
}

@media (min-width: 992px) {
  .gallery-slider .item .slick-img img {
    width: 725px;
  }
}

@media (min-width: 1200px) {
  .gallery-slider .item .slick-img img {
    width: auto;
  }
}

.gallery-slider .item.slick-active {
  transform: scale(1) rotate(0);
  position: relative;
  opacity: 1;
  z-index: 1 !important;
}

@media (min-width: 768px) {
  .gallery-slider .item.slick-active .slick-img img {
    border: 5px solid #ff891e !important;
    border-radius: 3px;
  }
}

.brand-slider .item {
  outline: 0;
}

.brand-slider .slick-prev {
  z-index: 1;
  left: 0;
}

.brand-slider .slick-prev:before {
  font-family: 'FontAwesome';
  content: "\f104";
  color: #a5a5a5;
  font-size: 25px;
}

.brand-slider .slick-next {
  z-index: 1;
  right: 0;
}

.brand-slider .slick-next:before {
  content: "\f105";
  font-family: 'FontAwesome';
  color: #a5a5a5;
  font-size: 25px;
}

.brand-slider .slick-list .slick-track .slick-slide img {
  margin: 0 auto;
}

/* Selectric Default */
.select-default .selectric {
  background-color: transparent;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
}

.select-default .selectric .label {
  padding: .8rem 1.5rem;
  font-size: .875rem;
  color: #969696;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
}

.select-default .selectric .button {
  height: 42px;
  width: 46px;
  background: transparent;
  top: 2px;
  right: 2px;
}

.select-default .selectric .button::before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: fontawesome;
  content: '\f107';
  font-size: 1rem;
  color: #969696;
}

.select-default .selectric-open .selectric {
  margin-top: 0;
}

.select-default .selectric-items {
  margin: 0;
  padding: 0;
  border-radius: 0;
  border-color: #dee2e6;
}

.select-default .selectric-items::before, .select-default .selectric-items::after {
  display: none;
}

.select-default .selectric-items .selectric-scroll li {
  border-bottom: 1px solid #dee2e6;
  padding: 13px 30px 13px 15px;
  font-size: 12px;
  color: #969696;
  text-transform: uppercase;
}

.select-default .selectric-items .selectric-scroll li:hover {
  background-color: #edeff1;
  color: #ff891e;
}

.select-default .selectric-items .selectric-scroll li.highlighted {
  background-color: #edeff1;
  color: #ff891e;
}

.select-default .selectric-items .selectric-scroll li.highlighted::after {
  display: none;
}

/* Selectric Confirm */
.select-confirm .selectric {
  background-color: #f5f5f5;
  border: 0;
}

/* Selectric White */
.select-white .selectric {
  background-color: transparent;
  border-radius: 5px;
}

.select-white .selectric .label {
  color: #fff;
  text-transform: uppercase;
  font-family: sans-serif;
  font-size: 14px;
}

.select-white .selectric .button {
  background-color: transparent;
}

.select-white .selectric .button::before {
  color: #fff;
}

.select-white .selectric-items {
  border-radius: 5px;
}

.select-white .selectric-items .selectric-scroll li {
  font-size: 14px;
}

/* Selectric Category-1 */
.select-category-1 .selectric {
  background-color: transparent;
  border-color: transparent;
  border-bottom-color: #dedede;
  border-radius: 0;
}

.select-category-1 .selectric .label {
  font-size: 12px;
  padding-left: 0;
  padding-right: 0;
  margin: 0;
}

.select-category-1 .selectric .button {
  width: auto;
  right: 5px;
}

.select-category-1 .selectric-items {
  border-radius: 5px;
}

.select-category-1 .selectric-items .selectric-scroll li {
  font-size: 13px;
}

/* Selectric Category-2 */
.select-category-2 .selectric {
  background-color: transparent;
  border-color: #dedede;
  border-radius: 3px;
}

.select-category-2 .selectric .label {
  color: #666;
  font-size: 13px;
}

.select-category-2 .selectric .button {
  background-color: transparent;
}

.select-category-2 .selectric .button::before {
  color: #666;
}

.select-category-2 .selectric-items {
  border-radius: 5px;
}

.select-category-2 .selectric-items .selectric-scroll li {
  font-size: 13px;
}

.timer .selectric .button::before {
  content: '\f017';
  font-size: 13px;
}

/* Selectric Search Box */
.select-search-box .selectric {
  background-color: transparent;
  border-color: transparent;
  border-bottom-color: rgba(255, 255, 255, 0.3);
  border-radius: 0;
}

.select-search-box .selectric .label {
  font-size: 13px;
  padding-left: 0;
  padding-right: 0;
  margin: 0;
  color: rgba(255, 255, 255, 0.3);
}

.select-search-box .selectric .button {
  width: auto;
  right: 5px;
}

.select-search-box .selectric .button::before {
  color: rgba(255, 255, 255, 0.3);
}

.select-search-box .selectric-items {
  border-radius: 5px;
}

.select-search-box .selectric-items .selectric-scroll li {
  font-size: 13px;
}

/* Selectric Option */
.select-features .selectric .label {
  text-transform: capitalize;
}

.page-title {
  padding-top: 94px;
}

@media (min-width: 992px) {
  .page-title {
    padding-top: 0;
  }
}

.page-title .page-title-img {
  background-size: cover !important;
  background-position: center top !important;
  background-repeat: no-repeat;
  transition: all 0.3s linear;
  position: relative;
}

.page-title .page-title-content {
  text-align: center;
  position: relative;
}

.page-title .page-title-content h2 {
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 0;
  line-height: inherit;
}

.page-title .page-title-content p {
  font-size: 15px;
}

.page-title .title-border {
  position: relative;
}

.page-title .title-border h2 {
  margin-bottom: 23px;
}

.page-title .title-border::before {
  position: absolute;
  content: "";
  width: 60px;
  height: 3px;
  background-color: #fff;
  left: 50%;
  bottom: -12px;
  transform: translateX(-50%);
}

/* No UI Slider */
.price-range .price-range-content {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

@media (min-width: 768px) {
  .price-range .price-range-content {
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
  }
}

.price-range .price-text,
.price-range .price-value {
  color: #222;
  font-weight: 600;
}

.price-range .btn {
  margin-right: 10px;
}

@media (min-width: 768px) {
  .price-range .btn {
    margin-right: 0px;
  }
}

.noUi-target {
  background-color: #dedede;
  box-shadow: none;
  border: 0;
}

.noUi-horizontal {
  height: 4px;
  border-radius: 0.25rem;
}

.noUi-horizontal .noUi-handle {
  width: 16px;
  height: 16px;
  line-height: 16px;
  background-color: #ff891e;
  box-shadow: none;
  border: 0;
  border-radius: 50%;
  top: -7px;
}

.noUi-handle:after,
.noUi-handle:before {
  display: none;
}

.noUi-connect {
  background-color: #ff891e;
}

.noUi-horizontal .noUi-origin:focus,
.noUi-handle.noUi-handle-lower:focus,
.noUi-handle.noUi-handle-upper:focus {
  outline: none;
}

html:not([dir=rtl]) .noUi-horizontal .noUi-handle {
  right: -16px;
  cursor: e-resize;
}

.noUi-horizontal .noUi-handle.noUi-handle-lower {
  left: auto !important;
}

.noUi-horizontal .noUi-handle.noUi-handle-upper {
  right: -1px !important;
}

/* Pagination */
.pagination {
  margin-bottom: 0;
}

.pagination .page-item:first-child .page-link, .pagination .page-item:last-child .page-link {
  border: 1px solid #dedede;
  margin: 0;
}

@media (min-width: 768px) {
  .pagination .page-item:first-child .page-link, .pagination .page-item:last-child .page-link {
    padding: 0.5rem 1.3rem;
  }
}

.pagination .page-item:first-child .page-link:hover, .pagination .page-item:last-child .page-link:hover {
  color: #fff;
  background-color: #ff891e;
  border-color: #ff891e;
}

.pagination .page-item .page-link {
  color: #222;
  background-color: transparent;
  border: transparent;
  border-radius: 0.25rem;
  text-transform: uppercase;
  transition: all 0.3s;
  font-size: 10px;
  font-weight: 500;
}

@media (min-width: 768px) {
  .pagination .page-item .page-link {
    font-size: 0.875rem;
    font-weight: normal;
    margin: 0 1px;
  }
}

.pagination .page-item .page-link:hover {
  color: #ff891e;
}

.pagination .page-item .page-link.active {
  color: #ff891e;
  cursor: default;
}

/* Owl Carousel */
.owl-theme .owl-dots {
  margin-top: 0 !important;
}

.owl-theme .owl-dots .owl-dot span {
  background-color: transparent;
  border: 1px solid #fff;
  width: 12px;
  height: 12px;
  margin: 0 5px;
}

.owl-theme .owl-dots .owl-dot.active span {
  background-color: #ff891e;
  border: 1px solid #ff891e;
}

.owl-theme .owl-dots .owl-dot:hover span {
  background-color: #ff891e;
  border: 1px solid #ff891e;
}

.owl-theme .owl-dots .owl-dot:focus {
  outline: 0;
}

.package-slider .owl-stage-outer {
  border-radius: 5px;
}

.package-slider.owl-theme .owl-nav {
  margin-top: 0;
  display: none;
}

@media (min-width: 768px) {
  .package-slider.owl-theme .owl-nav {
    display: block;
  }
}

.package-slider.owl-theme .owl-nav .owl-prev,
.package-slider.owl-theme .owl-nav .owl-next {
  position: absolute;
  top: 100%;
}

.package-slider.owl-theme .owl-nav .owl-prev {
  left: 20px;
}

.package-slider.owl-theme .owl-nav .owl-next {
  right: 20px;
}

.package-slider.owl-theme .owl-nav [class*=owl-] {
  margin-top: 0;
  font-size: 1.25rem;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.35);
  border-radius: 4px;
  width: 75px;
  height: 75px;
  line-height: 75px;
  margin: 0;
  top: 50%;
  outline: 0;
  transform: translateY(-50%);
  transition: all 0.3s;
}

.package-slider.owl-theme .owl-nav [class*=owl-]:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

.package-slider img {
  -o-object-fit: cover;
     object-fit: cover;
  height: 300px;
  transition: all .4s;
}

@media (min-width: 768px) {
  .package-slider img {
    height: auto;
  }
}

@media (min-width: 1200px) {
  .package-slider.package-single img {
    height: 450px !important;
  }
}

@media (min-width: 768px) {
  .package-slider.package-single img {
    height: 400px;
    -o-object-fit: fill;
       object-fit: fill;
  }
}

/* Gallery Photo Slider */
.gallery-photo-slider .owl-stage-outer {
  border-radius: 5px;
}

.gallery-photo-slider.owl-theme .owl-nav {
  margin-top: 0;
  display: none;
}

@media (min-width: 768px) {
  .gallery-photo-slider.owl-theme .owl-nav {
    display: block;
  }
}

.gallery-photo-slider.owl-theme .owl-nav .owl-prev,
.gallery-photo-slider.owl-theme .owl-nav .owl-next {
  position: absolute;
  top: 100%;
}

.gallery-photo-slider.owl-theme .owl-nav .owl-prev {
  left: 20px;
}

.gallery-photo-slider.owl-theme .owl-nav .owl-next {
  right: 20px;
}

.gallery-photo-slider.owl-theme .owl-nav [class*=owl-] {
  margin-top: 0;
  font-size: 1.25rem;
  color: #fff;
  background-color: rgba(255, 255, 255, 0.35);
  border-radius: 4px;
  width: 75px;
  height: 75px;
  line-height: 75px;
  margin: 0;
  top: 50%;
  outline: 0;
  transform: translateY(-50%);
  transition: all 0.3s;
}

.gallery-photo-slider.owl-theme .owl-nav [class*=owl-]:hover {
  background-color: rgba(255, 255, 255, 0.5);
}

.gallery-photo-slider img {
  -o-object-fit: cover;
     object-fit: cover;
  height: 300px;
  transition: all .4s;
}

@media (min-width: 768px) {
  .gallery-photo-slider img {
    height: auto;
  }
}

.owl-slider-tabs {
  bottom: 30px;
  z-index: 1;
  left: 50%;
  transform: translateX(-50%);
  display: none;
}

@media (min-width: 768px) {
  .owl-slider-tabs {
    display: block;
  }
}

.owl-slider-tabs li {
  background-color: #fff;
  border: 2px solid #fff;
  border-radius: 5px;
  margin-right: 5px;
}

.owl-slider-tabs li:last-child {
  margin-right: 0;
}

.owl-slider-tabs li img {
  height: auto;
}

.slidetabs {
  opacity: .5;
}

.slidetabs.active {
  opacity: 1;
}

/* Progres Wizard */
.progress-wizard {
  margin: 0 -10px 0;
}

@media (min-width: 768px) {
  .progress-wizard {
    margin: 0;
  }
}

.progress-wizard > .progress-wizard-step {
  padding: 0;
  position: relative;
}

@media (min-width: 768px) {
  .progress-wizard > .progress-wizard-step {
    margin-bottom: 0;
  }
}

.progress-wizard > .progress-wizard-step > .progress {
  position: relative;
  border-radius: 0;
  height: 2px;
  box-shadow: none;
  margin: 0;
  background-color: #dedede;
  top: 50%;
}

.progress-wizard > .progress-wizard-step > .progress > .progress-bar {
  box-shadow: none;
  background-color: #ff891e;
}

@media (min-width: 768px) {
  .progress-wizard > .progress-wizard-step > .progress > .progress-bar {
    background-color: #ff891e;
  }
}

.progress-wizard > .progress-wizard-step.complete > .progress > .progress-bar {
  width: 100%;
}

.progress-wizard > .progress-wizard-step.active > .progress > .progress-bar {
  width: 50%;
}

.progress-wizard > .progress-wizard-step > .progress > .progress-bar {
  width: 0%;
}

.progress-wizard > .progress-wizard-step:first-child.active > .progress > .progress-bar {
  width: 0%;
}

.progress-wizard > .progress-wizard-step:last-child.active > .progress > .progress-bar {
  width: 100%;
}

.progress-wizard > .progress-wizard-step:first-child > .progress {
  left: 50%;
  width: 50%;
}

.progress-wizard > .progress-wizard-step:last-child > .progress {
  width: 50%;
}

.progress-wizard > .progress-wizard-step.disabled a.progress-wizard-dot {
  pointer-events: none;
}

.progress {
  background-color: transparent;
  margin-bottom: 10px;
}

@media (min-width: 768px) {
  .progress {
    background-color: #dedede;
  }
}

.progress-wizard > .progress-wizard-step > .progress-wizard-dot {
  display: block;
  background-color: #fff;
  border-radius: 7px;
  border: 2px solid #ededed;
  text-align: center;
  color: #969696;
  width: 85%;
  height: 60px;
  position: relative;
  margin: auto;
  top: 0;
  left: 0;
  transition: all .4s;
}

@media (min-width: 768px) {
  .progress-wizard > .progress-wizard-step > .progress-wizard-dot {
    width: 203px;
    height: 102px;
    border-radius: 10px;
    margin: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}

.progress-wizard > .progress-wizard-step > .progress-wizard-dot .progress-wizard-content {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.progress-wizard > .progress-wizard-step > .progress-wizard-dot .progress-wizard-content i {
  font-size: 13px;
  transition: all .4s;
}

@media (min-width: 768px) {
  .progress-wizard > .progress-wizard-step > .progress-wizard-dot .progress-wizard-content i {
    margin-bottom: 15px;
    font-size: 20px;
  }
}

.progress-wizard > .progress-wizard-step > .progress-wizard-dot .progress-wizard-content span {
  font-size: 10px;
  font-weight: 500;
  transition: all .4s;
}

@media (min-width: 768px) {
  .progress-wizard > .progress-wizard-step > .progress-wizard-dot .progress-wizard-content span {
    font-size: 14px;
    font-weight: 400;
  }
}

.progress-wizard > .progress-wizard-step > .progress-wizard-dot:hover {
  opacity: 1;
}

.progress-wizard > .progress-wizard-step.active > .progress-wizard-dot {
  border-color: #ff891e;
  color: #222;
}

.progress-wizard > .progress-wizard-step.incomplete > .progress-wizard-dot i {
  color: #222;
}

.progress-wizard > .progress-wizard-step.complete > .progress-wizard-dot {
  background-color: #ff891e;
  border-color: #ff891e;
}

.progress-wizard > .progress-wizard-step.complete > .progress-wizard-dot i, .progress-wizard > .progress-wizard-step.complete > .progress-wizard-dot span {
  color: #fff;
}

.progress-wizard .progress-info {
  text-align: center;
  font-weight: 600;
  font-size: 1rem;
  color: #222;
}

.alert-confirm {
  padding: 0;
  border-left: 0;
}

.alert-confirm i {
  border-radius: 4px 0 0 4px;
  font-size: 20px;
  color: #fff;
  padding: 35px 15px;
  width: 50px;
  text-align: center;
}

.alert-confirm > span {
  padding-left: 15px;
  padding-right: 80px;
  line-height: 20px;
}

@media (min-width: 992px) {
  .alert-confirm > span {
    font-size: 16px !important;
  }
}

.alert-confirm .close {
  text-shadow: none;
  opacity: 1;
  z-index: 1;
}

.alert-confirm .close:hover {
  color: inherit;
}

.alert-confirm .close:focus {
  outline: 0;
}

.alert-confirm > span, .alert-confirm .close {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 11px;
  transition: all .4s;
}

@media (min-width: 768px) {
  .alert-confirm > span, .alert-confirm .close {
    font-size: 14px;
  }
}

.alert-confirm.alert-success i {
  background-color: #60a662;
}

.alert-confirm.alert-success .close {
  color: #60a662;
}

.alert-confirm.alert-danger i {
  background-color: #e25a5a;
}

.alert-confirm.alert-danger .close {
  color: #e25a5a;
}

.alert-confirm.alert-primary i {
  background-color: #ff891e;
}

.alert-confirm.alert-primary .close {
  color: #ff891e;
}

.alert-confirm.alert-warning i {
  background-color: #fec701;
}

.alert-confirm.alert-warning .close {
  color: #fec701;
}

.alert-confirm.alert-info i {
  background-color: #1ab3e8;
}

.alert-confirm.alert-info .close {
  color: #1ab3e8;
}

.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: 1;
}

.alert-default {
  padding: 0;
  border-left: 0;
}

.alert-default i {
  border-radius: 4px 0 0 4px;
  font-size: 14px;
  color: #fff;
  padding: 25px 0;
  text-align: center;
  opacity: 0;
  width: -webkit-fill-available;
}

@media (min-width: 768px) {
  .alert-default i {
    opacity: 1;
    width: 50px;
    padding: 37px 0;
  }
}

@media (min-width: 992px) {
  .alert-default i {
    font-size: 16px;
  }
}

.alert-default > span {
  padding-left: 9px;
  padding-right: 65px;
  line-height: 20px;
}

@media (min-width: 768px) {
  .alert-default > span {
    padding-left: 25px;
    padding-right: 80px;
  }
}

@media (min-width: 992px) {
  .alert-default > span {
    font-size: 16px !important;
  }
}

.alert-default .close {
  text-shadow: none;
  opacity: 1;
  z-index: 1;
  padding: 0.75rem .7rem;
}

@media (min-width: 768px) {
  .alert-default .close {
    padding: 0.75rem 1.25rem;
  }
}

.alert-default .close:hover {
  color: inherit;
}

.alert-default .close:focus {
  outline: 0;
}

.alert-default > span, .alert-default .close {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 11px;
  transition: all .4s;
}

@media (min-width: 768px) {
  .alert-default > span, .alert-default .close {
    font-size: 14px;
  }
}

.alert-default.alert-success i {
  background-color: #60a662;
}

.alert-default.alert-danger i {
  background-color: #e25a5a;
}

.alert-default.alert-primary i {
  background-color: #ff891e;
}

.alert-default.alert-warning i {
  background-color: #fec701;
}

.alert-default.alert-info i {
  background-color: #1ab3e8;
}

.alert-dismissible .close {
  outline: 0;
}

/* Accordion */
.accordion .card:not(:first-of-type):not(:last-of-type) {
  border: 0;
}

.accordion .card:first-of-type {
  border: 0;
}

.accordion .card:last-of-type {
  border: 0;
}

.accordion .card {
  border-radius: 0;
  margin-bottom: 0.9375rem;
  background-color: transparent;
}

.accordion .card .card-header {
  padding: 0;
  border-bottom: 1px solid transparent;
  background-color: transparent;
}

.accordion .card .card-header h5 {
  cursor: pointer;
  margin-bottom: 0;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  color: #969696;
  font-size: 13px;
  padding: 0.75rem 2.1875rem 0.75rem 0;
  text-transform: uppercase;
  position: relative;
}

.accordion .card .card-header h5 span {
  display: block;
}

.accordion .card .card-header h5.icon-bg {
  position: relative;
  border-bottom: 1px solid #e5e5e5;
  background-color: transparent;
  transition: all .3s;
}

.accordion .card .card-header h5.icon-bg:before {
  position: absolute;
  top: 50%;
  font-family: 'FontAwesome';
  font-style: normal;
  font-size: .8125rem;
  font-weight: 400;
  padding: .125rem 0.187rem;
  transform: translateY(-50%) rotate(0deg);
  content: '\f068';
  right: 0;
  color: #969696;
}

.accordion .card .card-header h5.icon-bg.collapsed {
  background-color: transparent;
  color: #969696;
  border-bottom: 1px solid transparent;
  transition: all .3s;
}

.accordion .card .card-header h5.icon-bg.collapsed:before {
  transform: translateY(-50%) rotate(0deg);
  color: #969696;
  content: '\f067';
}

.accordion .card .card-body {
  padding: 1.875rem 0 0;
}

/* Custom Scss */
.coming-soon {
  background: no-repeat center top fixed;
  background-size: cover;
}

.coming-soon .coming-soon-logo {
  position: absolute;
  top: 0;
  width: 100%;
  padding: 15px 0;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.3);
}

@media (min-width: 768px) {
  .coming-soon .coming-soon-logo {
    padding: 32px 0;
  }
}

.coming-soon .coming-soon-logo img {
  max-width: 100%;
  outline: 0;
}

.coming-soon .coming-soon-content {
  text-align: center;
}

.coming-soon .coming-soon-content h1 {
  font-size: 30px;
  color: #fff;
  margin-bottom: 20px;
  text-transform: uppercase;
}

@media (min-width: 768px) {
  .coming-soon .coming-soon-content h1 {
    font-size: 40px;
    margin-bottom: 40px;
  }
}

.coming-soon .coming-soon-content p {
  font-size: 0.875rem;
  color: #a7a7a7;
  margin-bottom: 30px;
}

.coming-soon .coming-soon-count {
  margin-bottom: 35px;
}

/* Soyotimer */
.coming-soon-count .timer-body-block {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  text-align: center;
}

.coming-soon-count .timer-body-block .table-cell {
  margin: 0 4px;
}

@media (min-width: 768px) {
  .coming-soon-count .timer-body-block .table-cell {
    margin: 0 12px;
  }
}

@media (min-width: 1200px) {
  .coming-soon-count .timer-body-block .table-cell {
    margin: 0 15px;
  }
}

.coming-soon-count .timer-body-block .table-cell .tab-val {
  display: block;
  width: 65px;
  height: 65px;
  line-height: 65px;
  margin-bottom: 20px;
  font-size: 1.5625rem;
  border-radius: 50%;
  color: #fff;
  font-weight: 700;
  border: 1px solid #fff;
  background-color: rgba(255, 255, 255, 0.1);
}

@media (min-width: 768px) {
  .coming-soon-count .timer-body-block .table-cell .tab-val {
    width: 85px;
    height: 85px;
    line-height: 85px;
    font-size: 2.1875rem;
    margin-bottom: 20px;
  }
}

@media (min-width: 1200px) {
  .coming-soon-count .timer-body-block .table-cell .tab-val {
    width: 120px;
    height: 120px;
    line-height: 120px;
  }
}

.coming-soon-count .timer-body-block .table-cell .tab-unit {
  font-size: 12px;
  text-transform: uppercase;
  color: #a7a7a7;
}

@media (min-width: 768px) {
  .coming-soon-count .timer-body-block .table-cell .tab-unit {
    font-size: 14px;
  }
}

/** Custom Table **/
.table-dashboard {
  padding: 10px;
  border-radius: 5px;
  background-color: #f5f5f5;
}

@media (min-width: 768px) {
  .table-dashboard {
    padding: 20px;
  }
}

@media (min-width: 992px) {
  .table-dashboard {
    padding: 30px;
  }
}

.table-dashboard h2 {
  font-size: 24px;
}

.table-dashboard .table {
  margin-bottom: 0;
  border: 1px solid #dee2e6;
  border-top: 0;
}

@media (min-width: 768px) {
  .table-dashboard .table {
    border: none;
  }
}

.table-dashboard .table th, .table-dashboard .table td {
  padding: 0;
  vertical-align: middle;
  padding: 15px 0;
}

.table-dashboard .table th:first-child, .table-dashboard .table td:first-child {
  padding-left: 10px;
}

@media (min-width: 768px) {
  .table-dashboard .table th:first-child, .table-dashboard .table td:first-child {
    padding-left: 15px;
  }
}

@media (min-width: 992px) {
  .table-dashboard .table th:first-child, .table-dashboard .table td:first-child {
    padding-right: 20px;
    padding-left: 20px;
  }
}

@media (min-width: 1200px) {
  .table-dashboard .table th:first-child, .table-dashboard .table td:first-child {
    padding-left: 30px;
  }
}

.table-dashboard .table th:last-child, .table-dashboard .table td:last-child {
  padding-right: 10px;
}

.table-dashboard .table h1, .table-dashboard .table h2, .table-dashboard .table h3, .table-dashboard .table h4, .table-dashboard .table h5, .table-dashboard .table h6, .table-dashboard .table p {
  margin: 0;
}

.table-dashboard .table tr {
  background-color: #fff;
}

.table-dashboard .table .badge {
  width: 55px;
  height: 58px;
  color: #fff;
}

@media (min-width: 992px) {
  .table-dashboard .table .badge {
    margin-right: -1.3pc;
  }
}

@media (min-width: 1200px) {
  .table-dashboard .table .badge {
    margin-right: -1.9pc;
  }
}

.table-dashboard .table .badge span {
  text-transform: capitalize;
  top: 50%;
  position: relative;
  transform: translateY(-50%);
}

.table-dashboard .table .badge h1, .table-dashboard .table .badge h3 {
  font-weight: 600;
}

.table-dashboard .table .badge h1 {
  font-size: 22px;
}

.table-dashboard .table .badge h3 {
  font-size: 14px;
}

.table-dashboard .table .badge,
.table-dashboard .table .list-unstyled {
  display: none;
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .table-dashboard .table .badge,
  .table-dashboard .table .list-unstyled {
    display: block;
  }
}

@media (min-width: 768px) {
  .table-dashboard .table .list-unstyled {
    border-left: 1px solid #ddd;
    margin-left: 15px;
    padding-left: 15px;
  }
}

.table-dashboard .table .list-unstyled h5, .table-dashboard .table .list-unstyled h6 {
  font-size: 11px;
  text-transform: uppercase;
  margin-bottom: 4px;
}

.table-dashboard .table .list-unstyled h5 {
  color: #ff891e;
}

.table-dashboard .table .list-unstyled h6 {
  color: #969696;
  margin-bottom: 10px;
}

.table-dashboard .table a {
  font-size: 12px;
  color: #222;
  font-weight: 400;
  text-transform: capitalize;
  transition: all .5s;
}

@media (min-width: 768px) {
  .table-dashboard .table a {
    font-size: 16px;
  }
}

.table-dashboard .table p {
  font-size: 9px;
  text-transform: uppercase;
}

@media (min-width: 768px) {
  .table-dashboard .table p {
    font-size: 14px;
  }
}

.table-dashboard .table h2, .table-dashboard .table h4 {
  text-align: right;
}

.table-dashboard .table h2 {
  font-size: 14px;
  color: #ff891e;
  margin-bottom: 5px;
}

@media (min-width: 768px) {
  .table-dashboard .table h2 {
    font-size: 22px;
    font-weight: 600;
  }
}

.table-dashboard .table h4 {
  font-size: 8px;
  color: #969696;
  text-transform: uppercase;
}

@media (min-width: 768px) {
  .table-dashboard .table h4 {
    font-size: 12px;
  }
}

.table-dashboard .table .second-child {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
}

@media (min-width: 768px) {
  .table-dashboard .table .second-child {
    padding: 32px 0px;
    padding-left: 10px;
  }
}

@media (min-width: 992px) {
  .table-dashboard .table .second-child {
    padding-right: 150px;
  }
}

.table-dashboard .table .second-child i {
  width: 45px;
  height: 45px;
  color: #ff891e;
  font-size: 20px;
  line-height: 45px;
  margin-right: 7px;
  border-radius: 50%;
  text-align: center;
  border: 1px solid #ff891e;
}

@media (min-width: 768px) {
  .table-dashboard .table .second-child i {
    margin-right: 30px;
  }
}

@media (min-width: 992px) {
  .table-dashboard .table .second-child i {
    margin-right: 10px;
  }
}

.table-dashboard .table.table-hover tbody tr {
  transition: all .5s;
}

/* Right Sidebar */
.element-right-sidebar {
  min-width: 215px;
}

.element-right-sidebar.sidebar-fixed {
  position: fixed;
  top: 110px;
  bottom: 0;
}

.element-right-sidebar.right-sidebar-absolute {
  position: absolute;
  top: auto;
  bottom: 0;
}

/* Footer Background Color */
.footer .footer-bg-color, .footer-2 .footer-bg-color {
  background-color: #262a2e;
}

.footer .title-tag, .footer-2 .title-tag {
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  .footer .title-tag, .footer-2 .title-tag {
    margin-bottom: 40px;
  }
}

.footer .title-tag h6, .footer-2 .title-tag h6 {
  color: #fff;
  text-transform: uppercase;
  font-size: 15px;
}

.footer .list-unstyled .media i, .footer-2 .list-unstyled .media i {
  color: #969696;
}

.footer .list-unstyled .media .media-body a, .footer-2 .list-unstyled .media .media-body a {
  color: #969696;
  font-size: 0.875rem;
  transition: all .3s;
}

.footer .list-unstyled .media .media-body a:hover, .footer-2 .list-unstyled .media .media-body a:hover {
  color: #ff891e;
}

.footer .gallery > .col-4, .footer-2 .gallery > .col-4 {
  padding: 0 0 0 15px;
}

.footer .copyright, .footer-2 .copyright {
  background-color: #232629;
}

.footer .copyright p, .footer-2 .copyright p {
  font-size: 13px;
  text-align: center;
}

@media (min-width: 992px) {
  .footer .copyright p, .footer-2 .copyright p {
    font-size: 14px;
  }
}

.footer .copyright .list-inline a, .footer-2 .copyright .list-inline a {
  font-size: 13px;
  color: #969696;
  transition: all .3s;
}

@media (min-width: 992px) {
  .footer .copyright .list-inline a, .footer-2 .copyright .list-inline a {
    font-size: 14px;
  }
}

.footer .copyright .list-inline a:hover, .footer-2 .copyright .list-inline a:hover {
  color: #ff891e;
}

.footer-2 h6 {
  font-weight: 400;
}

/* Media List & Fullwidth View */
.media-list-view-cruise {
  background-color: #fff;
  border-radius: 5px;
  width: 100%;
  height: 200px;
}

.media-list-view-cruise .media-img {
  width: 100%;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  overflow: hidden;
}

@media (min-width: 768px) {
  .media-list-view-cruise .media-img {
    width: 40%;
    border-top-right-radius: 0;
    border-bottom-left-radius: 5px;
  }
}

.media-list-view-cruise .media-img img {
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  transition: all 0.3s;
}

@media (min-width: 768px) {
  .media-list-view-cruise .media-img img {
    height: 247px;
  }
}

@media (min-width: 1200px) {
  .media-list-view-cruise .media-img img {
    height: auto;
  }
}

.media-list-view-cruise .media-img .media-img-overlay {
  transition: all 0.6s;
  transform: scale(1) rotate(0deg);
}

.media-list-view-cruise .media-img:hover .media-img-overlay {
  background-color: rgba(0, 0, 0, 0.5);
}

.media-list-view-cruise .media-body {
  padding-left: 1.25rem;
  padding-top: 1rem;
  padding-right: 1.25rem;
}

.media-list-view-cruise .media-body .media-title {
  color: #222;
  font-weight: 700;
  text-transform: uppercase;
}

.media-list-view-cruise .media-body .rating-view {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  line-height: 15px;
  margin-bottom: 10px;
}

.media-list-view-cruise .media-body .rating-view .content-view {
  font-size: 13px;
  border-right: 1px solid #dedede;
  color: #969696;
  padding-right: 5px;
}

.media-list-view-cruise .media-body .rating-view .list-inline-rating {
  color: #fec701;
}

.media-list-view-cruise .media-body .rating-view .list-inline-rating .list-inline-item {
  margin-right: 0;
}

.media-list-view-cruise .media-body .date-view-footer {
  margin-bottom: 1.5rem;
}

@media (min-width: 768px) {
  .media-list-view-cruise .media-body .date-view-footer {
    position: absolute;
    bottom: 0;
    margin-bottom: 0;
  }
}

.media-list-view-cruise .media-body .date-view-footer span {
  height: 35px;
  line-height: 35px;
  border-radius: 4px;
  border: 1px solid #fff;
  padding: 0 14px;
  color: #969696;
  text-transform: uppercase;
  font-size: 13px;
  display: inline-block;
}

.media-list-view-cruise .media-body .view-details {
  border-radius: 5px;
}

.media-list-view-cruise:hover .media-title {
  color: #ff891e;
}

.media-list-view-cruise.media-border .media-body {
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

@media (min-width: 768px) {
  .media-list-view-cruise.media-border .media-body {
    border-bottom-left-radius: 0;
    border-top-right-radius: 5px;
  }
}

@media (min-width: 768px) {
  .media-list-view-cruise.media-border .media-body .date-view {
    position: absolute;
  }
}

.media-list-view-resorts {
  background-color: #fff;
  border-radius: 5px;
  width: 100%;
  height: 200px;
}

.media-list-view-resorts .media-img {
  width: 100%;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  overflow: hidden;
}

@media (min-width: 768px) {
  .media-list-view-resorts .media-img {
    width: 40%;
    border-top-right-radius: 0;
    border-bottom-left-radius: 5px;
  }
}

.media-list-view-resorts .media-img img {
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  transition: all 0.3s;
}

@media (min-width: 768px) {
  .media-list-view-resorts .media-img img {
    height: 247px;
  }
}

@media (min-width: 1200px) {
  .media-list-view-resorts .media-img img {
    height: auto;
  }
}

.media-list-view-resorts .media-img .media-img-overlay {
  transition: all 0.6s;
  transform: scale(1) rotate(0deg);
}

.media-list-view-resorts .media-img:hover .media-img-overlay {
  background-color: rgba(0, 0, 0, 0.5);
}

.media-list-view-resorts .media-body {
  padding-left: 1.25rem;
  padding-top: 1rem;
  padding-right: 1.25rem;
}

.media-list-view-resorts .media-body .media-title {
  color: #222;
  font-weight: 700;
  text-transform: uppercase;
}

.media-list-view-resorts .media-body .rating-view {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  line-height: 15px;
  margin-bottom: 10px;
}

.media-list-view-resorts .media-body .rating-view .content-view {
  font-size: 13px;
  border-right: 1px solid #dedede;
  color: #969696;
  padding-right: 5px;
}

.media-list-view-resorts .media-body .rating-view .list-inline-rating {
  color: #fec701;
}

.media-list-view-resorts .media-body .rating-view .list-inline-rating .list-inline-item {
  margin-right: 0;
}

.media-list-view-resorts .media-body .date-view {
  margin-bottom: 1.5rem;
}

@media (min-width: 768px) {
  .media-list-view-resorts .media-body .date-view {
    position: absolute;
    bottom: 0;
    margin-bottom: 0;
  }
}

.media-list-view-resorts .media-body .date-view span {
  height: 35px;
  line-height: 35px;
  border-radius: 4px;
  border: 1px solid #dedede;
  padding: 0 14px;
  color: #969696;
  text-transform: uppercase;
  font-size: 13px;
  display: inline-block;
}

.media-list-view-resorts:hover .media-title {
  color: #ff891e;
}

.media-list-view-resorts.media-border .media-body {
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

@media (min-width: 768px) {
  .media-list-view-resorts.media-border .media-body {
    border-bottom-left-radius: 0;
    border-top-right-radius: 5px;
  }
}

@media (min-width: 768px) {
  .media-list-view-resorts.media-border .media-body .date-view {
    position: absolute;
  }
}

.cruise-list-footer span {
  height: 35px;
  line-height: 35px;
  border-radius: 4px;
  border: 1px solid #fff;
  padding: 0 14px;
  color: #fff;
  text-transform: uppercase;
  font-size: 13px;
  display: inline-block;
}

.media-list-view-hotweeks {
  background-color: #fff;
  border-radius: 5px;
  width: 100%;
  height: 200px;
}

.media-list-view-hotweeks .media-img {
  width: 100%;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  overflow: hidden;
}

@media (min-width: 768px) {
  .media-list-view-hotweeks .media-img {
    width: 40%;
    border-top-right-radius: 0;
    border-bottom-left-radius: 5px;
  }
}

.media-list-view-hotweeks .media-img img {
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  transition: all 0.3s;
}

@media (min-width: 768px) {
  .media-list-view-hotweeks .media-img img {
    height: 247px;
  }
}

@media (min-width: 1200px) {
  .media-list-view-hotweeks .media-img img {
    height: auto;
  }
}

.media-list-view-hotweeks .media-img .media-img-overlay {
  transition: all 0.6s;
  transform: scale(1) rotate(0deg);
}

.media-list-view-hotweeks .media-img:hover .media-img-overlay {
  background-color: rgba(0, 0, 0, 0.5);
}

.media-list-view-hotweeks .media-body {
  padding-left: 1.25rem;
  padding-top: 1rem;
  padding-right: 1.25rem;
}

.media-list-view-hotweeks .media-body .media-title {
  color: #222;
  font-weight: 700;
  text-transform: uppercase;
}

.media-list-view-hotweeks .media-body .rating-view {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  line-height: 15px;
  margin-bottom: 10px;
}

.media-list-view-hotweeks .media-body .rating-view .content-view {
  font-size: 13px;
  border-right: 1px solid #dedede;
  color: #969696;
  padding-right: 5px;
}

.media-list-view-hotweeks .media-body .rating-view .list-inline-rating {
  color: #fec701;
}

.media-list-view-hotweeks .media-body .rating-view .list-inline-rating .list-inline-item {
  margin-right: 0;
}

.media-list-view-hotweeks .media-body .date-view-footer {
  margin-bottom: 1.5rem;
}

@media (min-width: 768px) {
  .media-list-view-hotweeks .media-body .date-view-footer {
    position: absolute;
    bottom: 0;
    margin-bottom: 0;
  }
}

.media-list-view-hotweeks .media-body .date-view-footer span {
  height: 35px;
  line-height: 35px;
  border-radius: 4px;
  border: 1px solid #fff;
  padding: 0 14px;
  color: #969696;
  text-transform: uppercase;
  font-size: 13px;
  display: inline-block;
}

.media-list-view-hotweeks .media-body .view-details {
  border-radius: 5px;
}

.media-list-view-hotweeks:hover .media-title {
  color: #ff891e;
}

.media-list-view-hotweeks.media-border .media-body {
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

@media (min-width: 768px) {
  .media-list-view-hotweeks.media-border .media-body {
    border-bottom-left-radius: 0;
    border-top-right-radius: 5px;
  }
}

@media (min-width: 768px) {
  .media-list-view-hotweeks.media-border .media-body .date-view {
    position: absolute;
  }
}

.cruise-list-footer span {
  height: 35px;
  line-height: 35px;
  border-radius: 4px;
  border: 1px solid #fff;
  padding: 0 14px;
  color: #fff;
  text-transform: uppercase;
  font-size: 13px;
  display: inline-block;
}

.card-footer.cruise {
  padding: 0.75rem 1.5rem;
  background-color: #1ab3e8;
  border-top: 0 solid transparent;
}

.card-footer.cruise:last-child {
  border-radius: 0 0 0.25rem 0.25rem;
}

/*# sourceMappingURL=star-color5.css.map */
