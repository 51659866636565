/* CSS3 ANIMATIONS */

.zoom-in{
    -webkit-animation: zoomIn 400ms ease both;
    -moz-animation: zoomIn 400ms ease both;
    -o-animation: zoomIn 400ms ease both;
    animation: zoomIn 400ms ease both;
}
@-webkit-keyframes zoomIn {
    0% { -webkit-transform: scale(1.5); }
    100% { -webkit-transform: scale(1); }
}
@-moz-keyframes zoomIn {
    0% { -moz-transform: scale(1.5); }
    100% { -moz-transform: scale(1); }
}
@-o-keyframes zoomIn {
    0% { opacity: 0; -o-transform: scale(1.5); }
    100% { opacity: 1; -o-transform: scale(1); }
}
@keyframes zoomIn {
    0% { transform: scale(1.5); }
    100% { transform: scale(1); }
}
.zoom-out{
    -webkit-animation: zoomOut 400ms ease both;
    -moz-animation: zoomOut 400ms ease both;
    -o-animation: zoomOut 400ms ease both;
    animation: zoomOut 400ms ease both;
}
@-webkit-keyframes zoomOut {
    0% { -webkit-transform: scale(.6); }
    100% { -webkit-transform: scale(1); }
}
@-moz-keyframes zoomOut {
    0% { -moz-transform: scale(.6); }
    100% { -moz-transform: scale(1); }
}
@-o-keyframes zoomOut {
    0% { -o-transform: scale(.6); }
    100% { -o-transform: scale(1); }
}
@keyframes zoomOut {
    0% { transform: scale(.6); }
    100% { transform: scale(1); }
}
.drop-up{
    -webkit-animation: drop-up 400ms ease both;
    -moz-animation: drop-up 400ms ease both;
    -o-animation: drop-up 400ms ease both;
    animation: drop-up 400ms ease both;
}
@-webkit-keyframes drop-up {
    0% { -webkit-transform: translateY(100px); }
    100% { -webkit-transform: translateY(0); }
}
@-moz-keyframes drop-up {
    0% { -moz-transform: translateY(100px); }
    100% { -moz-transform: translateY(0); }
}
@-o-keyframes drop-up {
    0% { -o-transform: translateY(100px); }
    100% { -o-transform: translateY(0); }
}
@keyframes drop-up {
    0% { transform: translateY(100px); }
    100% { transform: translateY(0); }
}
.drop-left{
    -webkit-animation: drop-left 400ms ease both;
    -moz-animation: drop-left 400ms ease both;
    -o-animation: drop-left 400ms ease both;
    animation: drop-left 400ms ease both;
}
@-webkit-keyframes drop-left {
    0% { -webkit-transform: translateX(-100px); }
    100% { -webkit-transform: translateX(0); }
}
@-moz-keyframes drop-left {
    0% { -moz-transform: translateX(-100px); }
    100% { -moz-transform: translateX(0); }
}
@-o-keyframes drop-left {
    0% { -o-transform: translateX(-100px); }
    100% { -o-transform: translateX(0); }
}
@keyframes drop-left {
    0% { transform: translateX(-100px); }
    100% { transform: translateX(0); }
}
.swing {
	-webkit-transform-origin: top center;
	-moz-transform-origin: top center;
	-o-transform-origin: top center;
	transform-origin: top center;
	-webkit-animation: swing 400ms ease-out both;
	-moz-animation: swing 400ms ease-out both;
	-o-animation: swing 400ms ease-out both;
	animation: swing 400ms ease-out both;
}
@-webkit-keyframes swing {
	20%, 40%, 60%, 80%, 100% { -webkit-transform-origin: top center; }
	20% { -webkit-transform: rotate(15deg); }	
	40% { -webkit-transform: rotate(-10deg); }
	60% { -webkit-transform: rotate(5deg); }	
	80% { -webkit-transform: rotate(-5deg); }	
	100% { -webkit-transform: rotate(0deg); }
}
@-moz-keyframes swing {
	20% { -moz-transform: rotate(15deg); }	
	40% { -moz-transform: rotate(-10deg); }
	60% { -moz-transform: rotate(5deg); }	
	80% { -moz-transform: rotate(-5deg); }	
	100% { -moz-transform: rotate(0deg); }
}
@-o-keyframes swing {
	20% { -o-transform: rotate(15deg); }	
	40% { -o-transform: rotate(-10deg); }
	60% { -o-transform: rotate(5deg); }	
	80% { -o-transform: rotate(-5deg); }	
	100% { -o-transform: rotate(0deg); }
}
@keyframes swing {
	20% { transform: rotate(15deg); }	
	40% { transform: rotate(-10deg); }
	60% { transform: rotate(5deg); }	
	80% { transform: rotate(-5deg); }	
	100% { transform: rotate(0deg); }
}

.flip{
	-webkit-animation: flip 700ms ease both;
	-moz-animation: flip 700ms ease both;
    -o-animation: flip 700ms ease both;
    animation: flip 700ms ease both;
}
@-webkit-keyframes flip {
  0% {
    -webkit-transform: perspective(400px) rotateX(90deg);
    transform: perspective(400px) rotateX(90deg);
    opacity: 0;
  }

  40% {
    -webkit-transform: perspective(400px) rotateX(-10deg);
    transform: perspective(400px) rotateX(-10deg);
  }

  70% {
    -webkit-transform: perspective(400px) rotateX(10deg);
    transform: perspective(400px) rotateX(10deg);
  }

  100% {
    -webkit-transform: perspective(400px) rotateX(0deg);
    transform: perspective(400px) rotateX(0deg);
    opacity: 1;
  }
}

@keyframes flip {
  0% {
    -webkit-transform: perspective(400px) rotateX(90deg);
    -ms-transform: perspective(400px) rotateX(90deg);
    transform: perspective(400px) rotateX(90deg);
    opacity: 0;
  }

  40% {
    -webkit-transform: perspective(400px) rotateX(-10deg);
    -ms-transform: perspective(400px) rotateX(-10deg);
    transform: perspective(400px) rotateX(-10deg);
  }

  70% {
    -webkit-transform: perspective(400px) rotateX(10deg);
    -ms-transform: perspective(400px) rotateX(10deg);
    transform: perspective(400px) rotateX(10deg);
  }

  100% {
    -webkit-transform: perspective(400px) rotateX(0deg);
    -ms-transform: perspective(400px) rotateX(0deg);
    transform: perspective(400px) rotateX(0deg);
    opacity: 1;
  }
}
.roll-in{
	-webkit-animation: roll-in 400ms ease both;
	-moz-animation: roll-in 400ms ease both;
    -o-animation: roll-in 400ms ease both;
    animation: roll-in 400ms ease both;
}
@-webkit-keyframes roll-in {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100%) rotate(-15deg);
    transform: translateX(-100%) rotate(-15deg);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0px) rotate(0deg);
    transform: translateX(0px) rotate(0deg);
  }
}
@keyframes roll-in {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100%) rotate(-15deg);
    -ms-transform: translateX(-100%) rotate(-15deg);
    transform: translateX(-100%) rotate(-15deg);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0px) rotate(0deg);
    -ms-transform: translateX(0px) rotate(0deg);
    transform: translateX(0px) rotate(0deg);
  }
}
.stretch{
	-webkit-animation: stretch 500ms ease both;
	-moz-animation: stretch 500ms ease both;
    -o-animation: stretch 500ms ease both;
    animation: stretch 500ms ease both;
	transform-origin: 0% 0%;
	-ms-transform-origin: 0% 0%;
	-webkit-transform-origin: 0% 0%; 		
}
@keyframes stretch{
	0% { transform: scaleX(0.3); }
	40% { transform: scaleX(1.02); }
	60% { transform: scaleX(0.98); }
	80% { transform: scaleX(1.01); }
	100% { transform: scaleX(0.98); }				
	80% { transform: scaleX(1.01); }
	100% { transform: scaleX(1); }							
}
@-webkit-keyframes stretch{
	0% { -webkit-transform: scaleX(0.3); }
	40% { -webkit-transform: scaleX(1.02); }
	60% { -webkit-transform: scaleX(0.98); }
	80% { -webkit-transform: scaleX(1.01); }
	100% { -webkit-transform: scaleX(0.98); }				
	80% { -webkit-transform: scaleX(1.01); }
	100% { -webkit-transform: scaleX(1); }		
}
@-o-keyframes stretch{
	0% { -o-transform: scaleX(0.3); }
	40% { -o-transform: scaleX(1.02); }
	60% { -o-transform: scaleX(0.98); }
	80% { -o-transform: scaleX(1.01); }
	100% { -o-transform: scaleX(0.98); }				
	80% { -o-transform: scaleX(1.01); }
	100% { -o-transform: scaleX(1); }		
}
